import {createSelector} from 'reselect';

import {getCurrentChannelId} from 'mattermost-redux/selectors/entities/common';
import {
    getMySystemPermissions,
    getMySystemRoles,
    getPermissionsForRoles,
    getRoles,
    haveISystemPermission,
} from 'mattermost-redux/selectors/entities/roles_helpers';
import {getTeamMemberships, getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';
import {getConfig} from 'mattermost-redux/selectors/entities/general';
import {getCurrentUserId, getCurrentUser} from 'mattermost-redux/selectors/entities/users';

import {General, Permissions} from 'mattermost-redux/constants';

import {type Role} from 'mattermost-redux/types/roles';
import {type GlobalState} from 'mattermost-redux/types/store';
import {type GroupMembership, type GroupPermissions} from 'mattermost-redux/types/groups';
import {type PermissionsKeys} from 'components/permissions_gates/types';
import {type UserProfile} from '@mattermost/types/users';
import {hasUserAccessTokenRole, isGuest, isGuestPlus, isSystemAdmin} from 'mattermost-redux/utils/user_utils';

export {getMySystemPermissions, getMySystemRoles, getRoles, haveISystemPermission};

export const getGroupMemberships: (state: GlobalState) => Record<string, GroupMembership> = createSelector(
    (state: GlobalState) => state.entities.groups.myGroups,
    getCurrentUserId,
    (myGroupIDs: string[], currentUserID: string) => {
        const groupMemberships: Record<string, GroupMembership> = {};
        myGroupIDs.forEach((groupID) => {
            groupMemberships[groupID] = {user_id: currentUserID, roles: General.CUSTOM_GROUP_USER_ROLE};
        });
        return groupMemberships;
    },
);

export const getMyGroupRoles: (state: GlobalState) => Record<string, Set<string>> = createSelector(
    getGroupMemberships,
    (groupMemberships) => {
        const roles: Record<string, Set<string>> = {};
        if (groupMemberships) {
            for (const key in groupMemberships) {
                if (groupMemberships.hasOwnProperty(key) && groupMemberships[key].roles) {
                    roles[key] = new Set<string>(groupMemberships[key].roles.split(' '));
                }
            }
        }
        return roles;
    },
);

/**
 * Returns a map of permissions, keyed by group id, for all groups that are mentionable and not deleted.
 */
export const getGroupListPermissions: (state: GlobalState) => Record<string, GroupPermissions> = createSelector(
    getMyGroupRoles,
    getRoles,
    getMySystemPermissions,
    (state: GlobalState) => state.entities.groups.groups,
    (myGroupRoles, roles, systemPermissions, allGroups) => {
        const groups = Object.entries(allGroups).filter((entry) => (entry[1]?.allow_reference && entry[1]?.delete_at === 0)).map((entry) => entry[1]);

        const permissions = new Set<string>();
        groups.forEach((group) => {
            const roleNames = myGroupRoles[group.id!];
            if (roleNames) {
                for (const roleName of roleNames) {
                    if (roles[roleName]) {
                        for (const permission of roles[roleName].permissions) {
                            permissions.add(permission);
                        }
                    }
                }
            }
        });

        for (const permission of systemPermissions) {
            permissions.add(permission);
        }

        const groupPermissionsMap: Record<string, GroupPermissions> = {};
        groups.forEach((g) => {
            groupPermissionsMap[g.id] = {
                can_delete: permissions.has(Permissions.DELETE_CUSTOM_GROUP) && g.source.toLowerCase() !== 'ldap',
                can_manage_members: permissions.has(Permissions.MANAGE_CUSTOM_GROUP_MEMBERS) && g.source.toLowerCase() !== 'ldap',
            };
        });
        return groupPermissionsMap;
    },
);

export const getMyTeamRoles: (state: GlobalState) => Record<string, Set<string>> = createSelector(
    getTeamMemberships,
    (teamsMemberships) => {
        const roles: Record<string, Set<string>> = {};
        if (teamsMemberships) {
            for (const key in teamsMemberships) {
                if (teamsMemberships.hasOwnProperty(key) && teamsMemberships[key].roles) {
                    roles[key] = new Set<string>(teamsMemberships[key].roles.split(' '));
                }
            }
        }
        return roles;
    },
);

export function getMyChannelRoles(state: GlobalState): Record<string, Set<string>> {
    return state.entities.channels.roles;
}

export const getRolesById: (state: GlobalState) => Record<string, Role> = createSelector(
    getRoles,
    (rolesByName) => {
        const rolesById: Record<string, Role> = {};
        for (const role of Object.values(rolesByName)) {
            rolesById[role.id] = role;
        }
        return rolesById;
    },
);

const getMyPermissionsByTeam = createSelector(
    getMyTeamRoles,
    getRoles,
    (myTeamRoles, allRoles) => {
        const permissionsByTeam: Record<string, Set<PermissionsKeys>> = {};

        for (const [teamId, roles] of Object.entries(myTeamRoles)) {
            permissionsByTeam[teamId] = getPermissionsForRoles(allRoles, roles);
        }

        return permissionsByTeam;
    },
);

const getMyPermissionsByGroup = createSelector(
    getMyGroupRoles,
    getRoles,
    (myGroupRoles, allRoles) => {
        const permissionsByGroup: Record<string, Set<PermissionsKeys>> = {};

        for (const [groupId, roles] of Object.entries(myGroupRoles)) {
            permissionsByGroup[groupId] = getPermissionsForRoles(allRoles, roles);
        }

        return permissionsByGroup;
    },
);

const getMyPermissionsByChannel = createSelector(
    getMyChannelRoles,
    getRoles,
    (myChannelRoles, allRoles) => {
        const permissionsByChannel: Record<string, Set<PermissionsKeys>> = {};

        for (const [channelId, roles] of Object.entries(myChannelRoles)) {
            permissionsByChannel[channelId] = getPermissionsForRoles(allRoles, roles);
        }

        return permissionsByChannel;
    },
);

export function haveITeamPermission(state: GlobalState, teamId: string, permission: PermissionsKeys) {
    return (
        getMySystemPermissions(state).has(permission) ||
        getMyPermissionsByTeam(state)[teamId]?.has(permission)
    );
}

export function haveIGroupPermission(state: GlobalState, groupID: string, permission: PermissionsKeys) {
    return (
        getMySystemPermissions(state).has(permission) ||
        getMyPermissionsByGroup(state)[groupID]?.has(permission)
    );
}

export function haveIChannelPermission(state: GlobalState, teamId: string, channelId: string, permission: PermissionsKeys): boolean {
    return (
        getMySystemPermissions(state).has(permission) ||
        getMyPermissionsByTeam(state)[teamId]?.has(permission) ||
        getMyPermissionsByChannel(state)[channelId]?.has(permission)
    );
}

export function haveICurrentTeamPermission(state: GlobalState, permission: PermissionsKeys): boolean {
    return haveITeamPermission(state, getCurrentTeamId(state), permission);
}

export function haveICurrentChannelPermission(state: GlobalState, permission: PermissionsKeys): boolean {
    return haveIChannelPermission(state, getCurrentTeamId(state), getCurrentChannelId(state), permission);
}

export const getMyChannelPermissions = createSelector(
    getMySystemPermissions,
    getMyPermissionsByTeam,
    getMyPermissionsByChannel,
    (systemPermissions, teamPermissions, channelPermissions) => {
        return {
            system: systemPermissions,
            team: teamPermissions,
            channel: channelPermissions,
        };
    },
);

export function canIChangeUserRoles(state: GlobalState, user?: UserProfile) {
    if (!user) {
        return false;
    }

    if (haveISystemPermission(state, {permission: Permissions.MANAGE_SYSTEM})) {
        return true;
    }

    const isUserSystemAdmin = isSystemAdmin(user.roles);

    if (haveISystemPermission(state, {permission: Permissions.MANAGE_ROLES})) {
        return !isUserSystemAdmin;
    }

    if (haveISystemPermission(state, {permission: Permissions.SYSCONSOLE_WRITE_USERMANAGEMENT_USERS})) {
        if (isUserSystemAdmin) {
            return false;
        }

        return !hasUserAccessTokenRole(user.roles);
    }

    return false;
}

export function canIActivateUser(state: GlobalState, user?: UserProfile) {
    if (!user || user.delete_at === 0) {
        return false;
    }

    const config = getConfig(state);
    const guestsEnabled = config.EnableRestrictedGuestAccounts === 'true';
    const guestsPlusEnabled = config.EnableGuestAccounts === 'true';

    if (!guestsEnabled && isGuest(user.roles)) {
        return false;
    }

    if (!guestsPlusEnabled && isGuestPlus(user.roles)) {
        return false;
    }

    if (haveISystemPermission(state, {permission: Permissions.MANAGE_SYSTEM})) {
        return true;
    }

    if (haveISystemPermission(state, {permission: Permissions.SYSCONSOLE_WRITE_USERMANAGEMENT_USERS})) {
        return !isSystemAdmin(user.roles);
    }

    return false;
}

export function canIDeactivateUser(state: GlobalState, user?: UserProfile) {
    if (!user || user.delete_at > 0) {
        return false;
    }

    if (haveISystemPermission(state, {permission: Permissions.MANAGE_SYSTEM})) {
        return true;
    }

    if (haveISystemPermission(state, {permission: Permissions.SYSCONSOLE_WRITE_USERMANAGEMENT_USERS})) {
        return !isSystemAdmin(user.roles);
    }

    return false;
}

export function canIManageUserTokens(state: GlobalState) {
    const config = getConfig(state);
    const userAccessTokensEnabled = config.EnableUserAccessTokens === 'true';
    const currentUser = getCurrentUser(state);

    if (!userAccessTokensEnabled || !currentUser) {
        return false;
    }

    if (currentUser.isSystemAdmin) {
        return true;
    }

    return hasUserAccessTokenRole(currentUser?.roles || '') && haveISystemPermission(state, {permission: Permissions.EDIT_OTHER_USERS});
}

export function canIRevokeUserSession(state: GlobalState, user?: UserProfile) {
    return (
        user?.delete_at === 0 &&
        haveISystemPermission(state, {permission: Permissions.REVOKE_USER_ACCESS_TOKEN}) &&
        haveISystemPermission(state, {permission: Permissions.EDIT_OTHER_USERS})
    );
}

export function canIResetUserPassword(state: GlobalState, user?: UserProfile) {
    if (!user || user.auth_service) {
        return false;
    }

    if (haveISystemPermission(state, {permission: Permissions.MANAGE_SYSTEM})) {
        return true;
    }

    if (haveISystemPermission(state, {permission: Permissions.SYSCONSOLE_WRITE_USERMANAGEMENT_USERS})) {
        return !isSystemAdmin(user.roles);
    }

    return false;
}

export function canIUpdateUserEmail(state: GlobalState, user?: UserProfile) {
    if (!user || user.auth_service || user.id === getCurrentUserId(state)) {
        return false;
    }

    if (haveISystemPermission(state, {permission: Permissions.MANAGE_SYSTEM})) {
        return true;
    }

    if (haveISystemPermission(state, {permission: Permissions.EDIT_OTHER_USERS})) {
        return !isSystemAdmin(user.roles);
    }

    return false;
}
