import React, {forwardRef} from 'react';
import {useOverlay, usePreventScroll, useModal, OverlayContainer} from '@react-aria/overlays';
import {useDialog} from '@react-aria/dialog';
import {FocusScope} from '@react-aria/focus';
import {mergeProps, mergeRefs} from '@react-aria/utils';
import classNames from 'classnames';

import {TypographySize, useTypography} from '../../../hooks/typography';

import type {Props} from './types';

import {ModalHeader} from './modal-header';
import {ModalCloser} from './modal-closer';
import {ConfirmFooter, ModalFooter} from './modal-footer';
import {ModalSize, KeyCodes} from './constants';
import {ModalError} from './modal-error';
import styles from './styles.module.css';

const DialogInner = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        title,
        description,
        onClose,
        onCancel,
        onConfirm,
        onEnterKeyPressed,
        cancelText,
        confirmText,
        footer,
        children,
        size = ModalSize.MEDIUM,
        isConfirmDisabled,
        isConfirmDestructive,
        hideCancelButton,
        error,
        autoFocusCancelBtn,
        bodyCls,
        closerProps,
        ...overlayPassedProps
    } = props;

    const innerRef = React.useRef(null);
    const {overlayProps, underlayProps} = useOverlay({...overlayPassedProps, onClose}, innerRef);

    usePreventScroll();
    const {modalProps} = useModal();
    const {dialogProps, titleProps} = useDialog(
        {
            ...props,
            role: 'dialog',
        },
        innerRef,
    );
    const bodyTextCls = useTypography({
        size: TypographySize.BodyL,
    });
    const onEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === KeyCodes.ENTER[0] && onEnterKeyPressed) {
            onEnterKeyPressed();
        }
    };

    return (
        <div onClick={onClose} onKeyDown={onEnterKeyDown} className={styles.overlay} {...underlayProps}>
            <FocusScope contain={true} restoreFocus={true} autoFocus={true}>
                <div className={styles.scrollContainer}>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={classNames(styles.dialog, styles[`dialog-${size}`])}
                        {...mergeProps(overlayProps, dialogProps, modalProps)}
                        ref={mergeRefs(ref, innerRef)}
                    >
                        {
                            title &&
                            <ModalHeader
                                closer={onClose && <ModalCloser {...closerProps} onClick={onClose} />}
                                description={description}
                                title={title}
                                {...titleProps}
                            />
                        }
                        {error && <ModalError>{error}</ModalError>}
                        <div className={classNames(bodyTextCls, bodyCls ?? styles.body)}>{children}</div>
                        {confirmText && onConfirm ? (
                            <ConfirmFooter
                                onCancel={onCancel ?? onClose}
                                onConfirm={onConfirm}
                                confirmText={confirmText}
                                cancelText={cancelText}
                                isConfirmDisabled={isConfirmDisabled}
                                hideCancelButton={hideCancelButton}
                                isConfirmDestructive={isConfirmDestructive}
                                autoFocusCancelBtn={autoFocusCancelBtn}
                            />
                        ) : (
                            <ModalFooter>{footer}</ModalFooter>
                        )}
                    </div>
                </div>
            </FocusScope>
        </div>
    );
});

export const ModalDialog = forwardRef<HTMLDivElement, Props>((props, ref) => {
    return (
        <OverlayContainer>
            <DialogInner {...props} ref={ref}/>
        </OverlayContainer>
    );
});
