import React from 'react';

import {draftsLocalForageAPI} from './local_forage';

export function useDraftsStorage(
    onInit: (items: Record<string, any>) => void,
    onError?: () => void,
) {
    const mountedRef = React.useRef(false);

    React.useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    React.useEffect(() => {
        let subscription: Subscription | null = null;

        draftsLocalForageAPI
            .ready()
            .then(() => {
                if (!mountedRef.current) {
                    return;
                }

                (async function fillInitialState() {
                    const initialDrafts: Record<string, any> = {};

                    await draftsLocalForageAPI.iterate((value: string, key) => {
                        initialDrafts[key] = value;
                    });

                    if (!mountedRef.current) {
                        return;
                    }

                    onInit(initialDrafts);
                }());
            })
            .catch((e) => {
                // eslint-disable-next-line no-console
                console.error(`[Time Drafts]: Failed: ${e.message}`);
                onError?.();
            });

        return () => {
            if (subscription && 'unsubscribe' in subscription && typeof subscription.unsubscribe === 'function') {
                subscription.unsubscribe();
                subscription = null;
            }
        };
    }, [onError, onInit]);

    return null;
}
