import {browserHistory} from 'utils/browser_history';
import * as Selectors from 'selectors/storage';
import * as Actions from 'actions/storage';
import store from 'stores/redux_store';
import {ErrorPageTypes, StoragePrefixes, LandingPreferenceTypes} from 'utils/constants';
import * as Utils from 'utils/utils';
import {isLocalStorageSupported} from 'utils/storage_utils';

import LocalStorageStore from './local_storage_store';

const dispatch = store.dispatch;
const getState = store.getState;

class BrowserStoreClass {
    private hasCheckedLocalStorage?: boolean;
    private localStorageSupported?: boolean;

    setItem(name: string, value: string) {
        dispatch(Actions.setItem(name, value));
    }

    getItem(name: string, defaultValue: string) {
        return Selectors.makeGetItem(name, defaultValue)(getState());
    }

    removeItem(name: string) {
        dispatch(Actions.removeItem(name));
    }

    setGlobalItem(name: string, value: string) {
        dispatch(Actions.setGlobalItem(name, value));
    }

    getGlobalItem(name: string, defaultValue: string | null = null) {
        return Selectors.makeGetGlobalItem(name, defaultValue)(getState());
    }

    removeGlobalItem(name: string) {
        dispatch(Actions.removeGlobalItem(name));
    }

    signalLogout() {
        if (this.isLocalStorageSupported()) {
            // PLT-1285 store an identifier in session storage so we can catch if the logout came from this tab on IE11
            const logoutId = Utils.generateId();

            LocalStorageStore.removePrefixes(StoragePrefixes.ANNOUNCEMENT);
            try {
                sessionStorage.setItem(StoragePrefixes.LOGOUT, logoutId);
                LocalStorageStore.setLogout(logoutId);
                LocalStorageStore.removeLogout();
            // eslint-disable-next-line no-empty
            } catch {}
        }
    }

    isSignallingLogout(logoutId: string) {
        return logoutId === sessionStorage.getItem(StoragePrefixes.LOGOUT);
    }

    signalLogin() {
        if (this.isLocalStorageSupported()) {
            // PLT-1285 store an identifier in session storage so we can catch if the logout came from this tab on IE11
            const loginId = Utils.generateId();

            sessionStorage.setItem(StoragePrefixes.LOGIN, loginId);
            LocalStorageStore.setLogin(loginId);
            LocalStorageStore.removeLogin();
        }
    }

    isSignallingLogin(loginId: string) {
        return loginId === sessionStorage.getItem(StoragePrefixes.LOGIN);
    }

    isLocalStorageSupported() {
        if (this.hasCheckedLocalStorage) {
            return this.localStorageSupported;
        }

        this.localStorageSupported = isLocalStorageSupported();

        try {
            sessionStorage.setItem('__testSession__', '1');
            sessionStorage.removeItem('__testSession__');
        } catch (e) {
            // Session storage not usable, website is unusable
            browserHistory.push('/error?type=' + ErrorPageTypes.LOCAL_STORAGE);
        }

        this.hasCheckedLocalStorage = true;

        return this.localStorageSupported;
    }

    getLandingPreference(siteUrl?: string) {
        return LocalStorageStore.getLandingPreference(String(siteUrl));
    }

    setLandingPreferenceToMattermostApp(siteUrl?: string) {
        LocalStorageStore.setLandingPreference(String(siteUrl), LandingPreferenceTypes.MATTERMOSTAPP);
    }

    setLandingPreferenceToBrowser(siteUrl?: string) {
        LocalStorageStore.setLandingPreference(String(siteUrl), LandingPreferenceTypes.BROWSER);
    }

    clearLandingPreference(siteUrl?: string) {
        LocalStorageStore.removeLandingPreference(String(siteUrl));
    }
}

const BrowserStore = new BrowserStoreClass();
export default BrowserStore;
