import {createAsyncThunk} from '@reduxjs/toolkit';

import deepMerge from '@tinkoff/utils/object/mergeDeep';
import map from '@tinkoff/utils/object/map';

import type {Channel, ChannelModeration} from '@mattermost/types/channels';
import type {AppDispatch, RootState} from 'stores/redux_store';
import {PermissionType} from '../constants';
import {Permissions} from 'mattermost-redux/constants';
import {getModerationsForChannelId} from '../selectors/get_moderations_for_channel_id';
import {patchChannelModerations} from '../api/patch_channel_moderations';

type Payload = {
    channelId: Channel['id'];
    permissions: Record<PermissionType, {enabled: boolean; value: boolean}>;
};

export const updateModerationsForChannel = createAsyncThunk(
    'channels/actions/updateModerationsForChannel',
    async (payload: Payload, thunkAPI) => {
        const {channelId, permissions} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const state = thunkAPI.getState() as RootState;

        const prevModerations = getModerationsForChannelId(state, channelId);

        const patchedModerations = await dispatch(
            patchChannelModerations({
                channelId,
                moderations: prevModerations
                    .map((moderation) => {
                        if (moderation.name === Permissions.CREATE_POST) {
                            return deepMerge(moderation, {
                                roles: {
                                    members: permissions[PermissionType.CREATE_POST],
                                    guests: permissions[PermissionType.CREATE_POST],
                                    restricted_guests: permissions[PermissionType.CREATE_POST],
                                },
                            } as ChannelModeration);
                        }

                        if (moderation.name === Permissions.CREATE_POST_IN_THREAD) {
                            return deepMerge(moderation, {
                                roles: {
                                    members: permissions[PermissionType.CREATE_POST_IN_THREAD],
                                    guests: permissions[PermissionType.CREATE_POST_IN_THREAD],
                                    restricted_guests: permissions[PermissionType.CREATE_POST_IN_THREAD],
                                },
                            } as ChannelModeration);
                        }

                        if (moderation.name === Permissions.USE_CHANNEL_MENTIONS) {
                            return deepMerge(moderation, {
                                roles: {
                                    members: permissions[PermissionType.CHANNEL_MENTIONS],
                                    guests: permissions[PermissionType.CHANNEL_MENTIONS],
                                    restricted_guests: permissions[PermissionType.CHANNEL_MENTIONS],
                                },
                            } as ChannelModeration);
                        }

                        if (moderation.name === Permissions.CHANNEL_MODERATED_PERMISSIONS.USE_CHANNEL_KTALK) {
                            return deepMerge(moderation, {
                                roles: {
                                    members: permissions[PermissionType.CHANNEL_KTALK],
                                    guests: permissions[PermissionType.CHANNEL_KTALK],
                                    restricted_guests: permissions[PermissionType.CHANNEL_KTALK],
                                },
                            } as ChannelModeration);
                        }

                        return moderation;
                    })
                    .map((moderation) => ({
                        ...moderation,
                        roles: map((role) => role?.value, moderation.roles),
                    })),
            }),
        ).unwrap();

        return patchedModerations;
    },
);
