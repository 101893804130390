import {bindActionCreators, type Dispatch} from 'redux';
import {connect} from 'react-redux';

import {addChannelMembers} from 'mattermost-redux/actions/channels';
import {removePost} from 'mattermost-redux/actions/posts';
import {getCurrentUser} from 'mattermost-redux/selectors/entities/users';
import {type GenericAction} from 'mattermost-redux/types/actions';

import {type Channel} from 'mattermost-redux/types/channels';

import {canManageMembers} from '../../../utils/channel_utils';

import {type Post} from 'mattermost-redux/types/posts';

import {type GlobalState} from '../../../types/store';

import {sendEphemeralPost} from 'actions/global_actions';

import PostAddChannelMember from './post_add_channel_member';

type OwnProps = {
    post: Post;
    channel: Channel;
};

function mapStateToProps(state: GlobalState, ownProps: OwnProps) {
    const canAddChannelMembers = canManageMembers(state, ownProps.channel);

    return {
        ...ownProps,
        canAddChannelMembers,
        currentUser: getCurrentUser(state)!,
    };
}

function mapDispatchToProps(dispatch: Dispatch<GenericAction>) {
    return {
        actions: bindActionCreators(
            {
                addChannelMembers,
                removePost,
                sendEphemeralPost,
            },
            dispatch,
        ),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PostAddChannelMember);
