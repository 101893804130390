import {type AxiosRequestConfig} from 'axios';

import {type UserAutocomplete} from '@mattermost/types/autocomplete';
import {General} from 'mattermost-redux/constants';

import {makeGetRequest} from '../request';

import {composeUsersRoute} from './helpers';

type AutocompleteUsersRequestParams = {

    /**
     * Team ID
     */
    in_team?: string;

    /**
     * Channel ID
     */
    in_channel?: string;

    /**
     * Username, nickname first name or last name
     */
    name: string;

    /**
     * The maximum number of users to return in each subresult
     *
     * @default 100
     */
    limit?: number;

    /**
     * A flag to boost users in select
     */
    boost?: boolean;
};

/**
 * Get a list of users for the purpose of autocompleting based on the provided search term.
 * Specify a combination of team_id and channel_id to filter results further.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/AutocompleteUsers
 */
export function autocompleteUsers(args: AutocompleteUsersRequestParams, options: AxiosRequestConfig<never> = {}) {
    const params: AutocompleteUsersRequestParams = {
        name: args.name,
        in_channel: args.in_channel,
        limit: args.limit || General.AUTOCOMPLETE_LIMIT_DEFAULT,
        in_team: args.in_team,
    };

    if (args.boost) {
        params.boost = args.boost;
    }

    const route = composeUsersRoute('/autocomplete');

    return makeGetRequest<UserAutocomplete>(route, {
        ...options,
        params,
    });
}
