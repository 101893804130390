/* eslint-disable max-lines */
import isEqual from '@tinkoff/utils/is/equal';

import {createSelector} from 'reselect';

import {General, Permissions, Preferences} from 'mattermost-redux/constants';
import {CategoryTypes} from 'mattermost-redux/constants/channel_categories';

import {getCategoryInTeamByType} from 'mattermost-redux/selectors/entities/channel_categories';
import {
    getCurrentChannelId,
    getCurrentUser,
    getUsers,
    getMyChannelMemberships,
    getMyCurrentChannelMembership,
} from 'mattermost-redux/selectors/entities/common';
import {getTeammateNameDisplaySetting} from 'mattermost-redux/selectors/entities/preferences';
import {
    haveICurrentChannelPermission,
    haveIChannelPermission,
    haveITeamPermission,
} from 'mattermost-redux/selectors/entities/roles';
import {getCurrentTeam, getCurrentTeamId, getMyTeams, getTeams} from 'mattermost-redux/selectors/entities/teams';
import {
    getCurrentUserId,
    getStatusForUserId,
    getUser,
    getUserIdsInChannels,
} from 'mattermost-redux/selectors/entities/users';

import type {
    Channel,
    ChannelMembership,
    ChannelMessageCount,
    ChannelModeration,
    ChannelSearchOpts,
    ChannelStats,
} from 'mattermost-redux/types/channels';
import type {GlobalState} from 'mattermost-redux/types/store';
import type {Team} from 'mattermost-redux/types/teams';
import type {UsersState, UserProfile} from 'mattermost-redux/types/users';
import type {
    IDMappedObjects,
    RelationOneToMany,
    RelationOneToManyUnique,
    RelationOneToOne,
} from 'mattermost-redux/types/utilities';

import {
    completeDirectChannelInfo,
    completeDirectGroupInfo,
    newCompleteDirectChannelInfo,
    completeDirectChannelDisplayName,
    getUserIdFromChannelName,
    getChannelByName as getChannelByNameHelper,
    isChannelMuted,
    sortChannelsByDisplayName,
    isDefaultChannel,
    isDirectChannel,
    filterChannelsMatchingTerm,
    calculateUnreadCount,
    isOpenChannel,
    isPrivateChannel,
} from 'mattermost-redux/utils/channel_utils';
import {createIdsSelector} from 'mattermost-redux/utils/helpers';
import {getDataRetentionCustomPolicy} from 'mattermost-redux/selectors/entities/admin';

import {isDmChannel} from 'features/sidebar/utils/isDmChannel';

export {getCurrentChannelId, getMyChannelMemberships, getMyCurrentChannelMembership};

export function getAllChannels(state: GlobalState): IDMappedObjects<Channel> {
    return state.entities.channels.channels;
}

export const getAllDmChannels = createSelector(getAllChannels, (allChannels) => {
    let allDmChannels: Record<string, Channel> = {};

    Object.values(allChannels).forEach((channel: Channel) => {
        if (isDmChannel(channel)) {
            allDmChannels = {...allDmChannels, [channel.name]: channel};
        }
    });

    return allDmChannels;
});

export function getAllChannelStats(state: GlobalState): RelationOneToOne<Channel, ChannelStats> {
    return state.entities.channels.stats;
}

export function getChannelsInTeam(state: GlobalState): RelationOneToMany<Team, Channel> {
    return state.entities.channels.channelsInTeam;
}

const emptyChannelPolicy: Channel[] = [];

export function getChannelsInPolicy() {
    return createSelector(
        getAllChannels,
        (state: GlobalState, props: {policyId: string}) => getDataRetentionCustomPolicy(state, props.policyId),
        (getAllChannels, policy) => {
            if (!policy) {
                return emptyChannelPolicy;
            }

            const policyChannels: Channel[] = [];

            Object.entries(getAllChannels).forEach((channelEntry: [string, Channel]) => {
                const [, channel] = channelEntry;
                if (channel.policy_id === policy.id) {
                    policyChannels.push(channel);
                }
            });

            return policyChannels;
        },
    ) as (
        b: GlobalState,
        a: {
            policyId: string;
        },
    ) => Channel[];
}

export const getDirectChannelsSet: (state: GlobalState) => Set<string> = createSelector(
    getChannelsInTeam,
    (channelsInTeam: RelationOneToMany<Team, Channel>): Set<string> => {
        if (!channelsInTeam) {
            return new Set();
        }

        return new Set(channelsInTeam['']);
    },
);

export function getChannelMembersInChannels(
    state: GlobalState,
): RelationOneToOne<Channel, Record<string, ChannelMembership>> {
    return state.entities.channels.membersInChannel;
}

// makeGetChannel returns a selector that returns a channel from the store with the following filled in for DM/GM channels:
// - The display_name set to the other user(s) names, following the Teammate Name Display setting
// - The teammate_id for DM channels
// - The status of the other user in a DM channel
export function makeGetChannel(): (state: GlobalState, props: {id: string}) => Channel {
    return createSelector(
        getCurrentUserId,
        (state: GlobalState) => state.entities.users.profiles,
        (state: GlobalState) => state.entities.users.profilesInChannel,
        (state: GlobalState, props: {id: string}) => {
            const channel = getChannel(state, props.id);
            if (!channel || !isDirectChannel(channel)) {
                return '';
            }

            const currentUserId = getCurrentUserId(state);
            const teammateId = getUserIdFromChannelName(currentUserId, channel.name);
            const teammateStatus = getStatusForUserId(state, teammateId);

            return teammateStatus || 'offline';
        },
        (state: GlobalState, props: {id: string}) => getChannel(state, props.id),
        getTeammateNameDisplaySetting,
        (currentUserId, profiles, profilesInChannel, teammateStatus, channel, teammateNameDisplay) => {
            return newCompleteDirectChannelInfo(
                currentUserId,
                profiles,
                profilesInChannel,
                teammateStatus,
                teammateNameDisplay!,
                channel,
            );
        },
        {
            memoizeOptions: {
                resultEqualityCheck: isEqual,
            },
        },
    );
}

/**
 * Returns a channel as it exists in the store without filling in any additional details such as the
 * display_name for DM/GM channels.
 */
export function getChannel(state: GlobalState, id: string) {
    return getAllChannels(state)[id];
}

export function getMyChannelMembership(state: GlobalState, channelId: string): ChannelMembership {
    return getMyChannelMemberships(state)[channelId];
}

/**
 * Returns a selector that, given an array of channel IDs, returns a list of the corresponding
 * channels. Channels are returned in the same order as the given IDs with undefined entries replacing any invalid IDs.
 * Note that memoization will fail if an array literal is passed in.
 */
export function makeGetChannelsForIds(): (state: GlobalState, ids: string[]) => Channel[] {
    return createSelector(
        getAllChannels,
        (state: GlobalState, ids: string[]) => ids,
        (allChannels, ids) => {
            return ids.map((id) => allChannels[id]);
        },
    );
}

export const getCurrentChannel: (state: GlobalState) => Channel | undefined = createSelector(
    getAllChannels,
    getCurrentChannelId,
    (state: GlobalState) => state.entities.users,
    getTeammateNameDisplaySetting,
    (allChannels, currentChannelId, users, teammateNameDisplay): Channel => {
        const channel = allChannels[currentChannelId];

        return completeDirectChannelInfo(users, teammateNameDisplay, channel);
    },
    {
        memoizeOptions: {
            resultEqualityCheck: isEqual,
        },
    },
);

export const getDefaultChannel = createSelector(
    getAllChannels,
    getCurrentTeam,
    (channels, team): Channel | undefined => {
        if (team && team.primary_channel_id) {
            return channels[team.primary_channel_id];
        }
    },
);

export const getCurrentChannelNameForSearchShortcut: (state: GlobalState) => string | undefined = createSelector(
    getAllChannels,
    getCurrentChannelId,
    (state: GlobalState): UsersState => state.entities.users,
    (allChannels: IDMappedObjects<Channel>, currentChannelId: string, users: UsersState): string | undefined => {
        const channel = allChannels[currentChannelId];

        // Only get the extra info from users if we need it
        if (channel?.type === General.DM_CHANNEL) {
            const dmChannelWithInfo = completeDirectChannelInfo(users, Preferences.DISPLAY_PREFER_USERNAME, channel);
            return `@${dmChannelWithInfo.display_name}`;
        }

        // Replace spaces in GM channel names
        if (channel?.type === General.GM_CHANNEL) {
            const gmChannelWithInfo = completeDirectGroupInfo(
                users,
                Preferences.DISPLAY_PREFER_USERNAME,
                channel,
                false,
            );
            return `@${gmChannelWithInfo.display_name.replace(/\s/g, '')}`;
        }

        return channel?.name;
    },
);

export const getMyChannelMember = (state: GlobalState, channelId?: string) => {
    if (!channelId) {
        return null;
    }

    const channelMemberships = getMyChannelMemberships(state);
    return channelMemberships[channelId] || null;
};

export const getChannelStats: (state: GlobalState, channelId: string) => ChannelStats = createSelector(
    getAllChannelStats,
    (state: GlobalState, channelId: string) => channelId,
    (allChannelStats: RelationOneToOne<Channel, ChannelStats>, channelId: string): ChannelStats => {
        return allChannelStats[channelId];
    },
);

export const getCurrentChannelStats: (state: GlobalState) => ChannelStats = createSelector(
    getAllChannelStats,
    getCurrentChannelId,
    (allChannelStats: RelationOneToOne<Channel, ChannelStats>, currentChannelId: string): ChannelStats => {
        return allChannelStats[currentChannelId];
    },
);

export function isCurrentChannelFavorite(state: GlobalState): boolean {
    const currentChannelId = getCurrentChannelId(state);
    return isFavoriteChannel(state, currentChannelId);
}

export const isCurrentChannelMuted: (state: GlobalState) => boolean = createSelector(
    getMyCurrentChannelMembership,
    (membership?: ChannelMembership): boolean => {
        if (!membership) {
            return false;
        }

        return isChannelMuted(membership);
    },
);

export const isChannelMutedByChannel = createSelector(
    getMyChannelMembership,
    (membership?: ChannelMembership): boolean => {
        if (!membership) {
            return false;
        }

        return isChannelMuted(membership);
    },
);

export const isMutedChannel: (state: GlobalState, channelId: string) => boolean = createSelector(
    (state: GlobalState, channelId: string) => getMyChannelMembership(state, channelId),
    (membership?: ChannelMembership): boolean => {
        if (!membership) {
            return false;
        }

        return isChannelMuted(membership);
    },
);

export const isCurrentChannelArchived: (state: GlobalState) => boolean = createSelector(
    getCurrentChannel,
    (channel) => channel.delete_at !== 0,
);

export const isChannelArchived = (channel: Channel | void) => (channel?.delete_at !== 0);

export const isCurrentChannelDefault: (state: GlobalState) => boolean = createSelector(
    getCurrentChannel,
    getCurrentTeam,
    (channel, team) => {
        if (!channel) {
            return false;
        }
        return isDefaultChannel(channel, team);
    },
);

export function getChannelMessageCounts(state: GlobalState): RelationOneToOne<Channel, ChannelMessageCount> {
    return state.entities.channels.messageCounts;
}

export function getChannelMessageCount(state: GlobalState, channelId: string): ChannelMessageCount {
    return getChannelMessageCounts(state)[channelId];
}

function getCurrentChannelMessageCount(state: GlobalState) {
    return getChannelMessageCount(state, getCurrentChannelId(state));
}

export const countCurrentChannelUnreadMessages: (state: GlobalState) => number = createSelector(
    getCurrentChannelMessageCount,
    getMyCurrentChannelMembership,
    (messageCount: ChannelMessageCount, membership?: ChannelMembership): number => {
        if (!membership) {
            return 0;
        }

        if (!messageCount) {
            return 0;
        }

        return messageCount.root - membership.msg_count_root;
    },
);

export function makeGetChannelUnreadCount(): (
    state: GlobalState,
    channelId: string,
) => ReturnType<typeof calculateUnreadCount> {
    return createSelector(
        (state: GlobalState, channelId: string) => getChannelMessageCount(state, channelId),
        (state: GlobalState, channelId: string) => getMyChannelMembership(state, channelId),
        (messageCount: ChannelMessageCount, member: ChannelMembership) => calculateUnreadCount(messageCount, member),
    );
}

export function getChannelByName(state: GlobalState, channelName: string): Channel | undefined {
    return getChannelByNameHelper(getAllChannels(state), channelName);
}

const emptyChannelSet: string[] = [];

export const getChannelSetInCurrentTeam: (state: GlobalState) => string[] = createSelector(
    getCurrentTeamId,
    getChannelsInTeam,
    (currentTeamId: string, channelsInTeam: RelationOneToMany<Team, Channel>): string[] => {
        return (channelsInTeam && channelsInTeam[currentTeamId]) || emptyChannelSet;
    },
);

export const getChannelSetForAllTeams: (state: GlobalState) => string[] = createSelector(
    getAllChannels,
    (allChannels): string[] => {
        const channelSet: string[] = [];
        Object.values(allChannels).forEach((channel: Channel) => {
            if (channel.type !== General.GM_CHANNEL && channel.type !== General.DM_CHANNEL) {
                channelSet.push(channel.id);
            }
        });
        return channelSet;
    },
);

function sortAndInjectChannels(channels: IDMappedObjects<Channel>, channelSet: string[], locale: string): Channel[] {
    const currentChannels: Channel[] = [];

    if (typeof channelSet === 'undefined') {
        return currentChannels;
    }

    channelSet.forEach((channelId) => {
        const channel = channels[channelId];
        if (channel) {
            currentChannels.push(channel);
        }
    });

    return currentChannels.sort(sortChannelsByDisplayName.bind(null, locale));
}

export const getChannelsInCurrentTeam: (state: GlobalState) => Channel[] = createSelector(
    getAllChannels,
    getChannelSetInCurrentTeam,
    getCurrentUser,
    (channels: IDMappedObjects<Channel>, currentTeamChannelSet: string[], currentUser: UserProfile): Channel[] => {
        let locale = General.DEFAULT_LOCALE;

        if (currentUser && currentUser.locale) {
            locale = currentUser.locale;
        }

        return sortAndInjectChannels(channels, currentTeamChannelSet, locale);
    },
);

export const getChannelsNameMapInTeam: (state: GlobalState, teamId: string) => Record<string, Channel> = createSelector(
    getAllChannels,
    getChannelsInTeam,
    (state: GlobalState, teamId: string): string => teamId,
    (
        channels: IDMappedObjects<Channel>,
        channelsInTeams: RelationOneToMany<Team, Channel>,
        teamId: string,
    ): Record<string, Channel> => {
        const channelsInTeam = channelsInTeams[teamId] || [];
        const channelMap: Record<string, Channel> = {};
        channelsInTeam.forEach((id) => {
            const channel = channels[id];
            if (channel) {
                channelMap[channel.name] = channel;
            }
        });
        return channelMap;
    },
);

export const getChannelsNameMapInCurrentTeam: (state: GlobalState) => Record<string, Channel> = createSelector(
    getAllChannels,
    getChannelSetInCurrentTeam,
    (channels: IDMappedObjects<Channel>, currentTeamChannelSet: string[]): Record<string, Channel> => {
        const channelMap: Record<string, Channel> = {};
        currentTeamChannelSet.forEach((id) => {
            const channel = channels[id];
            if (channel) {
                channelMap[channel.name] = channel;
            }
        });
        return channelMap;
    },
);

export const getChannelNameToDisplayNameMap: (state: GlobalState) => Record<string, string> = createIdsSelector(
    getAllChannels,
    getChannelSetInCurrentTeam,
    (channels: IDMappedObjects<Channel>, currentTeamChannelSet: string[]) => {
        const channelMap: Record<string, string> = {};
        for (const id of currentTeamChannelSet) {
            const channel = channels[id];
            if (channel) {
                channelMap[channel.name] = channel.display_name;
            }
        }
        return channelMap;
    },
);

// Returns both DMs and GMs
export const getAllDirectChannels: (state: GlobalState) => Channel[] = createSelector(
    getAllChannels,
    getDirectChannelsSet,
    (state: GlobalState): UsersState => state.entities.users,
    getTeammateNameDisplaySetting,
    (
        channels: IDMappedObjects<Channel>,
        channelSet: Set<string>,
        users: UsersState,
        teammateNameDisplay: string,
    ): Channel[] => {
        const dmChannels: Channel[] = [];
        channelSet.forEach((channelId) => {
            const channel = channels[channelId];
            if (channel) {
                dmChannels.push(completeDirectChannelInfo(users, teammateNameDisplay, channel));
            }
        });
        return dmChannels;
    },
);

export const getAllDirectChannelsNameMapInCurrentTeam: (state: GlobalState) => Record<string, Channel> = createSelector(
    getAllChannels,
    getDirectChannelsSet,
    (state: GlobalState): UsersState => state.entities.users,
    getTeammateNameDisplaySetting,
    (
        channels: IDMappedObjects<Channel>,
        channelSet: Set<string>,
        users: UsersState,
        teammateNameDisplay: string,
    ): Record<string, Channel> => {
        const channelMap: Record<string, Channel> = {};
        channelSet.forEach((id) => {
            const channel = channels[id];
            if (channel) {
                channelMap[channel.name] = completeDirectChannelInfo(users, teammateNameDisplay, channel);
            }
        });
        return channelMap;
    },
);

// Returns only GMs
export const getGroupChannels: (state: GlobalState) => Channel[] = createSelector(
    getAllChannels,
    getDirectChannelsSet,
    (state: GlobalState): UsersState => state.entities.users,
    getTeammateNameDisplaySetting,
    (
        channels: IDMappedObjects<Channel>,
        channelSet: Set<string>,
        users: UsersState,
        teammateNameDisplay: string,
    ): Channel[] => {
        const gmChannels: Channel[] = [];
        channelSet.forEach((id) => {
            const channel = channels[id];

            if (channel && channel.type === General.GM_CHANNEL) {
                gmChannels.push(completeDirectChannelInfo(users, teammateNameDisplay, channel));
            }
        });
        return gmChannels;
    },
);

export const getMyChannels: (state: GlobalState) => Channel[] = createSelector(
    getChannelsInCurrentTeam,
    getAllDirectChannels,
    getMyChannelMemberships,
    (
        channels: Channel[],
        directChannels: Channel[],
        myMembers: RelationOneToOne<Channel, ChannelMembership>,
    ): Channel[] => {
        return [...channels, ...directChannels].filter((c) => myMembers.hasOwnProperty(c.id));
    },
);

export function isLastChannel(state: GlobalState) {
    const myChannels = getMyChannels(state).filter((c) => isOpenChannel(c) || isPrivateChannel(c));

    return myChannels.length === 1;
}

export const getOtherChannels: (state: GlobalState, archived?: boolean | null) => Channel[] = createSelector(
    getChannelsInCurrentTeam,
    getMyChannelMemberships,
    (state: GlobalState, archived: boolean | undefined | null = true) => archived,
    (
        channels: Channel[],
        myMembers: RelationOneToOne<Channel, ChannelMembership>,
        archived?: boolean | null,
    ): Channel[] => {
        return channels.filter(
            (c) =>
                !myMembers.hasOwnProperty(c.id) &&
                c.type === General.OPEN_CHANNEL &&
                (archived ? true : c.delete_at === 0),
        );
    },
);

export const getMembersInCurrentChannel: (state: GlobalState) => Record<string, ChannelMembership> = createSelector(
    getCurrentChannelId,
    getChannelMembersInChannels,
    (
        currentChannelId: string,
        members: RelationOneToOne<Channel, Record<string, ChannelMembership>>,
    ): Record<string, ChannelMembership> => {
        return members[currentChannelId] ?? {};
    },
);

export const canManageChannelMembers = createSelector(
    getCurrentChannel,
    getTeams,
    (state: GlobalState) => haveICurrentChannelPermission(state, Permissions.MANAGE_PRIVATE_CHANNEL_MEMBERS),
    (state: GlobalState) => haveICurrentChannelPermission(state, Permissions.MANAGE_PUBLIC_CHANNEL_MEMBERS),
    (channel, teams, managePrivateMembers, managePublicMembers) => {
        if (!channel) {
            return false;
        }

        if (channel.delete_at !== 0) {
            return false;
        }

        const team = teams[channel.team_id];

        if (
            channel.type === General.DM_CHANNEL ||
            channel.type === General.GM_CHANNEL ||
            isDefaultChannel(channel, team)
        ) {
            return false;
        }

        if (channel.type === General.OPEN_CHANNEL) {
            return managePublicMembers;
        } else if (channel.type === General.PRIVATE_CHANNEL) {
            return managePrivateMembers;
        }

        return true;
    },
);

// Determine if the user has permissions to manage members in at least one channel of the current team
export function canManageAnyChannelMembersInCurrentTeam(state: GlobalState): boolean {
    const myChannelMemberships = getMyChannelMemberships(state);
    const myChannelsIds = Object.keys(myChannelMemberships);
    const currentTeamId = getCurrentTeamId(state);

    for (const channelId of myChannelsIds) {
        const channel = getChannel(state, channelId);

        if (!channel || channel.team_id !== currentTeamId) {
            continue;
        }

        if (
            channel.type === General.OPEN_CHANNEL &&
            haveIChannelPermission(state, currentTeamId, channelId, Permissions.MANAGE_PUBLIC_CHANNEL_MEMBERS)
        ) {
            return true;
        } else if (
            channel.type === General.PRIVATE_CHANNEL &&
            haveIChannelPermission(state, currentTeamId, channelId, Permissions.MANAGE_PRIVATE_CHANNEL_MEMBERS)
        ) {
            return true;
        }
    }

    return false;
}

export const getAllDirectChannelIds: (state: GlobalState) => string[] = createIdsSelector(
    getDirectChannelsSet,
    (directIds: Set<string>): string[] => {
        return Array.from(directIds);
    },
);

export const getChannelIdsInCurrentTeam: (state: GlobalState) => string[] = createIdsSelector(
    getCurrentTeamId,
    getChannelsInTeam,
    (currentTeamId: string, channelsInTeam: RelationOneToMany<Team, Channel>): string[] => {
        return Array.from(channelsInTeam[currentTeamId] || []);
    },
);

export const getChannelIdsForCurrentTeam: (state: GlobalState) => string[] = createIdsSelector(
    getChannelIdsInCurrentTeam,
    getAllDirectChannelIds,
    (channels, direct) => {
        return [...channels, ...direct];
    },
);

let prevUnreadChannelIds: string[] = [];

export const getUnreadChannelIds: (state: GlobalState, lastUnreadChannel?: Channel | null) => string[] = createSelector(
    getMyChannelMemberships,
    getChannelMessageCounts,
    getChannelIdsForCurrentTeam,
    (state: GlobalState, lastUnreadChannel: Channel | undefined | null = null): Channel | undefined | null =>
        lastUnreadChannel,
    (
        members: RelationOneToOne<Channel, ChannelMembership>,
        messageCounts: RelationOneToOne<Channel, ChannelMessageCount>,
        teamChannelIds: string[],
        lastUnreadChannel?: Channel | null,
    ): string[] => {
        const unreadIds = teamChannelIds.filter((id) => {
            return calculateUnreadCount(messageCounts[id], members[id]).showUnread;
        });

        if (lastUnreadChannel && members[lastUnreadChannel.id] && !unreadIds.includes(lastUnreadChannel.id)) {
            unreadIds.push(lastUnreadChannel.id);
        }

        if (!isEqual(prevUnreadChannelIds, unreadIds)) {
            prevUnreadChannelIds = unreadIds;
        }

        return prevUnreadChannelIds;
    },
);

const emptyUnreadChannels: Channel[] = [];

export const getUnreadChannels: (state: GlobalState, lastUnreadChannel?: Channel | null) => Channel[] =
    createIdsSelector(
        getCurrentUser,
        getUsers,
        getUserIdsInChannels,
        getAllChannels,
        getUnreadChannelIds,
        getTeammateNameDisplaySetting,
        (currentUser, profiles, userIdsInChannels: any, channels, unreadIds, settings) => {
            // If we receive an unread for a channel and then a mention the channel
            // won't be sorted correctly until we receive a message in another channel
            if (!currentUser) {
                return emptyUnreadChannels;
            }

            const allUnreadChannels = unreadIds
                .filter((id) => channels[id] && channels[id]?.delete_at === 0)
                .map((id) => {
                    const c = channels[id];

                    if (c.type === General.DM_CHANNEL || c.type === General.GM_CHANNEL) {
                        return completeDirectChannelDisplayName(
                            currentUser.id,
                            profiles,
                            userIdsInChannels[id],
                            settings!,
                            c,
                        );
                    }

                    return c;
                });
            return allUnreadChannels;
        },
    );

const getProfiles = (
    currentUserId: string,
    usersIdsInChannel: Set<string>,
    users: IDMappedObjects<UserProfile>,
): UserProfile[] => {
    const profiles: UserProfile[] = [];
    usersIdsInChannel.forEach((userId) => {
        if (userId !== currentUserId) {
            profiles.push(users[userId]);
        }
    });
    return profiles;
};

/**
 * Returns an array of unsorted group channels, each with an array of the user profiles in the channel attached to them.
 */
export const getChannelsWithUserProfiles: (state: GlobalState) => Array<
{
    profiles: UserProfile[];
} & Channel
> = createSelector(
    getUserIdsInChannels,
    getUsers,
    getGroupChannels,
    getCurrentUserId,
    (
        channelUserMap: RelationOneToManyUnique<Channel, UserProfile>,
        users: IDMappedObjects<UserProfile>,
        channels: Channel[],
        currentUserId: string,
    ) => {
        return channels.map(
            (
                channel: Channel,
            ): {
                profiles: UserProfile[];
            } & Channel => {
                const profiles = getProfiles(currentUserId, channelUserMap[channel.id] || new Set(), users);
                return {
                    ...channel,
                    profiles,
                };
            },
        );
    },
);

export const getDefaultChannelForTeams: (state: GlobalState) => RelationOneToOne<Team, Channel> = createSelector(
    getTeams,
    getAllChannels,
    (teams, channels) => {
        const result: RelationOneToOne<Team, Channel> = {};
        const channelsList = Object.values(channels);

        for (const channel of channelsList) {
            const team = teams[channel.team_id];
            if (channel.id === team?.primary_channel_id) {
                result[channel.team_id] = channel;
            }
        }

        return result;
    },
);

export const getMyFirstChannelForTeams: (state: GlobalState) => RelationOneToOne<Team, Channel> = createSelector(
    getAllChannels,
    getMyChannelMemberships,
    getMyTeams,
    getCurrentUser,
    (
        allChannels: IDMappedObjects<Channel>,
        myChannelMemberships: RelationOneToOne<Channel, ChannelMembership>,
        myTeams: Team[],
        currentUser: UserProfile,
    ): RelationOneToOne<Team, Channel> => {
        const locale = currentUser.locale || General.DEFAULT_LOCALE;
        const result: RelationOneToOne<Team, Channel> = {};

        for (const team of myTeams) {
            // Get a sorted array of all channels in the team that the current user is a member of
            const teamChannels = Object.values(allChannels)
                .filter(
                    (channel: Channel) =>
                        channel && channel.team_id === team.id && Boolean(myChannelMemberships[channel.id]),
                )
                .sort(sortChannelsByDisplayName.bind(null, locale));

            if (teamChannels.length === 0) {
                continue;
            }

            result[team.id] = teamChannels[0];
        }

        return result;
    },
);

export const getRedirectChannelNameForTeam = (state: GlobalState, teamId: string): string => {
    const defaultChannelForTeam = getDefaultChannelForTeams(state)[teamId];
    const myChannelMemberships = getMyChannelMemberships(state);

    if (defaultChannelForTeam) {
        const iAmMemberOfTheTeamDefaultChannel = Boolean(
            myChannelMemberships[defaultChannelForTeam.id],
        );
        const canIJoinPublicChannelsInTeam = haveITeamPermission(state, teamId, Permissions.JOIN_PUBLIC_CHANNELS);

        if (iAmMemberOfTheTeamDefaultChannel || canIJoinPublicChannelsInTeam) {
            return defaultChannelForTeam.name;
        }
    }

    const myFirstChannelForTeam = getMyFirstChannelForTeams(state)[teamId];

    if (myFirstChannelForTeam) {
        return myFirstChannelForTeam.name;
    }

    return '';
};

// isManually unread looks into state if the provided channelId is marked as unread by the user.
export function isManuallyUnread(state: GlobalState, channelId?: string): boolean {
    if (!channelId) {
        return false;
    }

    return Boolean(state.entities.channels.manuallyUnread[channelId]);
}

export function getChannelModerations(state: GlobalState, channelId: string): ChannelModeration[] {
    return state.entities.channels.channelModerations[channelId] ?? [];
}

export function isFavoriteChannel(state: GlobalState, channelId: string): boolean {
    const channel = getChannel(state, channelId);
    if (!channel) {
        return false;
    }

    const category = getCategoryInTeamByType(
        state,
        channel.team_id || getCurrentTeamId(state),
        CategoryTypes.FAVORITES,
    );

    if (!category) {
        return false;
    }

    return category.channel_ids.includes(channel.id);
}

export function filterChannelList(channelList: Channel[], filters: ChannelSearchOpts): Channel[] {
    if (!filters || (!filters.private && !filters.public && !filters.deleted && !filters.team_ids)) {
        return channelList;
    }
    let result: Channel[] = [];
    const channelType: string[] = [];
    const channels = channelList;
    if (filters.public) {
        channelType.push(General.OPEN_CHANNEL);
    }
    if (filters.private) {
        channelType.push(General.PRIVATE_CHANNEL);
    }
    if (filters.deleted) {
        channelType.push(General.ARCHIVED_CHANNEL);
    }
    channelType.forEach((type) => {
        result = result.concat(channels.filter((channel) => channel.type === type));
    });
    if (filters.team_ids && filters.team_ids.length > 0) {
        let teamResult: Channel[] = [];
        filters.team_ids.forEach((id) => {
            if (channelType.length > 0) {
                const filterResult = result.filter((channel) => channel.team_id === id);
                teamResult = teamResult.concat(filterResult);
            } else {
                teamResult = teamResult.concat(channels.filter((channel) => channel.team_id === id));
            }
        });
        result = teamResult;
    }
    return result;
}
export function searchChannelsInPolicy(
    state: GlobalState,
    policyId: string,
    term: string,
    filters: ChannelSearchOpts,
): Channel[] {
    const channelsInPolicy = getChannelsInPolicy();
    const channelArray = channelsInPolicy(state, {policyId});
    let channels = filterChannelList(channelArray, filters);
    channels = filterChannelsMatchingTerm(channels, term);

    return channels;
}

export function getDirectTeammate(state: GlobalState, channelId: string): UserProfile | undefined {
    const channel = getChannel(state, channelId);
    if (!channel) {
        return undefined;
    }

    const userIds = channel.name.split('__');
    const currentUserId = getCurrentUserId(state);

    if (userIds.length !== 2 || userIds.indexOf(currentUserId) === -1) {
        return undefined;
    }

    if (userIds[0] === userIds[1]) {
        return getUser(state, userIds[0]);
    }

    for (const id of userIds) {
        if (id !== currentUserId) {
            return getUser(state, id);
        }
    }

    return undefined;
}
