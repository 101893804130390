import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import {emitUserLoggedOutEvent} from 'actions/global_actions';

import {ExitIcon} from '@time-webkit/all/icons';
import {ActionButton} from '@time-webkit/all/molecules/icon-button';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import styles from './logout_button.module.css';

export const LogoutButton = () => {
    const [bodyMTrue] = useTypography([{size: TypographySize.BodyMTrue}]);

    const handleLogout = () => emitUserLoggedOutEvent();

    return (
        <ActionButton
            onPress={handleLogout}
            className={classNames(styles.logoutButton, bodyMTrue)}
        >
            <ExitIcon width={16} height={16}/>
            <FormattedMessage id='components.logout_button.text' defaultMessage='Log out'/>
        </ActionButton>
    );
};
