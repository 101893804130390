import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import type {User} from 'features/users';
import type {Team} from '@mattermost/types/teams';
import type {ServerThread} from '../types/threads';
import type {Post} from '@mattermost/types/posts';

type Payload = {

    /**
     * The ID of the user. This can also be "me" which will point to the current user.
     */
    userId: User['id'] | 'me';

    /**
     * The ID of the team in which the thread is.
     */
    teamId: Team['id'];

    threadId: ServerThread['id'];
    postId: Post['id'];
};

/**
 * Mark as unread from a post
 *
 * Mark a channel as being unread from a given post.
 *
 * Must have read_channel permission for the channel the post is in or if the channel is public,
 * have the read_public_channels permission for the team.
 * Must have edit_other_users permission if the user is not
 * the one marking the post for himself.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/noop
 * @example "/users/{user_id}/teams/{team_id}/threads/{thread_id}/set_unread/{post_id}"
 */
export const setUnreadThreadByPostId = createAsyncThunk(
    'threads/api/setUnreadThreadByPostId',
    async (payload: Payload, thunkAPI) => {
        const {teamId, userId, postId, threadId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/threads/${threadId}/set_unread/${postId}`;

        try {
            const {data} = await requester.post<ServerThread>(url, null, {
                ...options,
            });

            return data;
        } catch (e) {
            const error = e as AxiosError;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));

            throw error;
        }
    },
);
