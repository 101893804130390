import {useDispatch} from 'react-redux';

import {useIntl} from 'react-intl';

import {ModalDialog, ModalSize} from '@time-webkit/all/organisms/modal-dialog';
import {closeModal} from 'actions/views/modals';
import {ModalIdentifiers} from 'utils/constants';

type Props = {
    titleId: string;
    titleDefaultMessage: string;
    descriptionId?: string;
    descriptionDefaultMessage?: string;
}

export const InsufficientPermissionModal: React.FC<Props> = ({
    titleId,
    titleDefaultMessage,
    descriptionId = 'error.insufficient_permissions.desc',
    descriptionDefaultMessage = 'Contact support team to get access',
}) => {
    const dispatch = useDispatch();
    const handleClose = () => dispatch(closeModal(ModalIdentifiers.INSUFFICIENT_PERMISSION));
    const {formatMessage} = useIntl();

    return (
        <ModalDialog
            isOpen={true}
            size={ModalSize.MEDIUM}
            title={formatMessage({
                id: titleId,
                defaultMessage: titleDefaultMessage,
            })}
            onClose={handleClose}
            onConfirm={handleClose}
            confirmText={formatMessage({
                id: 'error.insufficient_permissions.confirmText',
                defaultMessage: 'Got it',
            })}
            hideCancelButton={true}
        >
            {formatMessage({
                id: descriptionId,
                defaultMessage: descriptionDefaultMessage,
            })}
        </ModalDialog>
    );
};
