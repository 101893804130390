const defaultKeyResolver = (...args: unknown[]) => args[0];

export function debounceByParams<F extends(...args: any[]) => any>(
    fn: F,
    delay: number,
    options?: {keyResolver?: (...args: Parameters<F>) => any; leading?: boolean; trailing?: boolean},
): {(...args: Parameters<F>): void; cancel: () => void} {
    const keyResolver = options?.keyResolver || defaultKeyResolver;
    const isLeading = options?.leading ?? false;
    const isTrailing = options?.trailing ?? true;

    const timersMap = new Map();

    const f = (...args: Parameters<F>) => {
        const key = keyResolver(...args);

        if (timersMap.has(key)) {
            clearTimeout(timersMap.get(key));
        } else if (isLeading) {
            fn(...args);
        }

        const timerId = setTimeout(() => {
            if (isTrailing) {
                fn(...args);
            }

            timersMap.delete(key);
        }, delay);

        timersMap.set(key, timerId);
    };

    f.cancel = () => {
        timersMap.forEach((timerId) => {
            clearTimeout(timerId);
        });
        timersMap.clear();
    };

    return f;
}
