import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import type {User} from '../types/user';

type Payload = {

    /**
     * List of user ids
     */
    userIds: Array<User['id']>;

    /**
     * Only return users that have been modified since the given Unix timestamp (in milliseconds).
     */
    since?: number;
};

/**
 * Get a list of users based on a provided list of user ids.
 *
 * Requires an active session but no other permissions.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetUsersByIds
 * @example /api/v4/users/ids
 */
export const getUsersByIds = async (payload: Payload, dispatch: AppDispatch): Promise<{users: User[]; error?: AxiosError}> => {
    const {userIds, since} = payload;

    const requester = Client4.requester;

    const options = omit(['url'], Client4.getTimeApiClientOptions({}));

    const url = `${Client4.getUrl()}/api/v4/users/ids`;

    try {
        const {data} = await requester.post<User[]>(url, userIds, {
            ...options,
            params: {
                since,
            },
        });

        return {users: data};
    } catch (e: any) {
        const error: AxiosError = e;

        dispatch(forceLogoutIfNecessaryThunkAxios(error));

        return {error, users: []};
    }
};
