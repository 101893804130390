import omit from '@tinkoff/utils/object/omit';

import {type AxiosError} from 'axios';

import {type Profile} from '../types/profile';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';

type Payload = {
    userId: string;
};

export const fetchProfile = async (payload: Payload, dispatch: AppDispatch): Promise<Profile> => {
    const {userId} = payload;
    const clientUrl = Client4.getUrl();
    const options = omit(['url'], Client4.getTimeApiClientOptions({}, !clientUrl));
    const url = `${clientUrl}/api/v4/users/${userId}/profile`;

    try {
        const {data} = await Client4.requester.get<Profile>(url, options);

        return data;
    } catch (e) {
        const error = e as AxiosError;

        //@TODO: выяснить почему нет статуса
        if (error.status === 404 || error.response?.status === 404) {
            return {
                user_id: userId,
            };
        }

        dispatch(forceLogoutIfNecessaryThunkAxios(error));

        throw error;
    }
};
