import {createSlice} from '@reduxjs/toolkit';

type AdminUiState = {
    shouldRefresh: boolean;
}

const initialState: AdminUiState = {
    shouldRefresh: false,
};

export const adminUiSlice = createSlice({
    name: 'adminUi',
    initialState,
    reducers: {
        setShouldRefresh: (state) => {
            state.shouldRefresh = true;
            return state;
        },
    },
});

export const {setShouldRefresh: setShouldAdminRefresh} = adminUiSlice.actions;

