import {useIntl} from 'react-intl';
import classNames from 'classnames';

import {Button, BUTTON_SIZE, BUTTON_VARIANT} from '../../../../atoms/button';

import styles from './styles.module.css';

export const ModalFooter: React.FunctionComponent<unknown> = (props) => {
    const {children} = props;

    return <div className={styles.footer}>{children}</div>;
};

type ConfirmFooterProps = {
    confirmText: string;
    cancelText?: string;
    onConfirm: () => void;
    onCancel?: () => void;
    isConfirmDisabled?: boolean;
    isConfirmDestructive?: boolean;
    hideCancelButton?: boolean;
    autoFocusCancelBtn?: boolean;
};

export const ConfirmFooter: React.VoidFunctionComponent<ConfirmFooterProps> = (props) => {
    const {formatMessage} = useIntl();
    const {
        cancelText = formatMessage({id: 'generic_modal.cancel', defaultMessage: 'Cancel'}),
        confirmText = formatMessage({id: 'generic_modal.confirm', defaultMessage: 'Confirm'}),
        onConfirm,
        onCancel,
        isConfirmDisabled,
        isConfirmDestructive,
        hideCancelButton = false,
        autoFocusCancelBtn = true,
    } = props;

    return (
        <div className={classNames(styles.footer, styles.confirmFooter)}>
            {
                !hideCancelButton && onCancel &&
                <Button
                    onPress={onCancel}
                    autoFocus={autoFocusCancelBtn}
                    className={styles.button}
                    variant={BUTTON_VARIANT.TERTIARY}
                    size={BUTTON_SIZE.MEDIUM}
                >
                    {cancelText}
                </Button>
            }
            <Button
                onPress={onConfirm}
                className={styles.button}
                variant={BUTTON_VARIANT.PRIMARY}
                size={BUTTON_SIZE.MEDIUM}
                isDisabled={isConfirmDisabled}
                destructive={isConfirmDestructive}
            >
                {confirmText}
            </Button>
        </div>
    );
};
