import {type AxiosRequestConfig} from 'axios';

import type {Channel} from '@mattermost/types/channels';

import {makeGetRequest} from '../request';

import {composeTeamsRoute} from './helpers';

type AutocompleteChannelsForTeamRequestParams = {

    /**
     * Team GUID
     */
    team_id: string;

    /**
     * Name or display name
     */
    name: string;

    /**
     * A flag to boost channels in select
     */
    boost?: boolean;
};

/**
 * Autocomplete public channels on a team based on the search term provided in the request URL.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/AutocompleteChannelsForTeam
 */
export function autocompleteChannelsForTeam(
    args: AutocompleteChannelsForTeamRequestParams,
    options: AxiosRequestConfig<never> = {},
) {
    const {team_id: teamId, name, boost} = args;

    const params = {
        name,
        boost,
    };

    const route = composeTeamsRoute(`/${teamId}/channels/autocomplete`);

    return makeGetRequest<Channel[]>(route, {
        ...options,
        params,
    });
}
