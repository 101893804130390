/* eslint-disable import/order */
import ru from './ru.json';
import {getConfig} from 'mattermost-redux/selectors/entities/general';

import store from 'stores/redux_store';

export type LanguageInfo = {
    value: string;
    name: string;
    order: number;
    url: string;
}

// should match the values in model/config.go
const languages: Record<string, LanguageInfo> = {
    en: {
        value: 'en',
        name: 'English (US)',
        order: 1,
        url: '',
    },
    ru: {
        value: 'ru',
        name: 'Pусский',
        order: 14,
        url: ru,
    },
};

export type Locale = keyof typeof languages;

export function getAllLanguages() {
    return languages;
}

export function getLanguages() {
    const config = getConfig(store.getState());
    if (!config.AvailableLocales) {
        return getAllLanguages();
    }
    return config.AvailableLocales.split(',').reduce<Record<Locale, LanguageInfo>>((result, locale) => {
        const language = languages[locale];
        if (language) {
            result[locale] = language;
        }
        return result;
    }, {});
}

export function getLanguageInfo(locale: Locale) {
    return getAllLanguages()[locale];
}

export function isLanguageAvailable(locale: Locale) {
    return Boolean(getLanguages()[locale]);
}
