import {closeRightHandSide} from 'actions/views/rhs';
import {addChannelToInitialCategory} from 'features/sidebar';
import {ChannelTypes} from 'mattermost-redux/action_types';
import {getSelectedChannelId} from 'selectors/rhs';
import {batchActions} from 'utils/batch_actions';
import {Client4} from 'mattermost-redux/client';
import type {AppDispatch} from 'stores/redux_store';
import type {GlobalState} from 'types/store';
import type {Channel, ChannelMembership} from '@mattermost/types/channels';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/common';
import {getChannel, getMyChannelMembership} from 'mattermost-redux/selectors/entities/channels';
import {ServerErrorIds} from 'utils/constants';
import {ClientError} from 'mattermost-redux/client/client4';
import {openInsufficientPermissionsModal} from 'actions/views/open_insufficient_permissions_modal';

export function leaveChannel(channelId: Channel['id']) {
    return async (dispatch: AppDispatch, getState: () => GlobalState) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);
        const channel = getChannel(state, channelId) as Channel | undefined;
        const membership = getMyChannelMembership(state, channelId) as ChannelMembership | undefined;

        // https://time-sentry.tinkoff.ru/organizations/sentry/issues/2635/
        const hasChannel = Boolean(channel?.id);

        Client4.trackEvent('action', 'action_channels_leave', {channel_id: channelId});

        const rhsChannelId = getSelectedChannelId(state);

        try {
            await Client4.removeFromChannel(currentUserId, channelId);

            // У нас может быть открыто окно с информацией об этом канале
            // И так как мы из него выходим, нужно закрыть и информацию о канале
            if (channelId === rhsChannelId) {
                dispatch(closeRightHandSide());
            }

            dispatch({
                type: ChannelTypes.LEAVE_CHANNEL,
                data: {
                    id: channelId,
                    user_id: currentUserId,
                    team_id: channel?.team_id,
                    type: channel?.type,
                },
            });
        } catch (error) {
            if (error instanceof ClientError && error.server_error_id === ServerErrorIds.INSUFFICIENT_PERMISSIONS) {
                dispatch(openInsufficientPermissionsModal('LEAVE_CHANNEL'));
                return {data: false};
            }

            if (!hasChannel) {
                return {data: true};
            }

            dispatch(
                batchActions([
                    {
                        type: ChannelTypes.RECEIVED_CHANNEL,
                        data: channel,
                    },
                    {
                        type: ChannelTypes.RECEIVED_MY_CHANNEL_MEMBER,
                        data: membership,
                    },
                ]),
            );

            // The category here may not be the one in which the channel was originally located,
            // much less the order in which it was placed. Treating this as a transient issue
            // for the user to resolve by refreshing or leaving again.
            dispatch(addChannelToInitialCategory({channel, setOnServer: false}));
        }

        return {data: true};
    };
}
