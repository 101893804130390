import classNames from 'classnames';
import {typographyStyles, TypographySize} from '@time-webkit/all/hooks/typography';

import {BaseAvatar} from '../base-avatar';

import type {TeamAvatarProps} from '../../types';

import {getAvatarInitials} from './get-avatar-initials';

import styles from './styles.module.css';

export const TeamAvatar = (props: TeamAvatarProps) => {
    const {teamName, size = 36, ...restProps} = props;

    const initials = getAvatarInitials(teamName);

    return (
        <BaseAvatar
            size={size}
            initials={initials}
            ariaLabel={teamName}
            alt={teamName}
            initialsClassName={classNames(styles.initials, typographyStyles[TypographySize.BodyLTrue])}
            {...restProps}
        />
    );
};
