import TipTapMentionExtension from '@tiptap/extension-mention';
import type {MentionOptions} from '@tiptap/extension-mention';
import {ReactRenderer} from '@tiptap/react';
import Suggestion from '@tiptap/suggestion';
import {getGroups} from 'mattermost-redux/api/groups/getGroups';
import {autocompleteUsers} from 'mattermost-redux/api/users/autocompleteUsers';
import {PluginKey} from 'prosemirror-state';

import {Constants} from 'utils/constants';
import {SearchTimeMeasurer, sendToStatist} from '@time-webkit/statist';

import {UserMentionsList} from './components/mention-list';
import {USER_RESULT_LIMIT, RESULT_LIMIT, TIMEOUT} from './constants';
import {transformGroupsResponse, transformUsersAutocompleteResponse} from './transformers';
import type {Mention} from './types';

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        mention: {

            /**
             * Set a mention node
             */
            setMention: () => ReturnType;
        };
    }
}

const emptyMentions: any[] = [];

const suggestionKey = new PluginKey('suggestion-timeEditorMentionExtension');

const searchTimeMeasurer = new SearchTimeMeasurer('editor.searchUser.result');

export const TimeEditorMentionExtension = TipTapMentionExtension.extend<
{
    channelId: string;
    teamId: string;
    predefinedMentions: Mention[];
    token?: string;
    withinThread: boolean;
    useGroupMentions: boolean;
} & MentionOptions
>({
    name: 'timeEditorMentionExtension',
    addStorage() {
        return {
            abortController: null,
            menu: null,
            error: null,
            hasButtonBeenPressed: false,
        };
    },

    addCommands() {
        return {
            setMention:
                () =>
                    ({chain}) => {
                        this.storage.hasButtonBeenPressed = true;
                        return chain().insertContent(' @').focus().run();
                    },
        };
    },

    addProseMirrorPlugins() {
        const options = this.options;
        const storage = this.storage;

        const getMentions = async (query: string) => {
            const prevAbortController = storage?.abortController;

            prevAbortController?.abort();

            if (typeof query === 'undefined') {
                return emptyMentions;
            }

            searchTimeMeasurer.measure(query);
            const controller = new AbortController();

            storage.abortController = controller;

            const data = await Promise.all([
                autocompleteUsers(
                    {
                        name: query,
                        in_channel: options.channelId,
                        in_team: options.teamId,
                        limit: USER_RESULT_LIMIT,
                        boost: true,
                    },
                    {
                        signal: controller.signal,
                        timeout: TIMEOUT,
                        id: `autocompleteUsers-${query}`,
                        cache: {
                            interpretHeader: false,
                            ttl: 1000 * 10,
                        },
                    },
                ).then(({data}) => {
                    return transformUsersAutocompleteResponse(data);
                }),
                options.useGroupMentions ? getGroups(
                    {
                        page: 0,
                        per_page: RESULT_LIMIT,
                        q: query,
                        filter_allow_reference: true,
                        include_member_count: false,
                        group_source: 'custom',
                    },
                    {
                        signal: controller.signal,
                        timeout: TIMEOUT,
                        cache: {
                            interpretHeader: false,
                        },
                    },
                ).then(({data}) => transformGroupsResponse(data)) : undefined,
            ]);

            const [users = [], groups = []] = data;
            const notDeletedGroups = groups.filter((group) => group.data.delete_at === 0);

            const dataMentions = users.concat(

                // TS не могёт нормально слить вместе типы
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                notDeletedGroups,
            ) as Mention[];

            const predefinedMentions = this.options.predefinedMentions.filter(({handle}) => handle?.includes(query));

            searchTimeMeasurer.measure(query, dataMentions.length);
            return predefinedMentions.concat(dataMentions);
        };

        return [
            // eslint-disable-next-line new-cap
            Suggestion({
                editor: this.editor,
                pluginKey: suggestionKey,
                char: '@',
                allowSpaces: false,
                allowedPrefixes: ['', ' '],
                command: ({editor, range, props}) => {
                    // increase range.to by one when the next node is of type "text"
                    // and starts with a space character
                    const nodeAfter = editor.view.state.selection.$to.nodeAfter;
                    const overrideSpace = nodeAfter?.text?.startsWith(' ');

                    if (overrideSpace) {
                        range.to += 1;
                    }

                    try {
                        editor
                            .chain()
                            .focus()
                            .insertContentAt(range, [
                                {
                                    type: this.name,
                                    attrs: props,
                                },
                                {
                                    type: 'text',
                                    text: ' ',
                                },
                            ])
                            .run();

                        window.getSelection()?.collapseToEnd();
                        // eslint-disable-next-line no-empty
                    } catch {}
                },
                allow: ({state, range}) => {
                    const $from = state.doc.resolve(range.from);
                    const type = state.schema.nodes[this.name];
                    const allow = Boolean($from.parent.type.contentMatch.matchType(type));

                    return allow;
                },
                render() {
                    return {
                        onStart(props) {
                            sendToStatist('editor.searchUser.tap', {
                                sourceTap: options.withinThread ? 'thread' : 'post',
                                sourceSearch: storage.hasButtonBeenPressed ? 'button' : 'type',
                            });
                            storage.hasButtonBeenPressed = false;
                            const component = new ReactRenderer(UserMentionsList, {
                                editor: props.editor,
                                props: {
                                    ...props,
                                    getMentions,
                                },
                            });

                            document.body.appendChild(component.element);

                            storage.menu = component;
                        },
                        onUpdate(props) {
                            const component = storage.menu as ReactRenderer;
                            component?.updateProps({
                                ...props,
                                getMentions,
                            });
                        },
                        onKeyDown(props) {
                            const component = storage.menu;

                            if (props.event.key === Constants.KeyCodes.ESCAPE[0] && component) {
                                if (component.element) {
                                    document.body.removeChild(component.element);
                                }
                                component.destroy();
                                storage.menu = null;

                                return true;
                            }

                            return false;
                        },
                        onExit() {
                            const component = storage.menu as ReactRenderer;
                            if (component?.element) {
                                document.body.removeChild(component?.element);
                            }
                            component?.destroy();
                            storage.menu = null;
                            searchTimeMeasurer.dispose();
                        },
                    };
                },
            }),
        ];
    },
});
