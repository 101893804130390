import React from 'react';

import {type PostImage} from 'mattermost-redux/types/posts';

import {getImageSrc, isSVGImage} from 'utils/post_utils';

interface Props {
    children: (src: string) => React.ReactNode;
    hasImageProxy: boolean;
    additionalSiteURLs?: string;
    imageMetadata?: PostImage;
    src: string;
}

export default class ExternalImage extends React.PureComponent<Props> {
    render() {
        let src = getImageSrc(this.props.src, this.props.hasImageProxy, this.props.additionalSiteURLs);

        if (isSVGImage(this.props.src, this.props.imageMetadata)) {
            src = '';
        }

        return this.props.children(src);
    }
}
