import {batchActions} from 'utils/batch_actions';

import {IntegrationTypes} from 'mattermost-redux/action_types';
import {General} from '../constants';
import {Client4} from 'mattermost-redux/client';
import {getCurrentUserId} from 'mattermost-redux/selectors/entities/users';
import {getCurrentTeamId} from 'mattermost-redux/selectors/entities/teams';

import {type DispatchFunc, type GetStateFunc, type ActionFunc} from 'mattermost-redux/types/actions';

import {
    type Command,
    type CommandArgs,
    type DialogSubmission,
    type IncomingWebhook,
    type OAuthApp,
    type OutgoingWebhook,
} from 'mattermost-redux/types/integrations';

import {bindClientFunc, forceLogoutIfNecessary} from './helpers';
export function createIncomingHook(hook: IncomingWebhook): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.createIncomingWebhook,
        onSuccess: [IntegrationTypes.RECEIVED_INCOMING_HOOK],
        params: [hook],
    });
}

export function getIncomingHook(hookId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getIncomingWebhook,
        onSuccess: [IntegrationTypes.RECEIVED_INCOMING_HOOK],
        params: [hookId],
    });
}

export function getIncomingHooks(teamId = '', page = 0, perPage: number = General.PAGE_SIZE_DEFAULT): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getIncomingWebhooks,
        onSuccess: [IntegrationTypes.RECEIVED_INCOMING_HOOKS],
        params: [teamId, page, perPage],
    });
}

export function removeIncomingHook(hookId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.removeIncomingWebhook(hookId);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        dispatch(
            batchActions([
                {
                    type: IntegrationTypes.DELETED_INCOMING_HOOK,
                    data: {id: hookId},
                },
            ]),
        );

        return {data: true};
    };
}

export function updateIncomingHook(hook: IncomingWebhook): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.updateIncomingWebhook,
        onSuccess: [IntegrationTypes.RECEIVED_INCOMING_HOOK],
        params: [hook],
    });
}

export function createOutgoingHook(hook: OutgoingWebhook): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.createOutgoingWebhook,
        onSuccess: [IntegrationTypes.RECEIVED_OUTGOING_HOOK],
        params: [hook],
    });
}

export function getOutgoingHook(hookId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getOutgoingWebhook,
        onSuccess: [IntegrationTypes.RECEIVED_OUTGOING_HOOK],
        params: [hookId],
    });
}

export function getOutgoingHooks(
    channelId = '',
    teamId = '',
    page = 0,
    perPage: number = General.PAGE_SIZE_DEFAULT,
): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getOutgoingWebhooks,
        onSuccess: [IntegrationTypes.RECEIVED_OUTGOING_HOOKS],
        params: [channelId, teamId, page, perPage],
    });
}

export function removeOutgoingHook(hookId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.removeOutgoingWebhook(hookId);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        dispatch(
            batchActions([
                {
                    type: IntegrationTypes.DELETED_OUTGOING_HOOK,
                    data: {id: hookId},
                },
            ]),
        );

        return {data: true};
    };
}

export function updateOutgoingHook(hook: OutgoingWebhook): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.updateOutgoingWebhook,
        onSuccess: [IntegrationTypes.RECEIVED_OUTGOING_HOOK],
        params: [hook],
    });
}

export function regenOutgoingHookToken(hookId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.regenOutgoingHookToken,
        onSuccess: [IntegrationTypes.RECEIVED_OUTGOING_HOOK],
        params: [hookId],
    });
}

export function getCommands(teamId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getCommandsList,
        onSuccess: [IntegrationTypes.RECEIVED_COMMANDS],
        params: [teamId],
    });
}

export function getAutocompleteCommands(
    teamId: string,
    page = 0,
    perPage: number = General.PAGE_SIZE_DEFAULT,
): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getAutocompleteCommandsList,
        onSuccess: [IntegrationTypes.RECEIVED_COMMANDS],
        params: [teamId, page, perPage],
    });
}

export function getCustomTeamCommands(teamId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getCustomTeamCommands,
        onSuccess: [IntegrationTypes.RECEIVED_CUSTOM_TEAM_COMMANDS],
        params: [teamId],
    });
}

export function addCommand(command: Command): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.addCommand,
        onSuccess: [IntegrationTypes.RECEIVED_COMMAND],
        params: [command],
    });
}

export function editCommand(command: Command): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.editCommand,
        onSuccess: [IntegrationTypes.RECEIVED_COMMAND],
        params: [command],
    });
}

export function executeCommand(command: string, args: CommandArgs): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.executeCommand,
        params: [command, args],
    });
}

export function regenCommandToken(id: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let res;
        try {
            res = await Client4.regenCommandToken(id);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        dispatch(
            batchActions([
                {
                    type: IntegrationTypes.RECEIVED_COMMAND_TOKEN,
                    data: {
                        id,
                        token: res.token,
                    },
                },
            ]),
        );

        return {data: true};
    };
}

export function deleteCommand(id: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.deleteCommand(id);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        dispatch(
            batchActions([
                {
                    type: IntegrationTypes.DELETED_COMMAND,
                    data: {id},
                },
            ]),
        );

        return {data: true};
    };
}

export function addOAuthApp(app: OAuthApp): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.createOAuthApp,
        onSuccess: [IntegrationTypes.RECEIVED_OAUTH_APP],
        params: [app],
    });
}

export function editOAuthApp(app: OAuthApp): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.editOAuthApp,
        onSuccess: IntegrationTypes.RECEIVED_OAUTH_APP,
        params: [app],
    });
}

export function getOAuthApps(page = 0, perPage: number = General.PAGE_SIZE_DEFAULT): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getOAuthApps,
        onSuccess: [IntegrationTypes.RECEIVED_OAUTH_APPS],
        params: [page, perPage],
    });
}

export function getAppsOAuthAppIDs(): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getAppsOAuthAppIDs,
        onSuccess: [IntegrationTypes.RECEIVED_APPS_OAUTH_APP_IDS],
    });
}

export function getAppsBotIDs(): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getAppsBotIDs,
        onSuccess: [IntegrationTypes.RECEIVED_APPS_BOT_IDS],
    });
}

export function getOAuthApp(appId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getOAuthApp,
        onSuccess: [IntegrationTypes.RECEIVED_OAUTH_APP],
        params: [appId],
    });
}

export function getAuthorizedOAuthApps(): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let data;
        try {
            data = await Client4.getAuthorizedOAuthApps(currentUserId);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        return {data};
    };
}

export function deauthorizeOAuthApp(clientId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.deauthorizeOAuthApp,
        params: [clientId],
    });
}

export function deleteOAuthApp(id: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.deleteOAuthApp(id);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        dispatch(
            batchActions([
                {
                    type: IntegrationTypes.DELETED_OAUTH_APP,
                    data: {id},
                },
            ]),
        );

        return {data: true};
    };
}

export function regenOAuthAppSecret(appId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.regenOAuthAppSecret,
        onSuccess: [IntegrationTypes.RECEIVED_OAUTH_APP],
        params: [appId],
    });
}

export function submitInteractiveDialog(submission: DialogSubmission): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const dialogSubmission: DialogSubmission = {...submission};
        const state = getState();
        dialogSubmission.team_id = getCurrentTeamId(state);

        let data;
        try {
            data = await Client4.submitInteractiveDialog(dialogSubmission);
        } catch (error) {
            forceLogoutIfNecessary(error, dispatch, getState);

            return {error};
        }

        return {data};
    };
}
