export type HttpErrorData = {
    status: number;
    message: string;
    responseData: any;
    url?: string;
    method?: string;
}

export abstract class HttpError extends Error {
    status: HttpErrorData['status'];
    responseData: HttpErrorData['responseData'];
    url: HttpErrorData['url'];
    method: HttpErrorData['method'];

    constructor(data: HttpErrorData) {
        super(data.message);

        this.responseData = data.responseData;
        this.method = data.method;
        this.url = data.url;
        this.status = data.status;
    }

    static create(data: HttpErrorData) {
        const httpStatus = data.status;

        if (httpStatus === 400) {
            return new InvalidRequestError(data);
        }

        if (httpStatus === 401) {
            return new NotAuthorizedError(data);
        }

        if (httpStatus === 402) {
            return new NoLicenseError(data);
        }

        if (httpStatus === 403) {
            return new NoPermissionError(data);
        }

        if (httpStatus === 404) {
            return new NotFoundError(data);
        }

        if (httpStatus === 408) {
            return new TimeoutError(data);
        }

        if (httpStatus === 429) {
            return new TooManyRequestsError(data);
        }

        if (httpStatus === 503) {
            return new UnavailableError(data);
        }

        return new UnknownHttpError(data);
    }

    toString() {
        return `[Time-API]: ${this.constructor.name} - ${this.method?.toUpperCase()} ${this.url} ${this.status} (${this.message})`;
    }
}

export class InvalidRequestError extends HttpError {}

export class NotAuthorizedError extends HttpError {}

export class NoPermissionError extends HttpError {}

export class TimeoutError extends HttpError {}

export class UnavailableError extends HttpError {}

export class UnknownHttpError extends HttpError {}

export class NoLicenseError extends HttpError {}

export class NotFoundError extends HttpError {}

export class TooManyRequestsError extends HttpError {}
