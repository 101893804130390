import {LocalStorageLRU} from '@cocalc/local-storage-lru';

import type {Channel} from '@mattermost/types/channels';
import type {Team} from '@mattermost/types/teams';
import type {UserProfile} from '@mattermost/types/users';

import {getBasePath} from 'selectors/general';

import type {GlobalState} from 'types/store';

import {StorageKeys, StoragePrefixes} from 'utils/constants';

import {type ILocalStorageStore} from './local_storage_store.types';

const getPreviousTeamIdKey = (userId: UserProfile['id']) => ['user_prev_team', userId].join(':');

const getCurrentTeamIdKey = (userId: UserProfile['id']) => ['user_cur_team', userId].join(':');

const getPreviousChannelNameKey = (userId: UserProfile['id'], teamId: Team['id']) =>
    ['user_team_prev_channel', userId, teamId].join(':');

export const getPenultimateChannelNameKey = (userId: UserProfile['id'], teamId: Team['id']) =>
    ['user_team_penultimate_channel', userId, teamId].join(':');

const getRecentEmojisKey = (userId: UserProfile['id']) => ['recent_emojis', userId].join(':');

const getWasLoggedInKey = () => 'was_logged_in';

const enableAnalyticsKey = 'ENABLE_ANALITICS';

const teamIdJoinedOnLoadKey = 'teamIdJoinedOnLoad';

const getPathScopedKey = (path: string, key: string) => {
    if (path === '' || path === '/') {
        return key;
    }

    return [path, key].join(':');
};

export class BaseLocalStorageLRU extends LocalStorageLRU {
    constructor() {
        super({
            isCandidate: (key) => key.startsWith('emoji-'),
            maxSize: 300,
        });
    }
}

class LocalStorageStoreClass extends BaseLocalStorageLRU implements ILocalStorageStore {
    private getItem(key: string, state?: GlobalState) {
        return this.get(getPathScopedKey(getBasePath(state), key));
    }

    private setItem<V extends string>(key: string, value: V, state?: GlobalState) {
        this.set(getPathScopedKey(getBasePath(state), key), value);
    }

    private removeItem(key: string, state?: GlobalState) {
        this.delete(getPathScopedKey(getBasePath(state), key));
    }

    getPreviousChannelName(userId: UserProfile['id'], teamId: Team['id'], state?: GlobalState) {
        return this.getItem(getPreviousChannelNameKey(userId, teamId), state);
    }

    setPreviousChannelName(
        userId: UserProfile['id'],
        teamId: Team['id'],
        channelName: Channel['name'],
        state?: GlobalState,
    ) {
        this.setItem(getPreviousChannelNameKey(userId, teamId), channelName, state);
    }

    getPenultimateChannelName(userId: UserProfile['id'], teamId: Team['id'], state?: GlobalState) {
        return this.getItem(getPenultimateChannelNameKey(userId, teamId), state);
    }

    setPenultimateChannelName(
        userId: UserProfile['id'],
        teamId: Team['id'],
        channelName: Channel['name'],
        state?: GlobalState,
    ) {
        this.setItem(getPenultimateChannelNameKey(userId, teamId), channelName, state);
    }

    removePreviousChannelName(userId: UserProfile['id'], teamId: Team['id'], state?: GlobalState) {
        this.setItem(
            getPreviousChannelNameKey(userId, teamId),
            this.getPenultimateChannelName(userId, teamId, state),
            state,
        );
        this.removeItem(getPenultimateChannelNameKey(userId, teamId), state);
    }

    removePenultimateChannelName(userId: UserProfile['id'], teamId: Team['id'], state?: GlobalState) {
        this.removeItem(getPenultimateChannelNameKey(userId, teamId), state);
    }

    getPreviousTeamId(userId: UserProfile['id'], state?: GlobalState) {
        return this.getItem(getPreviousTeamIdKey(userId), state);
    }

    getCurrentTeamId(userId: UserProfile['id'], state?: GlobalState) {
        return this.getItem(getCurrentTeamIdKey(userId), state);
    }

    setPreviousTeamId(userId: UserProfile['id'], teamId: Team['id'], state?: GlobalState) {
        this.setItem(getPreviousTeamIdKey(userId), teamId, state);
    }

    setCurrentTeamId(userId: UserProfile['id'], teamId: Team['id'], state?: GlobalState) {
        this.setItem(getCurrentTeamIdKey(userId), teamId, state);
    }

    /**
     * Returns the list of recently used emojis for the user in string format.
     * @param {string} userId The user ID.
     * @returns The list of emojis in string format. eg. '['smile','+1', 'pizza']'
     * @memberof LocalStorageStore
     * @example
     * const recentEmojis = LocalStorageStore.getRecentEmojis('userId', state);
     * if (recentEmojis) {
     *  const recentEmojisArray = JSON.parse(recentEmojis);
     * // do something with the emoji list
     * }
     **/
    getRecentEmojis(userId: UserProfile['id'], state?: GlobalState) {
        const recentEmojis = this.getItem(getRecentEmojisKey(userId), state);
        if (!recentEmojis) {
            return null;
        }

        return recentEmojis;
    }

    setRecentEmojis(userId: UserProfile['id'], recentEmojis = [], state?: GlobalState) {
        if (recentEmojis.length) {
            this.setItem(getRecentEmojisKey(userId), JSON.stringify(recentEmojis), state);
        }
    }

    getTeamIdJoinedOnLoad(state?: GlobalState) {
        return this.getItem(teamIdJoinedOnLoadKey, state);
    }

    setTeamIdJoinedOnLoad(teamId: Team['id'] | null, state?: GlobalState) {
        if (!teamId) {
            this.removeItem(teamIdJoinedOnLoadKey);
            return;
        }

        this.setItem(teamIdJoinedOnLoadKey, teamId, state);
    }

    setWasLoggedIn(wasLoggedIn: boolean, state?: GlobalState) {
        if (wasLoggedIn) {
            this.setItem(getWasLoggedInKey(), 'true', state);
        } else {
            this.setItem(getWasLoggedInKey(), 'false', state);
        }
    }

    getWasLoggedIn(state?: GlobalState) {
        return this.getItem(getWasLoggedInKey(), state) === 'true';
    }

    getAnnouncementStatus(text: string) {
        return this.getItem(StoragePrefixes.ANNOUNCEMENT + text);
    }

    setAnnouncementStatus(text: string, value: boolean) {
        this.setItem(StoragePrefixes.ANNOUNCEMENT + text, value.toString());
    }

    setAnalyticsEnabled(value: boolean) {
        return this.setItem(enableAnalyticsKey, value.toString());
    }

    getAnalyticsEnabled() {
        return this.getItem(enableAnalyticsKey);
    }

    setOnboardingTaskListShow(value: boolean): void {
        this.setItem(StorageKeys.ONBOARDING_TASK_CATEGORY, value.toString());
    }

    getOnboardingTaskListShow() {
        return this.getItem(StorageKeys.ONBOARDING_TASK_CATEGORY);
    }

    removeOnboardingTaskListShow() {
        this.removeItem(StorageKeys.ONBOARDING_TASK_CATEGORY);
    }

    setLastSearchTab(value: string) {
        this.setItem(StorageKeys.LAST_SEARCH_TAB, value);
    }

    getLastSearchTab() {
        return this.getItem(StorageKeys.LAST_SEARCH_TAB);
    }

    removeLastSearchTab() {
        this.removeItem(StorageKeys.LAST_SEARCH_TAB);
    }

    getLKUserChoises() {
        return this.getItem(StorageKeys.LK_USER_CHOISES);
    }

    setLKUserChoises(value: string): void {
        this.setItem(StorageKeys.LK_USER_CHOISES, value);
    }

    setLandingPreference(key: string, value: string): void {
        this.setItem(StoragePrefixes.LANDING_PREFERENCE + key, value);
    }

    getLandingPreference(key: string) {
        return this.getItem(StoragePrefixes.LANDING_PREFERENCE + key);
    }

    removeLandingPreference(key: string): void {
        this.removeItem(StoragePrefixes.LANDING_PREFERENCE + key);
    }

    setLogin(value: string) {
        this.setItem(StoragePrefixes.LOGIN, value);
    }

    removeLogin() {
        this.removeItem(StoragePrefixes.LOGIN);
    }

    setLogout(value: string) {
        this.setItem(StoragePrefixes.LOGOUT, value);
    }

    removeLogout() {
        this.removeItem(StoragePrefixes.LOGOUT);
    }

    private key(index: number) {
        const keys = this.keys();

        if (index >= keys.length || index < 0) {
            return null;
        }

        return keys[index]!;
    }

    private get length() {
        return this.keys().length;
    }

    removePrefixes(prefix: string) {
        const keys = [];

        for (let i = 0; i < this.length; i++) {
            const key = this.key(i);
            if (key && key.startsWith(prefix)) {
                keys.push(key);
            }
        }

        for (let i = 0; i < keys.length; i++) {
            this.removeItem(keys[i]!);
        }
    }
}

const LocalStorageStore: ILocalStorageStore = new LocalStorageStoreClass();

export default LocalStorageStore;
