import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';

import {ArrowBack} from '@time-webkit/all/icons/arrow_back';
import {ActionButton} from '@time-webkit/all/molecules/icon-button';
import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import styles from './back_button.module.css';

export const BackButton = ({onPress}: {onPress: () => void}) => {
    const [bodyMTrue] = useTypography([{size: TypographySize.BodyMTrue}]);

    return (
        <ActionButton
            onPress={onPress}
            className={classNames(styles.backButton, bodyMTrue)}
        >
            <ArrowBack width={16} height={16}/>
            <FormattedMessage id='web.header.back' defaultMessage='Back'/>
        </ActionButton>
    );
};
