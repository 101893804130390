import {Subject} from 'rxjs';

import {type HttpErrorObservable} from './http_error_observable';
import {HttpError, type HttpErrorData} from './http_error';

const httpError$ = new Subject<HttpError>();

export function interceptHttpError(): HttpErrorObservable {
    return httpError$.asObservable();
}

export function handleHttpError(data: HttpErrorData) {
    const httpError = HttpError.create(data);
    httpError$.next(httpError);
}
