import {BaseAvatar} from '../base-avatar';

import type {UserAvatarProps} from '../../types';

import {getAvatarInitials} from './get-avatar-initials';

import styles from './styles.module.css';

export const UserAvatar = (props: UserAvatarProps) => {
    const {username = '', text = '', ...restProps} = props;

    const initials = getAvatarInitials(text, username);

    return (
        <BaseAvatar
            initials={initials}
            ariaLabel={username}
            alt={username}
            initialsClassName={styles.initialsColors}
            {...restProps}
        />
    );
};
