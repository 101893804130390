import {type PropsWithChildren, createContext, useContext, useEffect, useState} from 'react';

import {
    type HttpError,
    type HttpErrorData,
    type HttpErrorObservable,
    interceptHttpError,
    TooManyRequestsError,
} from '@time-webkit/http';
import {sendToStatist} from '@time-webkit/statist';
import {debounceByParams} from 'utils/debounce_by_params';
import {Client4} from 'mattermost-redux/client';
import {LogLevel} from '@mattermost/types/client4';

export type HttpErrorContext = {
    httpErrorObservable: HttpErrorObservable;
};

type HttpErrorProviderProps = {
    onError: (httpError: HttpError) => void;
}

const httpErrorObservable = interceptHttpError();

const HttpErrorContext = createContext<HttpErrorContext>({httpErrorObservable});

export const HttpErrorProvider = ({children, onError}: PropsWithChildren<HttpErrorProviderProps>) => {
    useEffect(() => {
        const subscription = httpErrorObservable.subscribe(onError);

        return () => subscription.unsubscribe();
    }, [onError]);

    return (
        <HttpErrorContext.Provider value={{httpErrorObservable}}>
            {children}
        </HttpErrorContext.Provider>
    );
};

export const useHttpError = (ErrorType: new (data: HttpErrorData) => HttpError) => {
    const {httpErrorObservable} = useContext(HttpErrorContext);
    const [error, setError] = useState<HttpError | undefined>();

    useEffect(() => {
        const subscription = httpErrorObservable.subscribe((httpError) => {
            if (httpError instanceof ErrorType) {
                setError(httpError);
            }
        });
        return () => subscription.unsubscribe();
    }, [httpErrorObservable, ErrorType]);

    return error;
};

const SEND_TO_STATIST_DELAY = 1000; // Задержка между повторными вызовами sendToStatist с уникальным url

export const logTooManyRequestsError = debounceByParams((url: string) => {
    sendToStatist('app.ratelimitError', {url});
}, SEND_TO_STATIST_DELAY, {leading: true, trailing: false});

export const logHttpError = async (httpError: HttpError) => {
    if (httpError.responseData?.id === 'api.context.session_expired.app_error') {
        return;
    }

    let sendToServer = true;

    if (httpError instanceof TooManyRequestsError) {
        sendToServer = false;
    }

    if (httpError.message.includes('Failed to fetch')) {
        sendToServer = false;
    }

    if (httpError.responseData?.id) {
        sendToServer = false;
    }

    if (sendToServer) {
        try {
            await Client4.logClientError(httpError.toString(), LogLevel.Debug);
        } catch {
            // avoid crashing the app if an error sending the error occurs.
        }
    }

    // eslint-disable-next-line no-console
    console.error(httpError.toString());
};
