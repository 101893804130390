import {createSelector} from 'reselect';

import {General} from 'mattermost-redux/constants';

import {type GlobalState} from 'mattermost-redux/types/store';
import {type ClientConfig, type FeatureFlags, type ClientLicense} from 'mattermost-redux/types/config';

export function getConfig(state: GlobalState): Partial<ClientConfig> {
    return state.entities.general.config;
}

/**
 * Safely get value of a specific or known FeatureFlag
 */
export function getFeatureFlagValue(state: GlobalState, key: keyof FeatureFlags): string | undefined {
    return getConfig(state)?.[`FeatureFlag${key}` as keyof Partial<ClientConfig>];
}

export function getLicense(state: GlobalState): ClientLicense {
    return state.entities.general.license;
}

export function getCurrentUrl(state: GlobalState): string {
    return state.entities.general.credentials.url;
}

export function warnMetricsStatus(state: GlobalState): any {
    return state.entities.general.warnMetricsStatus;
}

export const canUploadFilesOnMobile: (a: GlobalState) => boolean = createSelector(
    getConfig,
    getLicense,
    (config: Partial<ClientConfig>, license: any): boolean => {
        // Defaults to true if either setting doesn't exist
        return config.EnableFileAttachments !== 'false' &&
           (license.IsLicensed === 'false' || license.Compliance === 'false' || config.EnableMobileFileUpload !== 'false');
    },
);

export const canDownloadFilesOnMobile: (a: GlobalState) => boolean = createSelector(
    getConfig,
    getLicense,
    (config: Partial<ClientConfig>, license: any): boolean => {
        // Defaults to true if the setting doesn't exist
        return license.IsLicensed === 'false' || license.Compliance === 'false' || config.EnableMobileFileDownload !== 'false';
    },
);

export const getAutolinkedUrlSchemes: (a: GlobalState) => string[] = createSelector(
    getConfig,
    (config: Partial<ClientConfig>): string[] => {
        if (!config.CustomUrlSchemes) {
            return General.DEFAULT_AUTOLINKED_URL_SCHEMES;
        }

        return [
            ...General.DEFAULT_AUTOLINKED_URL_SCHEMES,
            ...config.CustomUrlSchemes.split(','),
        ];
    },
);

export const getManagedResourcePaths: (state: GlobalState) => string[] = createSelector(
    getConfig,
    (config) => {
        if (!config.ManagedResourcePaths) {
            return [];
        }

        return config.ManagedResourcePaths.split(',').map((path) => path.trim());
    },
);

export const getServerVersion = (state: GlobalState): string => {
    return state.entities.general.serverVersion;
};

export function getFirstAdminVisitMarketplaceStatus(state: GlobalState): boolean {
    return state.entities.general.firstAdminVisitMarketplaceStatus;
}

export function getFirstAdminSetupComplete(state: GlobalState): boolean {
    return state.entities.general.firstAdminCompleteSetup;
}

export function isPerformanceDebuggingEnabled(state: GlobalState): boolean {
    return state.entities.general.config.EnableClientPerformanceDebugging === 'true';
}
