import classNames from 'classnames';

import type {ReactNode} from 'react';

import {TypographySize, useTypography} from '@time-webkit/all/hooks/typography';

import {isMobile} from 'utils/utils';

import styles from './styles.module.css';

type Props = {
    icon?: ReactNode;
    heading: ReactNode;
    body?: ReactNode;
    actions?: ReactNode;
    bottom?: ReactNode;
    leftTopActionButton?: ReactNode;
    rightTopActionButton?: ReactNode;
};

export const LockScreenLayout = (props: Props) => {
    const {
        actions,
        body,
        heading,
        icon,
        bottom,
        leftTopActionButton,
        rightTopActionButton,
    } = props;
    const isMobileView = isMobile();
    const [heading700Cls, heading400Cls, bodyCls, bodyClsNoColor] = useTypography([
        {size: TypographySize.Heading3},
        {size: TypographySize.Heading6},
        {size: TypographySize.BodyXL},
        {
            size: TypographySize.BodyXL,
            noDefaultColor: true,
        },
    ]);

    const iconCls = isMobileView ? styles.iconMobile : styles.icon;
    const titleCls = isMobileView ? heading400Cls : heading700Cls;

    return (
        <div className={classNames(styles.lockScreen, {[styles.mobileLockScreen]: isMobileView})}>
            {leftTopActionButton && <div className={classNames(styles.actionButton, styles.leftActionButton)}>{leftTopActionButton}</div>}
            {rightTopActionButton && <div className={classNames(styles.actionButton, styles.rightActionButton)}>{rightTopActionButton}</div>}
            {icon && <span className={classNames(iconCls, bottom && styles.top)}>{icon}</span>}
            <h3 className={classNames(titleCls, styles.title, bottom && !icon && styles.top)}>{heading}</h3>
            <span
                className={classNames(styles.description, {
                    [bodyClsNoColor as string]: isMobileView,
                    [styles.descriptionMobile]: isMobileView,
                    [bodyCls as string]: !isMobileView,
                })}
            >
                {body}
            </span>
            {actions}
            {bottom ? <div className={styles.bottom}>{bottom}</div> : null}
        </div>
    );
};
