import {useIntl} from 'react-intl';

import {LockScreenLayout} from '@time-webkit/all/molecules/lock-screen-layout';
import {Button, BUTTON_SIZE, BUTTON_VARIANT} from '@time-webkit/all/atoms/button';

import {LogoutButton} from '../../logout_button';

export const AccessDenied = () => {
    const {formatMessage} = useIntl();

    return (
        <LockScreenLayout
            icon={<span>&#128274;</span>}
            rightTopActionButton={<LogoutButton/>}
            heading={formatMessage({
                id: 'error_pages.access_denied.heading',
                defaultMessage: 'You don\'t have access to the teams',
            })}
            body={formatMessage({
                id: 'error_pages.access_denied.body',
                defaultMessage: 'To continue, contact the support service',
            })}
            actions={
                <Button
                    size={BUTTON_SIZE.LARGE}
                    variant={BUTTON_VARIANT.OUTLINE}
                    onPress={() => location.assign('/')}
                >
                    {formatMessage({id: 'error_pages.access_denied.retry_button', defaultMessage: 'Retry'})}
                </Button>
            }
        />
    );
};
