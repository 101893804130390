/* eslint-disable */
// Маттермостовский форк пакета marked. Был добавлен как исходник для дого, чтобы кастомизировать правила
// форматирования списка: убрать + из правила для bullet
// @TODO: перейти на актуальную версию marked, где есть возможность настройки токенов
/**
     * Block-Level Grammar
     */

var block = {
    newline: /^\n+/,
    code: /^( {4}[^\n]*(\s*\n)*)+/,
    fences: noop,
    hr: /^( *[-*_]){3,} *(?:(?:\n +)*\n|$)/,
    heading: /^ *(#{1,6}) *([^\n]+?) *#* *(?:\n+|$)/,
    nptable: noop,
    lheading: /^([^\n]+)\n *(=|-){2,} *(?:\n+|$)/,
    blockquote: /^( *>[^\n]+(\n(?!def)[^\n]+)*\n*)+/,
    list: /^( *)(bull) [\s\S]+?(?:hr|def|\n{2,}(?! )(?!\1bull )\n*|\s*$)/,
    html: /^ *(?:comment *(?:\n|\s*$)|closed *(?:\n{2,}|\s*$)|closing *(?:\n{2,}|\s*$))/,
    def: /^ *\[([^\]]+)\]: *<?((?:\w*?:(?:\/\/)?|\/|#)[^\s>]+)>?(?: +["(]([^\n]+)[")])? *(?:\n+|$)/,
    table: noop,
    paragraph: /^((?:[^\n]+\n?(?!hr|heading|lheading|blockquote|tag|def))+)\n*/,
    text: /^[^\n]+/,
};

// убрали +
block.bullet = /(?:[*-]|\d+\.)/;

block.item = /^( *)(bull) [^\n]*(?:\n(?!\1bull )[^\n]*)*/;
block.item = replace(block.item, 'gm')(/bull/g, block.bullet)();

block.list = replace(block.list)(/bull/g, block.bullet)('hr', '\\n+(?=\\1?(?:[-*_] *){3,}(?:\\n+|$))')('def', '\\n+(?=' + block.def.source + ')')();

block.blockquote = replace(block.blockquote)('def', block.def)();

block._tag = '(?!(?:' +
      'a|em|strong|small|s|cite|q|dfn|abbr|data|time|code' +
      '|var|samp|kbd|sub|sup|i|b|u|mark|ruby|rt|rp|bdi|bdo' +
      '|span|br|wbr|ins|del|img)\\b)\\w+(?!:/|[^\\w\\s@]*@)\\b';

block._comment = /<!--(?!-?>)[\s\S]*?-->/;

block.html = replace(block.html)('comment', /<!--[\s\S]*?-->/)('closed', /<(tag)[\s\S]+?<\/\1>/)('closing', /<tag(?:"[^"]*"|'[^']*'|[^'">])*?>/)(/tag/g, block._tag)();

block.paragraph = replace(block.paragraph)('hr', block.hr)('heading', block.heading)('lheading', block.lheading)('blockquote', block.blockquote)('tag', '<' + block._tag)('def', block.def)();

/**
     * Normal Block Grammar
     */

block.normal = merge({}, block);

/**
     * GFM Block Grammar
     */

block.gfm = merge({}, block.normal, {
    fences: /^ {0,3}(`{3,}|~{3,})([^`~\n]*)\n(?:|([\s\S]*?)\n)(?: {0,3}\1[~`]* *(?:\n+|$)|$)/,
    paragraph: /^/,
    heading: /^ *(#{1,6}) +([^\n]+?) *#* *(?:\n+|$)/,
});

block.gfm.paragraph = replace(block.paragraph)('(?!', '(?!' +
        block.gfm.fences.source.replace('\\1', '\\2') + '|' +
        block.list.source.replace('\\1', '\\3') + '|')();

/**
     * GFM + Tables Block Grammar
     */

block.tables = merge({}, block.gfm, {
    nptable: /^ *(\S.*\|.*)\n *([-:]+ *\|[-| :]*)\n((?:.*\|.*(?:\n|$))*)\n*/,
    table: /^ *\|(.+)\n *\|( *[-:]+[-| :]*)\n((?: *\|.*(?:\n|$))*)\n*/,
});

/**
     * Block Lexer
     */

function Lexer(options) {
    this.options = options || marked.defaults;
    this.rules = block.normal;

    if (this.options.gfm) {
        if (this.options.tables) {
            this.rules = block.tables;
        } else {
            this.rules = block.gfm;
        }
    }
}

/**
     * Expose Block Rules
     */

Lexer.rules = block;

/**
     * Static Lex Method
     */

Lexer.lex = function(src, options) {
    var lexer = new Lexer(options);
    return lexer.lex(src);
};

/**
     * Preprocessing
     */

Lexer.prototype.lex = function(src) {
    src = src.
        replace(/\r\n|\r/g, '\n').
        replace(/\t/g, '    ').
        replace(/\u00a0/g, ' ').
        replace(/\u2424/g, '\n');

    return this.token(src, true, false, [], 1);
};

/**
     * Lexing
     */

var MAX_DEPTH = 100;

Lexer.prototype.token = function(src, top, bq, links, depth) {
    var tokens = [];
    var next;
    var loose;
    var cap;
    var bull;
    var b;
    var item;
    var space;
    var i;
    var l;
    var token;
    var ordered;

    // @hmhealey We shouldn't end up this deep under regular circumstances
    if (depth > MAX_DEPTH) {
        return {
            tokens: [{
                type: 'text',
                text: src,
            }],
            links,
        };
    }

    while (src) {
        // newline
        if (cap = this.rules.newline.exec(src)) {
            src = src.substring(cap[0].length);
            if (cap[0].length > 1) {
                tokens.push({
                    type: 'space',
                    raw: cap[0],
                });
            }
        }

        // code
        if (cap = this.rules.code.exec(src)) {
            src = src.substring(cap[0].length);
            cap = cap[0].replace(/^ {4}/gm, '');
            tokens.push({
                type: 'code',
                text: !this.options.pedantic ? rtrim(cap, '\n') : cap,
                raw: cap[0],
            });
            continue;
        }

        // fences (gfm)
        if (cap = this.rules.fences.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'code',
                lang: cap[2] ? cap[2].trim() : cap[2],
                text: cap[3] || '',
                raw: cap[0],
            });
            continue;
        }

        // heading
        if (cap = this.rules.heading.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'heading',
                depth: cap[1].length,
                text: cap[2],
                raw: cap[0],
            });
            continue;
        }

        // table no leading pipe (gfm)
        if (top && (cap = this.rules.nptable.exec(src))) {
            src = src.substring(cap[0].length);

            item = {
                type: 'table',
                header: splitOnPipes(cap[1]),
                align: cap[2].replace(/^ *|\| *$/g, '').split(/ *\| */),
                cells: cap[3].replace(/\n$/, '').split('\n'),
                raw: cap[0],
            };

            for (i = 0; i < item.align.length; i++) {
                if ((/^ *-+: *$/).test(item.align[i])) {
                    item.align[i] = 'right';
                } else if ((/^ *:-+: *$/).test(item.align[i])) {
                    item.align[i] = 'center';
                } else if ((/^ *:-+ *$/).test(item.align[i])) {
                    item.align[i] = 'left';
                } else {
                    item.align[i] = null;
                }
            }

            for (i = 0; i < item.cells.length; i++) {
                item.cells[i] = splitOnPipes(item.cells[i]);
            }

            tokens.push(item);

            continue;
        }

        // lheading
        if (cap = this.rules.lheading.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'heading',
                depth: cap[2] === '=' ? 1 : 2,
                text: cap[1],
                raw: cap[0],
            });
            continue;
        }

        // hr
        if (cap = this.rules.hr.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'hr',
                raw: cap[0],
            });
            continue;
        }

        // blockquote
        if (cap = this.rules.blockquote.exec(src)) {
            src = src.substring(cap[0].length);

            tokens.push({
                type: 'blockquote_start',
                raw: '',
            });

            cap = cap[0].replace(/^ *> ?/gm, '');

            // Pass `top` to keep the current
            // "toplevel" state. This is exactly
            // how markdown.pl works.
            tokens = tokens.concat(this.token(cap, top, true, links, depth + 1).tokens);

            /**
             * Нужно перенести raw с последнего элемента цитаты на закрывающий
             * токен цитаты, чтобы переносы строки учитывались для самой цитаты,
             * а не для этого элемента.
             */
            const buffer = tokens[tokens.length - 1].raw;
            tokens[tokens.length - 1].raw = '';

            tokens.push({
                type: 'blockquote_end',
                raw: buffer,
            });

            continue;
        }

        // list
        if (cap = this.rules.list.exec(src)) {
            src = src.substring(cap[0].length);
            bull = cap[2];
            ordered = bull.length > 1;

            tokens.push({
                type: 'list_start',
                ordered,
                start: ordered ? parseInt(bull) : 0,
                raw: '',
            });

            const rootListCap = cap[0];

            // Get each top-level item.
            cap = cap[0].match(this.rules.item);
            next = false;
            l = cap.length;
            i = 0;

            for (; i < l; i++) {
                item = cap[i];

                // Remove the list item's bullet
                // so it is seen as the next token.
                space = item.length;
                bull = (/^ *([*+-]|\d+\.) +/).exec(item)[1];
                item = item.replace(/^ *([*+-]|\d+\.) +/, '');

                // @hmhealey only allow newlines in list items if they are followed by indentation to indicate that
                // they're intended to actually be part of the list
                var newlinesInItem = (/^([^\n]+)\n(\S[\s\S]+)$/).exec(item);
                if (newlinesInItem && newlinesInItem.length > 0) {
                    item = newlinesInItem[1];

                    // add any unprocessed input back onto src and exit the list
                    src = newlinesInItem[2] + '\n' + cap.slice(i + 1).join('\n') + src;
                    l = i + 1;
                }

                // Outdent whatever the
                // list item contains. Hacky.
                if (item.indexOf('\n ') !== -1) {
                    space -= item.length;
                    item = !this.options.pedantic ? item.replace(new RegExp('^ {1,' + space + '}', 'gm'), '') : item.replace(/^ {1,4}/gm, '');
                }

                // Determine whether the next list item belongs here.
                // Backpedal if it does not belong in this list.
                if (this.options.smartLists && i !== l - 1) {
                    b = block.bullet.exec(cap[i + 1])[0];
                    if (bull !== b && !(bull.length > 1 && b.length > 1)) {
                        src = cap.slice(i + 1).join('\n') + src;
                        i = l - 1;
                    }
                }

                // Determine whether item is loose or not.
                // Use: /(^|\n)(?! )[^\n]+\n\n(?!\s*$)/
                // for discount behavior.
                loose = next || (/\n\n(?!\s*$)/).test(item);
                if (i !== l - 1) {
                    next = item.charAt(item.length - 1) === '\n';
                    if (!loose) {
                        loose = next;
                    }
                }

                token = {
                    type: loose ? 'loose_item_start' : 'list_item_start',
                    raw: '',
                };

                // @hmhealey only provide an overridden bullet value for the first list item
                // so that autonumbering still works
                if (ordered && i === 0) {
                    token.bullet = bull;
                }

                tokens.push(token);

                // Recurse.
                tokens = tokens.concat(this.token(item, false, bq, links, depth + 1).tokens);

                tokens.push({
                    type: 'list_item_end',
                    raw: '',
                });
            }

            tokens.push({
                type: 'list_end',
                raw: rootListCap,
                depth,
            });

            continue;
        }

        // html
        if (cap = this.rules.html.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: this.options.sanitize ? 'paragraph' : 'html',
                pre: !this.options.sanitizer &&
              (cap[1] === 'pre' || cap[1] === 'script' || cap[1] === 'style'),
                text: cap[0],
                raw: cap[0],
            });
            continue;
        }

        // def
        if ((!bq && top) && (cap = this.rules.def.exec(src))) {
            src = src.substring(cap[0].length);
            links[cap[1].toLowerCase()] = {
                href: cap[2],
                title: cap[3],
            };
            continue;
        }

        // table (gfm)
        if (top && (cap = this.rules.table.exec(src))) {
            src = src.substring(cap[0].length);

            item = {
                type: 'table',
                header: splitOnPipes(cap[1].replace(/^ *| *\| *$/g, '')),
                align: cap[2].replace(/^ *|\| *$/g, '').split(/ *\| */),
                cells: cap[3].replace(/(?: *\| *)?\n$/, '').split('\n'),
                raw: cap[0],
            };

            for (i = 0; i < item.align.length; i++) {
                if ((/^ *-+: *$/).test(item.align[i])) {
                    item.align[i] = 'right';
                } else if ((/^ *:-+: *$/).test(item.align[i])) {
                    item.align[i] = 'center';
                } else if ((/^ *:-+ *$/).test(item.align[i])) {
                    item.align[i] = 'left';
                } else {
                    item.align[i] = null;
                }
            }

            for (i = 0; i < item.cells.length; i++) {
                item.cells[i] = splitOnPipes(item.cells[i].replace(/^ *\| *| *\| *$/g, ''));
            }

            tokens.push(item);

            continue;
        }

        // top-level paragraph
        if (top && (cap = this.rules.paragraph.exec(src))) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'paragraph',
                text: cap[1].charAt(cap[1].length - 1) === '\n' ? cap[1].slice(0, -1) : cap[1],
                raw: cap[0],
            });
            continue;
        }

        // text
        if (cap = this.rules.text.exec(src)) {
            // Top-level should never reach here.
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'text',
                text: cap[0],
                raw: cap[0],
            });
            continue;
        }

        if (src) {
            throw new
            Error('Infinite loop on byte: ' + src.charCodeAt(0));
        }
    }

    return {
        tokens,
        links,
    };
};

/**
     * Inline-Level Grammar
     */

var inline = {
    escape: /^\\([`*{}\[\]()#+\-.!_>|~]|\\(?!\w))/,
    autolink: /^<((?:[^ >]+(@|:\/)|www\d{0,3}\.)[^ >]+)>/,
    url: noop,
    tag: '^comment' +
          '|^</[a-zA-Z][\\w:-]*\\s*>' + // self-closing tag
          '|^<[a-zA-Z][\\w-]*(?:attribute)*?\\s*/?>' + // open tag
          '|^<\\?[\\s\\S]*?\\?>' + // processing instruction, e.g. <?php ?>
          '|^<![a-zA-Z]+\\s[\\s\\S]*?>' + // declaration, e.g. <!DOCTYPE html>
          '|^<!\\[CDATA\\[[\\s\\S]*?\\]\\]>', // CDATA section
    link: /^!?\[(inside)\]\((href)\)/,
    reflink: /^!?\[(inside)\]\s*\[([^\]]*)\]/,
    nolink: /^!?\[((?:\[[^\]]*\]|[^\[\]])*)\]/,
    strong: /^__([\s\S]+?)__(?!_)|^\*\*([\s\S]+?)\*\*(?!\*)/,
    em: /^_([^\s_])_(?!_)|^\*([^\s*"<])\*(?!\*)|^_([^\s][\s\S]*?[^\s_])_(?!_|[^\spunctuation])|^_([^\s_][\s\S]*?[^\s])_(?!_|[^\spunctuation])|^\*([^\s"<][\s\S]*?[^\s*])\*(?!\*)|^\*([^\s*"<][\s\S]*?[^\s])\*(?!\*)/,
    code: /^(`+)([^`]|[^`][\s\S]*?[^`])\1(?!`)/,
    br: /^ {2,}\n(?!\s*$)/,
    del: noop,
    text: /^(`+|[^`])(?:[\s\S]*?(?:(?=[\\<!\[`*]|\B\$|\b_|$)|[^ ](?= {2,}\n))|(?= {2,}\n))/,
    inlinelatex: /^\$([^\$\n]+)\$(?!\w)/,
};

inline._attribute = /\s+[a-zA-Z:_][\w.:-]*(?:\s*=\s*"[^"]*"|\s*=\s*'[^']*'|\s*=\s*[^\s"'=<>`]+)?/;

inline.tag = edit(inline.tag).
    replace('comment', block._comment).
    replace('attribute', inline._attribute).
    getRegex();

// list of punctuation marks from common mark spec
// without ` and ] to workaround Rule 17 (inline code blocks/links)
inline._punctuation = '!"#$%&\'()*+,\\-./:;<=>?@\\[^_{|}~';
inline.em = edit(inline.em).replace(/punctuation/g, inline._punctuation).getRegex();

inline._inside = /(?:\[[^\]]*\]|[^\[\]]|\](?=[^\[]*\]))*/;
inline._href = /(?:[^()]|\([^()]*\)|\((?:[^()]*\([^()]*\))+[^()]*\))*/;
inline.link = replace(inline.link)('inside', inline._inside)('href', inline._href)();

// @hmhealey extra regex to separate the url from the title in a link
inline.href = /^\s*<?([\s\S]*?)>?(?:\s+['"]([\s\S]*?)['"])?\s*$/;

inline.reflink = replace(inline.reflink)('inside', inline._inside)();

/**
     * Normal Inline Grammar
     */

inline.normal = merge({}, inline);

/**
     * Pedantic Inline Grammar
     */

inline.pedantic = merge({}, inline.normal, {
    strong: /^__(?=\S)([\s\S]*?\S)__(?!_)|^\*\*(?=\S)([\s\S]*?\S)\*\*(?!\*)/,
    em: /^_(?=\S)([\s\S]*?\S)_(?!_)|^\*(?=\S)([\s\S]*?\S)\*(?!\*)/,
});

/**
     * GFM Inline Grammar
     */

inline.gfm = merge({}, inline.normal, {
    escape: replace(inline.escape)('])', '~|])')(),
    url: /^((?:[A-Za-z][A-Za-z\d.+-]*:(?:\/{1,3}|[\p{L}\d%])|www\d{0,3}[.]|[\p{L}\d.-]+[.]\p{L}{2,4}\/)(?:\[[\da-f:]+\]|[^\s`!()\[\]\{;:'",<>?«»“”‘’]|[`!\[\]\{;:'",<>?«»“”‘’](?=[^\s()<>])|\((?:[^\s()<>]|(?:\([^\s()<>]+\)))*\))+)/u,
    del: /^~~(?=\S)([\s\S]*?\S)~~/,
    text: replace(inline.text)(']|', '~]|\\b[a-z][a-z0-9.+-]{1,31}:|\\bwww\d{0,3}\.|')(),
});

/**
     * GFM + Line Breaks Inline Grammar
     */

inline.breaks = merge({}, inline.gfm, {
    br: replace(inline.br)('{2,}', '*')(),
    text: replace(inline.gfm.text)('{2,}', '*')(),
});

/**
     * Inline Lexer & Compiler
     */

function InlineLexer(links, options) {
    this.options = options || marked.defaults;
    this.links = links;
    this.rules = inline.normal;
    this.renderer = this.options.renderer || new Renderer();
    this.renderer.options = this.options;

    if (!this.links) {
        throw new
        Error('Tokens array requires a `links` property.');
    }

    if (this.options.gfm) {
        if (this.options.breaks) {
            this.rules = inline.breaks;
        } else {
            this.rules = inline.gfm;
        }
    } else if (this.options.pedantic) {
        this.rules = inline.pedantic;
    }
}

/**
     * Expose Inline Rules
     */

InlineLexer.rules = inline;

/**
     * Static Lexing/Compiling Method
     */

InlineLexer.output = function(src, links, options) {
    var inline = new InlineLexer(links, options);
    return inline.output(src);
};

/**
     * Lexing/Compiling
     */

InlineLexer.prototype.output = function(src) {
    var link;
    var text;
    var href;
    var title;
    var cap;
    var subcap;
    var tokens = [];
    var remaining = -1;

    while (src) {
        // prevent DOS due to a failing regex
        if (src.length === remaining) {
            throw new
            Error('Nothing consumed on last loop at: ' + src);
        }
        remaining = src.length;

        // escape
        if (cap = this.rules.escape.exec(src)) {
            src = src.substring(cap[0].length);

            tokens.push({
                type: 'text',
                text: cap[1],
            });
            continue;
        }

        // autolink
        if (cap = this.rules.autolink.exec(src)) {
            src = src.substring(cap[0].length);
            if (cap[2] === '@') {
                text = cap[1].charAt(6) === ':' ? this.mangle(cap[1].substring(7)) : this.mangle(cap[1]);
                text = escape(text);
                href = this.mangle('mailto:') + text;
            } else {
                text = escape(cap[1]);
                href = text;
            }

            tokens.push({
                type: 'link',
                text,
                title: null,
                href,
            });
            continue;
        }

        // url (gfm)
        if (!this.inLink && (cap = this.rules.url.exec(src))) {
            var url = cap[0];
            while ((/[?!.,,:*_~'"]$/).test(url)) {
                url = url.substring(0, url.length - 1);
            }

            src = src.substring(url.length);
            text = escape(url);
            href = text;

            tokens.push({
                type: 'link',
                text,
                title: null,
                href,
                isUrl: true,
            });
            continue;
        }

        // tag
        if (cap = this.rules.tag.exec(src)) {
            if (!this.inLink && (/^<a /i).test(cap[0])) {
                this.inLink = true;
            } else if (this.inLink && (/^<\/a>/i).test(cap[0])) {
                this.inLink = false;
            }
            src = src.substring(cap[0].length);

            if (this.options.sanitize) {
                if (this.options.sanitizer) {
                    tokens.push({
                        type: 'text',
                        text: this.options.sanitizer(cap[0]),
                    });
                } else {
                    tokens.push({
                        type: 'text',
                        text: escape(cap[0]),
                    });
                }
            } else {
                tokens.push({
                    type: 'text',
                    text: cap[0],
                });
            }
            continue;
        }

        // link
        if (cap = this.rules.link.exec(src)) {
            src = src.substring(cap[0].length);
            this.inLink = true;
            subcap = this.rules.href.exec(cap[2]);
            tokens.push({
                type: 'preformatted',
                text: this.outputLink(cap, {
                    href: subcap[1],
                    title: subcap[2],
                }),
            });
            this.inLink = false;
            continue;
        }

        // reflink, nolink
        if ((cap = this.rules.reflink.exec(src)) ||
            (cap = this.rules.nolink.exec(src))) {
            src = src.substring(cap[0].length);
            link = (cap[2] || cap[1]).replace(/\s+/g, ' ');
            link = this.links[link.toLowerCase()];
            if (!link || !link.href) {
                tokens.push({
                    type: 'text',
                    text: cap[0].charAt(0),
                });
                src = cap[0].substring(1) + src;
                continue;
            }
            this.inLink = true;
            tokens.push({
                type: 'preformatted',
                text: this.outputLink(cap, link),
            });
            this.inLink = false;
            continue;
        }

        // strong
        if (cap = this.rules.strong.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'strong',
                text: this.output(cap[2] || cap[1]),
            });
            continue;
        }

        // em
        if (cap = this.rules.em.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'em',
                text: this.output(cap[6] || cap[5] || cap[4] || cap[3] || cap[2] || cap[1]),
            });
            continue;
        }

        // codespan
        if (cap = this.rules.code.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'code',
                text: escape(cap[2].trim(), true),
            });
            continue;
        }

        // br
        if (cap = this.rules.br.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'br',
            });
            continue;
        }

        // del (gfm)
        if (cap = this.rules.del.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'del',
                text: this.output(cap[1]),
            });
            continue;
        }

        // inlinelatex
        if (this.options.inlinelatex && (cap = this.rules.inlinelatex.exec(src))) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'inlinelatex',
                text: cap[1].trim(),
            });
            continue;
        }

        // text
        if (cap = this.rules.text.exec(src)) {
            src = src.substring(cap[0].length);
            tokens.push({
                type: 'text',
                text: escape(this.smartypants(cap[0])),
            });
            continue;
        }

        if (src) {
            throw new
            Error('Infinite loop on byte: ' + src.charCodeAt(0));
        }
    }

    // combine adjacent text tokens into one
    tokens = tokens.reduce((arr, token) => {
        if (arr.length > 0) {
            var prevToken = arr[arr.length - 1];

            if (prevToken.type === 'text' && token.type === 'text') {
                prevToken.text += token.text;
            } else {
                arr.push(token);
            }

            return arr;
        }
        return [token];
    }, []);

    return this.renderTokens(tokens);
};

InlineLexer.prototype.renderTokens = function(tokens) {
    var out = '';

    for (var i = 0; i < tokens.length; i++) {
        var token = tokens[i];

        switch (token.type) {
        case 'text':
            out += this.renderer.text(token.text);
            break;
        case 'link':
            out += this.renderer.link(token.href, token.title, token.text, Boolean(token.isUrl));
            break;
        case 'preformatted':
            out += token.text;
            break;
        case 'strong':
            out += this.renderer.strong(token.text);
            break;
        case 'em':
            out += this.renderer.em(token.text);
            break;
        case 'code':
            out += this.renderer.codespan(token.text);
            break;
        case 'br':
            out += this.renderer.br();
            break;
        case 'del':
            out += this.renderer.del(token.text);
            break;
        case 'inlinelatex':
            out += this.renderer.inlinelatex(token.text);
            break;
        default:
            console.log('unrecognized inline token type ' + token.type);
        }
    }

    return out;
};

/**
     * Compile Link
     */

InlineLexer.prototype.outputLink = function(cap, link) {
    var href = escape(link.href);
    var title = link.title ? escape(link.title) : null;

    return cap[0].charAt(0) !== '!' ? this.renderer.link(href, title, this.output(cap[1]), false) : this.renderer.image(href, title, escape(cap[1]));
};

/**
     * Smartypants Transformations
     */

InlineLexer.prototype.smartypants = function(text) {
    if (!this.options.smartypants) {
        return text;
    }
    return text.

        // em-dashes
        replace(/---/g, '\u2014').

        // en-dashes
        replace(/--/g, '\u2013').

        // opening singles
        replace(/(^|[-\u2014/(\[{"\s])'/g, '$1\u2018').

        // closing singles & apostrophes
        replace(/'/g, '\u2019').

        // opening doubles
        replace(/(^|[-\u2014/(\[{\u2018\s])"/g, '$1\u201c').

        // closing doubles
        replace(/"/g, '\u201d').

        // ellipses
        replace(/\.{3}/g, '\u2026');
};

/**
     * Mangle Links
     */

InlineLexer.prototype.mangle = function(text) {
    if (!this.options.mangle) {
        return text;
    }
    var out = '';
    var l = text.length;
    var i = 0;
    var ch;

    for (; i < l; i++) {
        ch = text.charCodeAt(i);
        if (Math.random() > 0.5) {
            ch = 'x' + ch.toString(16);
        }
        out += '&#' + ch + ';';
    }

    return out;
};

/**
     * Renderer
     */

function Renderer(options) {
    this.options = options || {};
}

Renderer.prototype.code = function(code, lang, escaped) {
    if (this.options.highlight) {
        var out = this.options.highlight(code, lang);
        if (out != null && out !== code) {
            escaped = true;
            code = out;
        }
    }

    if (!lang) {
        return '<pre><code>' +
          (escaped ? code : escape(code, true)) +
          '</code></pre>';
    }

    return '<pre><code class="' +
        this.options.langPrefix +
        escape(lang, true) +
        '">' +
        (escaped ? code : escape(code, true)) +
        '\n</code></pre>\n';
};

Renderer.prototype.blockquote = function(quote) {
    return '<blockquote>\n' + quote + '</blockquote>\n';
};

Renderer.prototype.html = function(html) {
    return html;
};

Renderer.prototype.heading = function(text, level, raw) {
    return '<h' +
        level +
        ' id="' +
        this.options.headerPrefix +
        raw.toLowerCase().replace(/[^\w]+/g, '-') +
        '">' +
        text +
        '</h' +
        level +
        '>\n';
};

Renderer.prototype.hr = function() {
    return this.options.xhtml ? '<hr/>\n' : '<hr>\n';
};

Renderer.prototype.list = function(body, ordered, start) {
    var type = ordered ? 'ol' : 'ul';

    var out = '<' + type;
    if (start && start !== 1) {
        out += 'start="' + start + '"';
    }
    out += '>\n' + body + '</' + type + '>\n';

    return out;
};

Renderer.prototype.listitem = function(text) {
    return '<li>' + text + '</li>\n';
};

Renderer.prototype.paragraph = function(text) {
    return '<p>' + text + '</p>\n';
};

Renderer.prototype.table = function(header, body) {
    return '<table>\n' +
        '<thead>\n' +
        header +
        '</thead>\n' +
        '<tbody>\n' +
        body +
        '</tbody>\n' +
        '</table>\n';
};

Renderer.prototype.tablerow = function(content) {
    return '<tr>\n' + content + '</tr>\n';
};

Renderer.prototype.tablecell = function(content, flags) {
    var type = flags.header ? 'th' : 'td';
    var tag = flags.align ? '<' + type + ' style="text-align:' + flags.align + '">' : '<' + type + '>';
    return tag + content + '</' + type + '>\n';
};

// span level renderer
Renderer.prototype.strong = function(text) {
    return '<strong>' + text + '</strong>';
};

Renderer.prototype.em = function(text) {
    return '<em>' + text + '</em>';
};

Renderer.prototype.codespan = function(text) {
    return '<code>' + text + '</code>';
};

Renderer.prototype.inlinelatex = function(text) {
    //Return a helper object that is replaced inside of mattermost.
    return `<span data-inline-latex="${escape(text)}"></span>`;
};

Renderer.prototype.br = function() {
    return this.options.xhtml ? '<br/>' : '<br>';
};

Renderer.prototype.del = function(text) {
    return '<del>' + text + '</del>';
};

Renderer.prototype.isSafeLinkHref = function(href) {
    try {
        var protocol = decodeURIComponent(unescape(href)).
            replace(/[^\w:]/g, '').
            toLowerCase();
    } catch (e) {
        return false;
    }

    if (protocol.indexOf('javascript:') === 0 || protocol.indexOf('vbscript:') === 0 || protocol.indexOf('data:') === 0) {
        return false;
    }

    return true;
}

Renderer.prototype.link = function(href, title, text) {
    if (this.options.sanitize && !this.isSafeLinkHref(href)) {
        return ''
    }
    var out = '<a href="' + href + '"';
    if (title) {
        out += ' title="' + title + '"';
    }
    out += '>' + text + '</a>';
    return out;
};

Renderer.prototype.image = function(href, title, text) {
    var out = '<img src="' + href + '" alt="' + text + '"';
    if (title) {
        out += ' title="' + title + '"';
    }
    out += this.options.xhtml ? '/>' : '>';
    return out;
};

Renderer.prototype.text = function(text) {
    return text;
};

/**
 * @see https://jira.tcsbank.ru/browse/TIME-4401
 */
Renderer.prototype.extendedbr = function() {
    return '<p style="height: 8px; margin: 0;"></p>';
}

/**
     * Parsing & Compiling
     */

function Parser(options) {
    this.tokens = [];
    this.token = null;
    this.options = options || marked.defaults;
    this.options.renderer = this.options.renderer || new Renderer();
    this.renderer = this.options.renderer;
    this.renderer.options = this.options;
}

/**
     * Static Parse Method
     */

Parser.parse = function(tokens, links, options, renderer) {
    var parser = new Parser(options, renderer);
    return parser.parse(tokens, links);
};

/**
     * Parse Loop
     */

Parser.prototype.parse = function(tokens, links) {
    this.inline = new InlineLexer(links, this.options, this.renderer);
    this.tokens = [];

    for (let i = tokens.length - 1; i >= 0; --i) {
        const token = tokens[i];
        const hasTokenRawValue = token.raw && token.raw.length > 1;
        const isNotLastToken = i !== tokens.length - 1;
        const isNotSpaceOrNestedList = token.type !== 'space' && (token.type !== 'list_end' || token.depth === 1);

        if (hasTokenRawValue && isNotLastToken && isNotSpaceOrNestedList) {
            const doubleLineBreak = token.type === 'blockquote_end' || token.type === 'list_end';
            let lastCharAt =  doubleLineBreak ? token.raw.length - 3 : token.raw.length - 2;

            while (token.raw[lastCharAt] === '\n') {
                this.tokens.push({
                    type: 'extended_br',
                    text: '\n',
                    raw: '\n',
                });

                lastCharAt--;
            }
        }

        this.tokens.push(tokens[i]);
    }

    var out = '';
    while (this.next()) {
        out += this.tok();
    }

    return out;
};

/**
     * Next Token
     */

Parser.prototype.next = function() {
    return this.token = this.tokens.pop();
};

/**
     * Preview Next Token
     */

Parser.prototype.peek = function() {
    return this.tokens[this.tokens.length - 1] || 0;
};

/**
     * Parse Text Tokens
     */

Parser.prototype.parseText = function() {
    var body = this.token.text;

    while (this.peek().type === 'text') {
        body += '\n' + this.next().text;
    }

    return this.inline.output(body);
};

/**
     * Parse Current Token
     */

Parser.prototype.tok = function() {
    switch (this.token.type) {
    case 'extended_br': {
        return this.renderer.extendedbr();
    }
    case 'space': {
        return '';
    }
    case 'hr': {
        return this.renderer.hr();
    }
    case 'heading': {
        return this.renderer.heading(
            this.inline.output(this.token.text),
            this.token.depth,
            this.token.text);
    }
    case 'code': {
        return this.renderer.code(this.token.text,
            this.token.lang,
            this.token.escaped);
    }
    case 'table': {
        var header = '';
        var body = '';
        var i;
        var row;
        var cell;
        var flags;
        var j;

        // header
        cell = '';
        for (i = 0; i < this.token.header.length; i++) {
            flags = {header: true, align: this.token.align[i]};
            cell += this.renderer.tablecell(
                this.inline.output(this.token.header[i]),
                {header: true, align: this.token.align[i]},
            );
        }
        header += this.renderer.tablerow(cell);

        for (i = 0; i < this.token.cells.length; i++) {
            row = this.token.cells[i];

            cell = '';
            for (j = 0; j < row.length; j++) {
                cell += this.renderer.tablecell(
                    this.inline.output(row[j]),
                    {header: false, align: this.token.align[j]},
                );
            }

            body += this.renderer.tablerow(cell);
        }
        return this.renderer.table(header, body);
    }
    case 'blockquote_start': {
        var body = '';

        while (this.next().type !== 'blockquote_end') {
            body += this.tok();
        }

        return this.renderer.blockquote(body);
    }
    case 'list_start': {
        body = '';
        var ordered = this.token.ordered;
        var start = this.token.start;

        while (this.next().type !== 'list_end') {
            body += this.tok();
        }

        return this.renderer.list(body, ordered, start);
    }
    case 'list_item_start': {
        var body = '';
        var bullet = this.token.bullet;

        while (this.next().type !== 'list_item_end') {
            body += this.token.type === 'text' ? this.parseText() : this.tok();
        }

        return this.renderer.listitem(body, bullet);
    }
    case 'loose_item_start': {
        var body = '';
        var bullet = this.token.bullet;

        while (this.next().type !== 'list_item_end') {
            body += this.tok();
        }

        return this.renderer.listitem(body, bullet);
    }
    case 'html': {
        var html = !this.token.pre && !this.options.pedantic ? this.inline.output(this.token.text) : this.token.text;
        return this.renderer.html(html);
    }
    case 'paragraph': {
        return this.renderer.paragraph(this.inline.output(this.token.text));
    }
    case 'text': {
        return this.renderer.paragraph(this.parseText());
    }
    }
};

/**
     * Helpers
     */

function escape(html, encode) {
    return html.
        replace(!encode ? /&(?!#?\w+;)/g : /&/g, '&amp;').
        replace(/</g, '&lt;').
        replace(/>/g, '&gt;').
        replace(/"/g, '&quot;').
        replace(/'/g, '&apos;');
}

function unescape(html) {
    // explicitly match decimal, hex, and named HTML entities
    return html.replace(/&(#(?:\d+)|(?:#x[0-9A-Fa-f]+)|(?:\w+));?/g, (_, n) => {
        n = n.toLowerCase();
        if (n === 'colon') {
            return ':';
        }
        if (n.charAt(0) === '#') {
            return n.charAt(1) === 'x' ? String.fromCharCode(parseInt(n.substring(2), 16)) : String.fromCharCode(Number(n.substring(1)));
        }
        return '';
    });
}

function edit(regex, opt) {
    regex = regex.source || regex;
    opt = opt || '';
    return {
        replace(name, val) {
            val = val.source || val;
            val = val.replace(/(^|[^\[])\^/g, '$1');
            regex = regex.replace(name, val);
            return this;
        },
        getRegex() {
            return new RegExp(regex, opt);
        },
    };
}

function replace(regex, opt) {
    regex = regex.source;
    opt = opt || '';
    return function self(name, val) {
        if (!name) {
            return new RegExp(regex, opt);
        }
        val = val.source || val;
        val = val.replace(/(^|[^\[])\^/g, '$1');
        regex = regex.replace(name, val);
        return self;
    };
}

function noop() {}
noop.exec = noop;

function merge(obj) {
    var i = 1;
    var target;
    var key;

    for (; i < arguments.length; i++) {
        target = arguments[i];
        for (key in target) {
            if (Object.prototype.hasOwnProperty.call(target, key)) {
                obj[key] = target[key];
            }
        }
    }

    return obj;
}

function splitOnPipes(str) {
    var start = 0;
    var parts = [];

    for (var i = 0; i < str.length; i++) {
        if (str[i] === '|' && (i === 0 || str[i - 1] !== '\\')) {
            parts.push(str.substring(start, i).trim());
            start = i + 1;
        }

        if (i === str.length - 1) {
            parts.push(str.substring(start, str.length).trim());
        }
    }

    return parts;
}

// Remove trailing 'c's. Equivalent to str.replace(/c*$/, '').
// /c*$/ is vulnerable to REDOS.
// invert: Remove suffix of non-c chars instead. Default falsey.
function rtrim(str, c, invert) {
    if (str.length === 0) {
        return '';
    }

    // Length of suffix matching the invert condition.
    var suffLen = 0;

    // Step left until we fail to match the invert condition.
    while (suffLen < str.length) {
        var currChar = str.charAt(str.length - suffLen - 1);
        if (currChar === c && !invert) {
            suffLen++;
        } else if (currChar !== c && invert) {
            suffLen++;
        } else {
            break;
        }
    }

    return str.substr(0, str.length - suffLen);
}

/**
     * Marked
     */

function marked(src, opt, callback) {
    if (callback || typeof opt === 'function') {
        if (!callback) {
            callback = opt;
            opt = null;
        }

        opt = merge({}, marked.defaults, opt || {});

        var highlight = opt.highlight;
        var lexed;
        var pending;
        var i = 0;

        try {
            lexed = Lexer.lex(src, opt);
        } catch (e) {
            return callback(e);
        }

        pending = tokens.length;

        var done = function(err) {
            if (err) {
                opt.highlight = highlight;
                return callback(err);
            }

            var out;

            try {
                out = Parser.parse(lexed.tokens, lexed.links, opt);
            } catch (e) {
                err = e;
            }

            opt.highlight = highlight;

            return err ? callback(err) : callback(null, out);
        };

        if (!highlight || highlight.length < 3) {
            return done();
        }

        delete opt.highlight;

        if (!pending) {
            return done();
        }

        for (; i < tokens.length; i++) {
            (function(token) {
                if (token.type !== 'code') {
                    return --pending || done();
                }
                return highlight(token.text, token.lang, (err, code) => {
                    if (err) {
                        return done(err);
                    }
                    if (code == null || code === token.text) {
                        return --pending || done();
                    }
                    token.text = code;
                    token.escaped = true;
                    --pending || done();
                });
            }(tokens[i]));
        }

        return;
    }
    try {
        if (opt) {
            opt = merge({}, marked.defaults, opt);
        }

        var lexed = Lexer.lex(src, opt);

        return Parser.parse(lexed.tokens, lexed.links, opt);
    } catch (e) {
        if ((opt || marked.defaults).silent) {
            return '<p>An error occured:</p><pre>' +
            escape(String(e.message), true) +
            '</pre>';
        }
        throw e;
    }
}

/**
     * Options
     */

marked.options =
    marked.setOptions = function(opt) {
        merge(marked.defaults, opt);
        return marked;
    };

marked.defaults = {
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    sanitizer: null,
    mangle: false,
    smartLists: false,
    silent: false,
    highlight: null,
    langPrefix: 'lang-',
    smartypants: false,
    headerPrefix: '',
    renderer: new Renderer(),
    xhtml: false,
    inlinelatex: false,
};

/**
     * Expose
     */

marked.Parser = Parser;
marked.parser = Parser.parse;

marked.Renderer = Renderer;

marked.Lexer = Lexer;
marked.lexer = Lexer.lex;

marked.InlineLexer = InlineLexer;
marked.inlineLexer = InlineLexer.output;

marked.parse = marked;

export default marked;
