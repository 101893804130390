import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {ChannelCategory} from '@mattermost/types/channel_categories';
import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';

type Payload = {

    /**
     * Team GUID
     */
    teamId: string;

    /**
     * User GUID
     */
    userId: string;

    /**
     * Category GUID
     */
    categoryId: ChannelCategory['id'];
};

/**
 * Returns a single sidebar category for the user on the given team.
 *
 * Must be authenticated and have the `list_team_channels` permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetSidebarCategoryForTeamForUser
 * @example /api/v4/users/{user_id}/teams/{team_id}/channels/categories/{category_id}
 */
export const getSidebarCategoryForTeamForUser = createAsyncThunk(
    'sidebar/api/getSidebarCategoryForTeamForUser',
    async (payload: Payload, thunkAPI) => {
        const {userId, teamId, categoryId} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/channels/categories/${categoryId}`;

        try {
            const {data} = await requester.get<ChannelCategory>(url, {
                ...options,
                signal: thunkAPI.signal,
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));

            throw error;
        }
    },
);
