import {logger} from '@tinkoff/logger';
import {DateTime} from 'luxon';

// eslint-disable-next-line no-process-env
const IS_DEV = process.env.NODE_ENV === 'development';

export const TiMeLogger = logger({
    name: 'Time',
    extensions: IS_DEV ? [
        {
            extend(logObj) {
                const name = logObj.name;

                return {
                    ...logObj,
                    name: `${DateTime.now().toFormat('HH:mm:ss.SSS')} - ${name}`,
                };
            },
        },
    ] : [],
});
