import identity from '@tinkoff/utils/function/identity';

import marked from 'packages/marked';

import {getConfig} from 'mattermost-redux/selectors/entities/general';

import store from 'stores/redux_store';

import {convertEntityToCharacter} from 'utils/text_formatting';

import RemoveMarkdown from 'utils/markdown/remove_markdown';

import type EmojiMap from 'utils/emoji_map';

import type {MarkedOptions} from 'packages/marked';

import Renderer from './renderer';
import {customTimestampRenderer} from './custom_timestamp_renderer';

const removeMarkdown = new RemoveMarkdown();

export function format(text: string, options = {}, emojiMap?: EmojiMap) {
    return formatWithRenderer(
        text,
        new Renderer(
            {
                breaks: true,
            },
            options,
            emojiMap,
        ),
    );
}

export function formatWithRenderer(text: string, renderer: marked.Renderer) {
    const config = getConfig(store.getState());

    const markdownOptions = {
        renderer,
        sanitize: true,
        gfm: true,
        tables: true,
        mangle: false,
        breaks: true,
        inlinelatex: config.EnableLatex === 'true' && config.EnableInlineLatex === 'true',
    } as MarkedOptions;

    const replaceBracketsInLinkIfNeeded = (/(`{1,3})([^`]+?)\1/g).test(text) ? identity : replaceBracketsInLink;

    return marked(replaceBracketsInLinkIfNeeded(stripImageLinks(formatCustomTimestamps(text))), markdownOptions).trim();
}

// exсept for thumbs.gfycat.com
function stripImageLinks(text: string): string {
    return text.replace(
        /!\[(.+)\]\((?!.*thumbs\.gfycat\.com|.*cdn-tinkoff\.ru|.*tinkoff\.ru|.*\/\/tcsbank\.ru|.*alert-images-mumbai\.s3\.amazonaws\.com|.*cardsmobile\.ru|.*tbank\.ru)(.+)\)/gm,
        '[$1]($2)',
    );
}

export function formatCustomTimestamps(text: string) {
    return text.replace(/<t:([0-9]*)(:([tdfTDFR]+))?>/, (_: string, timestamp: string, p2?: string, type?: string) => {
        return customTimestampRenderer(Number(timestamp) * 1000, type);
    });
}

const linkStartRegexp = /\[[^\]]+]\(/g;

export function replaceBracketsInLink(text: string): string {
    /**
     * Складывается впечатление, что здесь мог быть просто стек и
     * urlEncode, добавил тривиальную задачу для проверки
     * https://jira.tcsbank.ru/browse/TIME-7971
     */
    let result = text;
    let prevIndex = result.length;
    [...result.matchAll(linkStartRegexp)].reverse().forEach((match) => {
        const index = match?.index;
        if (index === undefined) {
            return;
        }
        const startIndex = index + match[0]!.length;
        let endIndex = -1;
        let braces = 1;
        for (let i = startIndex; i < result.length; i++) {
            const char = result[i];
            if (char === '(') {
                braces++;
            } else if (char === ')') {
                braces--;
            }
            if (!braces) {
                endIndex = i;
                break;
            }
        }
        if (endIndex !== -1 && endIndex < prevIndex) {
            const link = result.substring(startIndex, endIndex).replace(/\(/g, '%28').replace(/\)/g, '%29');
            result = result.substr(0, startIndex) + link + result.substr(endIndex);
            prevIndex = index;
        }
    });
    return result;
}

export function stripMarkdown(text: string) {
    if (typeof text === 'string' && text.length > 0) {
        return convertEntityToCharacter(formatWithRenderer(text, removeMarkdown)).trim();
    }

    return text;
}
