import {useIntl} from 'react-intl';

import {Button, BUTTON_SIZE, BUTTON_VARIANT} from '@time-webkit/all/atoms/button';

import {LockScreenLayout} from '@time-webkit/all/molecules/lock-screen-layout';

export const LockScreenForNoLicensesToJoin = () => {
    const {formatMessage} = useIntl();

    return (
        <LockScreenLayout
            icon={<span>&#128274;</span>}
            heading={formatMessage({
                id: 'error.license.noLicense_to_join.title',
                defaultMessage: 'Failed to join',
            })}
            body={formatMessage({
                id: 'error.license.noLicense_to_join.description',
                defaultMessage: 'No licenses available — contact support service',
            })}
            actions={
                <Button
                    size={BUTTON_SIZE.LARGE}
                    variant={BUTTON_VARIANT.OUTLINE}
                    onPress={() => location.reload()}
                >
                    {formatMessage({id: 'error.license.noLicense.action', defaultMessage: 'Retry'})}
                </Button>
            }
        />
    );
};
