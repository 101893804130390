import {memo, type FC, type ReactNode, type TimeHTMLAttributes} from 'react';

export type Props = {
    value: Date;
    children?: ReactNode;
} & TimeHTMLAttributes<HTMLTimeElement>;

const SemanticTime: FC<Props> = ({
    value,
    children,
    ...props
}: Props) => {
    return (
        <time
            {...props}
            dateTime={value.toISOString()}
        >
            {children}
        </time>
    );
};

export default memo(SemanticTime);
