import {useState} from 'react';
import classNames from 'classnames';

import {typographyStyles} from '../../../../../hooks/typography';

import {type BaseAvatarProps} from '../../types';

import styles from './styles.module.css';

export const BaseAvatar = (props: BaseAvatarProps) => {
    const {
        inline = false,
        size = 48,
        src,
        className,
        alt,
        ariaLabel,
        initials,
        initialsClassName,
    } = props;

    const [isImgLoadingFailed, setIsImgLoadingFailed] = useState(false);
    const shouldMountImg = Boolean(src);

    const wrapperCls = classNames([
        styles.base,
        typographyStyles.base,
        styles[`size${size}`],
        className,
    ]);

    return (
        <div className={classNames(wrapperCls, {[styles.baseInline]: inline})} aria-label={ariaLabel}>
            {shouldMountImg && (
                <div className={styles.avatar}>
                    <img
                        className={classNames(styles.img, {[styles.imgError]: isImgLoadingFailed})}
                        src={src}
                        loading='lazy'
                        alt={alt}
                        onError={() => setIsImgLoadingFailed(true)}
                    />
                </div>
            )}
            <div
                className={classNames(styles.initials, initialsClassName, {[styles.initialsShown]: isImgLoadingFailed})}
            >
                {initials}
            </div>
        </div>
    );
};
