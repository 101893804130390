import {createSlice} from '@reduxjs/toolkit';

export type TeamSidebarState = {
    isOpen: boolean;
}

const initialState: TeamSidebarState = {
    isOpen: true,
};

export const teamSidebarSlice = createSlice({
    name: 'teamSidebar',
    initialState,
    reducers: {
        toggleTeamSidebar: (state) => {
            state.isOpen = !state.isOpen;
        },
    },
});

export const {toggleTeamSidebar} = teamSidebarSlice.actions;
