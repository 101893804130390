import {InsufficientPermissionModal} from 'components/insufficient_permission_modal';
import {ModalIdentifiers} from 'utils/constants';

import {openModal} from './modals';

const insufficientPermissionModalData = {
    LEAVE_TEAM: {
        titleId: 'error.insufficient_permissions.leave_team.title',
        titleDefaultMessage: "You don't have enough permissions to leave the team",
    },
    LEAVE_CHANNEL: {
        titleId: 'error.insufficient_permissions.leave_channel.title',
        titleDefaultMessage: "You don't have enough permissions to leave the channel",
    },
    VIEW_TEAM_MEMBERS: {
        titleId: 'error.insufficient_permissions.view_team_members.title',
        titleDefaultMessage: "You don't have enough rights to view the team members",
        descriptionId: 'error.insufficient_permissions.desc_full',
        descriptionDefaultMessage: 'Contact support team to get access the section',
    },
    VIEW_CHANNEL_MEMBERS: {
        titleId: 'error.insufficient_permissions.view_channel_members.title',
        titleDefaultMessage: "You don't have enough rights to view the channel members",
        descriptionId: 'error.insufficient_permissions.desc_full',
        descriptionDefaultMessage: 'Contact support team to get access the section',
    },
    ARCHIVE_CHANNEL: {
        titleId: 'error.insufficient_permissions.archive_channel.title',
        titleDefaultMessage: "You don't have enough permissions to archive the channel",
    },
    UNARCHIVE_CHANNEL: {
        titleId: 'error.insufficient_permissions.unarchive_channel.title',
        titleDefaultMessage: "You don't have enough permissions to unarchive the channel",
    },
    VIEW_CHANNEL: {
        titleId: 'error.insufficient_permissions.view_channel.title',
        titleDefaultMessage: "You don't have enough permissions to viewing channel",
    },
    CREATE_GM_CHANNEL: {
        titleId: 'error.insufficient_permissions.create_gm.title',
        titleDefaultMessage: "You don't have enough permissions to create a conversation",
    },
    ADD_TO_CHANNEL: {
        titleId: 'error.insufficient_permissions.add_to_channel.title',
        titleDefaultMessage: "You don't have enough permissions to add members",
    },
    VIEW_USER_GROUP: {
        titleId: 'error.insufficient_permissions.view_user_group.title',
        titleDefaultMessage: "You can't view User Groups",
        descriptionId: 'error.insufficient_permissions.desc_full',
        descriptionDefaultMessage: 'Contact support team to get access the section',
    },
} as const;

type InsufficientPermissionModalIds = keyof typeof insufficientPermissionModalData;

export const openInsufficientPermissionsModal = (modalId: InsufficientPermissionModalIds) =>
    openModal({
        modalId: ModalIdentifiers.INSUFFICIENT_PERMISSION,
        dialogType: InsufficientPermissionModal,
        dialogProps: insufficientPermissionModalData[modalId],
    });
