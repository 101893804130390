import {type ErrorObject} from 'serialize-error';

import {ErrorTypes} from 'mattermost-redux/action_types';
import {type DispatchFunc, type ActionFunc} from 'mattermost-redux/types/actions';

export function dismissErrorObject(index: number) {
    return {
        type: ErrorTypes.DISMISS_ERROR,
        index,
        data: null,
    };
}

export function dismissFirstDisplayableErrorObject() {
    return {
        type: ErrorTypes.DISMISS_FIRST_DISPLAYABLE_ERROR,
        data: null,
    };
}

export function dismissError(index: number): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        dispatch(dismissErrorObject(index));

        return {data: true};
    };
}

export function dismissFirstDisplayableError(): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        dispatch(dismissFirstDisplayableErrorObject());

        return {data: true};
    };
}

export function getLogErrorAction(error: ErrorObject, displayable = false) {
    return {
        type: ErrorTypes.LOG_ERROR,
        displayable,
        error,
        data: null,
    };
}

export function clearErrors(): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        dispatch({type: ErrorTypes.CLEAR_ERRORS, data: null});

        return {data: true};
    };
}
