import {useCallback, useLayoutEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {useEventListener} from 'usehooks-ts';

import Constants from 'utils/constants';

import * as Utils from 'utils/utils';

import {toggleTeamSidebar} from './slices/team_sidebar_settings';
import {selectTeamSidebarOpen} from './selectors/select_team_sidebar_open';

export const useTeamSidebarToggler = () => {
    const [isOpen, allowToOpen] = useSelector(selectTeamSidebarOpen);
    const dispatch = useDispatch();

    const root = useRef<Element | null>(null);

    if (!root.current) {
        root.current = document.querySelector('#root');
    }

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        if (!allowToOpen) {
            return;
        }

        if ((e.ctrlKey || e.metaKey) && Utils.isKeyPressed(e, Constants.KeyCodes.F12)) {
            dispatch(toggleTeamSidebar());
        }
    }, [allowToOpen, dispatch]);

    useEventListener('keydown', handleKeyDown);

    useLayoutEffect(() => {
        if (!root.current) {
            return;
        }

        if (isOpen) {
            root.current.classList.add('multi-teams');
            return;
        }
        root.current.classList.remove('multi-teams');
    }, [isOpen]);

    return isOpen;
};
