/* eslint-disable max-lines */
import React from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {SendIcon} from '@mattermost/compass-icons/components';
import {AxiosError} from 'axios';

import {Avatar} from '@time-webkit/all/atoms/avatar';

import type {UserStatus} from 'packages/ui-kit/src/types/general';

import EventEmitter from 'mattermost-redux/utils/event_emitter';
import StatusIcon from 'components/status_icon';
import OverlayTrigger from 'components/overlay_trigger';
import Tooltip from 'components/tooltip';
import {browserHistory} from 'utils/browser_history';
import * as GlobalActions from 'actions/global_actions';
import Constants, {ModalIdentifiers, UserStatuses} from 'utils/constants';
import Pluggable from 'plugins/pluggable';
import AddUserToChannelModal from 'components/add_user_to_channel_modal';
import Popover from 'components/widgets/popover';
import SharedUserIndicator from 'components/shared_user_indicator';
import CustomStatusEmoji from 'components/custom_status/custom_status_emoji';
import type {UserCustomStatus, UserProfile} from 'mattermost-redux/types/users';
import type {ServerError} from 'mattermost-redux/types/errors';
import type {ModalData} from 'types/actions';
import CustomStatus from 'components/custom_status';
import {DetailsArea} from 'components/profile_popover/details_area';
import UserAddIcon from 'components/widgets/icons/user_add_icon';
import ArchiveIcon from 'components/widgets/icons/archive_icon';
import MessagesIcon from 'components/widgets/icons/messages_icon';
import {isGuestPlus, isGuest} from 'mattermost-redux/utils/user_utils';
import BotBadge from 'components/widgets/badges/bot_badge';
import GuestPlusBadge from 'components/widgets/badges/guest_plus_badge';
import GuestBadge from 'components/widgets/badges/guest_badge';
import {Constants as StatistConstants} from '@time-webkit/statist';
import './profile_popover.scss';
import type {Profile} from 'features/profiles';
import {Client4} from 'mattermost-redux/client';

// Hack: there is a cyclic dependency; I have no time to find a solution. :-(
let Markdown: any = () => null;
import(/* webpackChunkName: "components-markdown"*/ 'components/markdown').then((module) => {
    Markdown = module.default;
});

interface ProfilePopoverProps extends Omit<React.ComponentProps<typeof Popover>, 'id'> {

    /**
     * Если ProfilePopover расположен в модалке, в этом параметре нужно передать id родительского элемента
     */
    appendId?: string;

    displayName: string;

    /**
     * Source URL from the image to display in the popover
     */
    src: string;

    /**
     * Source URL from the image that should override default image
     */
    overwriteIcon?: string;

    /**
     * Set to true of the popover was opened from a webhook post
     */
    fromWebhook?: boolean;

    /**
     * User the popover is being opened for
     */
    user?: UserProfile;
    profile?: Profile;
    userId: string;
    channelId?: string;

    /**
     * Status for the user, either 'offline', 'away', 'dnd' or 'online'
     */
    status?: UserStatus;
    hideStatus?: boolean;

    /**
     * Function to call to hide the popover
     */
    hide?: () => void;

    /**
     * Вариант без кнопок
     */
    isInteractive?: boolean;

    /**
     * Set to true if the popover was opened from the right-hand
     * sidebar (comment thread, search results, etc.)
     */
    isRHS?: boolean;
    hasMention?: boolean;
    isMobileView: boolean;

    /**
     * Returns state of modals in redux for determing which need to be closed
     */
    modals?: {
        modalState: {
            [modalId: string]: {
                open: boolean;
                dialogProps: Record<string, any>;
                dialogType: React.ComponentType;
            };
        };
    };
    currentTeamId: string;

    /**
     * @internal
     */
    currentUserId: string;
    customStatus?: UserCustomStatus | null;

    /**
     * @internal
     */
    teamUrl: string;

    /**
     * @internal
     */
    userRoleTitle: string;

    /**
     * The overwritten username that should be shown at the top of the popover
     */
    overwriteName?: React.ReactNode;

    /**
     * Имеет ли право пользователь открыть личную беседу с другим пользователем
     */
    canOpenDirectChannel: boolean;

    /**
     * Имеет ли право пользователь добавлять пользователей в каналы
     */
    canAddUserToChannel: boolean;

    /**
     * @internal
     */
    actions: {
        openModal: <P>(modalData: ModalData<P>) => void;
        closeModal: (modalId: string) => void;
        showChannelInfo: (channelId: string) => void;
        showUserInfo: (userId: string) => void;
        openDirectChannelToUserId: (userId?: string) => Promise<{error: ServerError}>;
        getMembershipForEntities: (teamId: string, userId: string, channelId?: string) => Promise<void>;
        getChannelByName: (teamId: string, channelName: string, includeDeleted?: boolean) => void;
        getStatusesByIds: (userIds: string[]) => void;
        getProfileIfAllowed: (userId: string) => void;
    };
}

interface DataForRender {
    urlSrc?: string;
    fullName?: any;
    description?: any;
    customStatus?: any;
    sharedIcon?: any;
    details?: any;
    buttons?: any;
    role?: any;
    roleContrast?: any;
}

type ProfilePopoverState = {
    loadingDMChannel?: string;
    hasAddToChannelPossibility: boolean;
};

const EMOJI_STYLE = {
    marginLeft: 6,
    marginTop: -2,
};

/**
 * The profile popover, or hovercard, that appears with user information when clicking
 * on the username or profile picture of a user.
 */
class ProfilePopover extends React.PureComponent<ProfilePopoverProps, ProfilePopoverState> {
    abortController?: AbortController;

    static defaultProps = {
        isRHS: false,
        hasMention: false,
        status: UserStatuses.OFFLINE,
        customStatus: null,
        isInteractive: true,
    };

    state = {
        loadingDMChannel: undefined,
        hasAddToChannelPossibility: !isGuest(this.props.user?.roles),
    };

    componentDidMount() {
        const {currentTeamId, userId, channelId, overwriteIcon, overwriteName} = this.props;
        if (currentTeamId && userId) {
            this.props.actions.getStatusesByIds([userId]);
            this.props.actions.getMembershipForEntities(currentTeamId, userId, channelId);
        }

        if (!overwriteIcon && !overwriteName) {
            this.props.actions.getProfileIfAllowed(userId);
        }

        if (isGuest(this.props.user?.roles)) {
            this.abortController = new AbortController();

            Client4.getUserAddChannelPossibility(userId, this.abortController.signal)
                .then(({status}) => {
                    this.setState({hasAddToChannelPossibility: status});
                })
                .catch((error) =>
                    error.status_code !== AxiosError.ERR_CANCELED && this.setState({hasAddToChannelPossibility: false}),
                );
        }

        EventEmitter.emit(StatistConstants.AnalyticEvents.PROFILE_POPOVER_OPENED, userId);
    }

    componentWillUnmount(): void {
        this.abortController?.abort();
    }

    handleShowDirectChannel = (e: any) => {
        const {actions} = this.props;
        e.preventDefault();
        if (!this.props.user) {
            return;
        }
        const user = this.props.user;
        if (this.state.loadingDMChannel !== undefined) {
            return;
        }
        this.setState({loadingDMChannel: user.id});
        actions.openDirectChannelToUserId(user.id).then((result: {error: ServerError}) => {
            if (!result.error) {
                if (this.props.isMobileView) {
                    GlobalActions.emitCloseRightHandSide();
                }
                this.setState({loadingDMChannel: undefined});
                this.props.hide?.();
                browserHistory.push(`${this.props.teamUrl}/messages/@${user.username}`);
            }
        });
        this.handleCloseModals();
    };

    handleMentionKeyClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!this.props.user) {
            return;
        }
        this.props.hide?.();
        EventEmitter.emit('mention_key_click', this.props.user.username, this.props.isRHS);
        this.handleCloseModals();
    };

    handleAddToChannel = (e: any) => {
        e.preventDefault();
        if (!this.props.user) {
            return;
        }
        this.props.hide?.();
        this.props.actions.openModal({
            modalId: ModalIdentifiers.ADD_USER_TO_CHANNEL,
            dialogType: AddUserToChannelModal,
            dialogProps: {user: this.props.user},
        });
        this.handleCloseModals();
    };

    handleCloseModals = () => {
        const {modals} = this.props;
        for (const modal in modals?.modalState) {
            if (!Object.prototype.hasOwnProperty.call(modals, modal)) {
                continue;
            }
            if (modals?.modalState[modal]?.open) {
                this.props.actions.closeModal(modal);
            }
        }
    };

    showProfileInfo = () => {
        if (this.props.user) {
            this.props.actions.showUserInfo(this.props.user.id);
            this.props.hide?.();
        }
    };

    getOverrideDataForRender(): DataForRender | null {
        const haveOverrideProp = this.props.overwriteIcon || this.props.overwriteName;
        if (!haveOverrideProp) {
            return null;
        }

        const fullName = this.props.overwriteName || '';
        const urlSrc = this.props.overwriteIcon || this.props.src || '';
        const description = (
            <>
                <FormattedMessage
                    id='user_profile.account.post_was_created'
                    defaultMessage='This post was created by an integration from'
                />{' '}
                <a onClick={this.handleMentionKeyClick}>{`@${this.props.user?.username}`}</a>
            </>
        );

        const details = this.props.user && (
            <DetailsArea
                appendId={this.props.appendId}
                user={{
                    username: this.props.user.username,
                }}
                teamUrl={this.props.teamUrl}
                hideEmail={true}
                hideTime={true}
            />
        );

        return {urlSrc, fullName, description, details};
    }

    getDataForRender(): DataForRender {
        if (!this.props.user) {
            return {};
        }
        let fullName: any;
        let description: any;
        let customStatus: any;
        let sharedIcon: any;
        let buttons: any;
        let role: any;
        let roleContrast = false;
        const urlSrc = this.props.src;

        const isBot = this.props.user.is_bot;
        const isDeleted = this.props.user.delete_at;
        const isMyProfile = this.props.user.id === this.props.currentUserId;
        const profile = this.props.profile;

        if (isDeleted) {
            roleContrast = true;
            role = (
                <>
                    <ArchiveIcon
                        className='icon'
                        size={12}
                    />
                    <FormattedMessage
                        id='channel_info_rhs.about_area.deactivatedUser'
                        defaultMessage='Deactivated account'
                    />
                </>
            );
        } else {
            role = this.props.userRoleTitle;
        }

        fullName = this.props.displayName;

        if (this.props.user.is_bot) {
            description = this.props.user.bot_description ? (
                <Markdown message={this.props.user.bot_description} />
            ) : null;
        } else if (profile?.position || profile?.department) {
            description = profile?.position && profile?.department ? <span>{profile.position + ' '}&#x2219;&nbsp;{profile.department}</span> : `${profile.position ?? ''}${profile.department ?? ''}`;
        }

        const hasCustomStatus = this.props.customStatus?.emoji || this.props.customStatus?.text || isMyProfile;
        const showCustomStatus =
            this.props.customStatus?.text ||
            this.props.customStatus?.expires_at !== '0001-01-01T00:00:00Z' ||
            isMyProfile;
        if (hasCustomStatus && this.props.isInteractive) {
            if (showCustomStatus) {
                customStatus = (
                    <CustomStatus
                        userId={this.props.user.id}
                        editable={true}
                        onEdit={this.props.hide}
                    />
                );
            } else {
                fullName = (
                    <>
                        {fullName}
                        <CustomStatusEmoji
                            userID={this.props.user.id}
                            showTooltip={false}
                            emojiStyle={EMOJI_STYLE}
                        />
                    </>
                );
            }
        }
        fullName = (
            <>
                {fullName}
                {isBot && <BotBadge />}
                <GuestPlusBadge show={isGuestPlus(this.props.user.roles)} />
                <GuestBadge show={isGuest(this.props.user.roles)} />
            </>
        );

        if (this.props.user.remote_id) {
            sharedIcon = (
                <SharedUserIndicator
                    className='shared-user-icon'
                    withTooltip={true}
                />
            );
        }

        const details = (
            <DetailsArea
                appendId={this.props.appendId}
                user={{
                    username: this.props.user.username,
                    email: this.props.user.email,
                    timezone: this.props.user.timezone,
                    schedule: this.props.profile?.hide_schedule ? undefined : this.props.profile?.schedule,
                }}
                teamUrl={this.props.teamUrl}
                hideTime={isBot}
            />
        );

        const tooltip1 = (
            <Tooltip
                id='actions-menu-icon-tooltip'
                className='hidden-xs user-profile-popover__tooltip'
            >
                {isDeleted ? (
                    <FormattedMessage
                        id='user_profile.seeMessages'
                        defaultMessage='See Messages'
                    />
                ) : (
                    <FormattedMessage
                        id='user_profile.send.dm'
                        defaultMessage='Write Message'
                    />
                )}
            </Tooltip>
        );

        const tooltip2 = (
            <Tooltip
                id='actions-menu-icon-tooltip'
                className='hidden-xs user-profile-popover__tooltip'
            >
                <FormattedMessage
                    id='user_profile.add_user_to_channel'
                    defaultMessage='Add to a Channel'
                />
            </Tooltip>
        );

        if (this.props.isInteractive) {
            const isAddToChannelShown = !isMyProfile && !isDeleted && this.props.canAddUserToChannel && this.state.hasAddToChannelPossibility;

            buttons = (
                <div className='user-popover__actions'>
                    {this.props.canOpenDirectChannel && (
                        <OverlayTrigger
                            delayShow={Constants.OVERLAY_TIME_DELAY}
                            placement='top'
                            overlay={tooltip1}
                            rootClose={true}
                        >
                            <button
                                className={isDeleted ? '' : 'primary'}
                                onClick={this.handleShowDirectChannel}
                            >
                                {isDeleted ? (
                                    <>
                                        <MessagesIcon className='messages' />
                                        <FormattedMessage
                                            id='user_profile.messages'
                                            defaultMessage='Messages'
                                        />
                                    </>
                                ) : (
                                    <>
                                        <span className='icon'>
                                            <SendIcon
                                                size={12}
                                                color='currentColor'
                                            />
                                        </span>
                                        <FormattedMessage
                                            id='user_profile.write'
                                            defaultMessage='Write'
                                        />
                                    </>
                                )}
                            </button>
                        </OverlayTrigger>
                    )}
                    {isAddToChannelShown && (
                        <OverlayTrigger
                            delayShow={Constants.OVERLAY_TIME_DELAY}
                            placement='top'
                            overlay={tooltip2}
                            rootClose={true}
                        >
                            <button onClick={this.handleAddToChannel} data-testid='addToChannel'>
                                <UserAddIcon size={12} />
                            </button>
                        </OverlayTrigger>
                    )}
                    <Pluggable
                        key='profilePopoverPluggable3'
                        pluggableName='PopoverUserActions'
                        user={this.props.user}
                        hide={this.props.hide}
                        status={this.props.hideStatus ? null : this.props.status}
                    />
                </div>
            );
        }

        return {
            urlSrc,
            fullName,
            description,
            customStatus,
            sharedIcon,
            details,
            buttons,
            role,
            roleContrast,
        };
    }

    render() {
        if (!this.props.user) {
            return null;
        }

        const {urlSrc, fullName, description, customStatus, sharedIcon, details, buttons, role, roleContrast} =
            this.getOverrideDataForRender() || this.getDataForRender();

        return (
            <Popover
                id='user-profile-popover'
                popoverStyle={this.props.popoverStyle}
                popoverSize={this.props.popoverSize}
                title={this.props.title}
                placement={this.props.placement}
                style={this.props.style}
                onMouseOut={this.props.onMouseOut}
                onMouseOver={this.props.onMouseOver}
                className={classNames('user-profile-popover', this.props.className)}
            >
                {role ? (
                    <div className={classNames('user-profile-popover__user-role', {contrast: roleContrast})}>
                        {role}
                    </div>
                ) : null}
                <div
                    className={classNames('user-profile-popover__user-name', {
                        'user-profile-popover__user-name_clickable': Boolean(this.props.user) && this.props.isInteractive,
                    })}
                    onClick={this.showProfileInfo}
                >
                    <div className='status-wrapper'>
                        <Avatar
                            size={48}
                            username={this.props.displayName}
                            src={urlSrc}
                        />
                        <StatusIcon
                            className={classNames('user-popover-status', 'status-icon-48')}
                            status={this.props.hideStatus ? undefined : this.props.status}
                        />
                    </div>
                    <div className='user-profile-popover__name-container'>
                        <div className='user-profile-popover__name'>
                            {fullName}
                            {sharedIcon}
                        </div>
                        <div className='user-profile-popover__description'>{description}</div>
                    </div>
                </div>
                {customStatus ? <div className='user-profile-popover__custom-status'>{customStatus}</div> : null}

                <hr className='divider' />

                {details}

                <Pluggable
                    key='profilePopoverPluggable2'
                    pluggableName='PopoverUserAttributes'
                    user={this.props.user}
                    hide={this.props.hide}
                    status={this.props.hideStatus ? null : this.props.status}
                    fromWebhook={this.props.fromWebhook}
                />

                {buttons}
            </Popover>
        );
    }
}

export default ProfilePopover;
