import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import {hash} from 'object-code';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import type {Channel, ChannelModeration, ChannelModerationPatch} from '@mattermost/types/channels';

type Payload = {

    /**
     * Channel GUID
     */
    channelId: Channel['id'];
    moderations: ChannelModerationPatch[];
};

/**
 * Update a channel's moderation settings.
 *
 * Must have `manage_system` permission or to be a channel admin
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/PatchChannelModerations
 * @example "/channels/{channel_id}/moderations/patch"
 */
export const patchChannelModerations = createAsyncThunk(
    'sidebar/api/patchChannelModerations',
    async (payload: Payload, thunkAPI) => {
        const {channelId, moderations} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/channels/${channelId}/moderations/patch`;

        try {
            const {data} = await requester.put<ChannelModeration[]>(url, moderations, {
                ...options,
                id: `patchChannelModerations-${hash(moderations)}`,
                cache: false,
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));

            throw error;
        }
    },
);
