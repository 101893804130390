import {Avatar} from '@time-webkit/all/atoms/avatar';

import {getFullName} from 'utils/utils';
import {isGuest, isGuestPlus} from 'mattermost-redux/utils/user_utils';

import GuestPlusBadge from 'components/widgets/badges/guest_plus_badge';
import GuestBadge from 'components/widgets/badges/guest_badge';
import BotBadge from 'components/widgets/badges/bot_badge';

import type {User} from 'features/users';

import type {UserAutocomplete} from '@mattermost/types/autocomplete';

import {OutOfChannelBadge} from 'components/widgets/badges/out_of_channel_badge';

import {getUserProfilePictureUrl} from 'features/users/utils/get_user_profile_picture_url';

import Provider from './provider';
import Suggestion from './suggestion';

type ExtendedUser = User & {
    outOfChannel?: boolean;
};

class UserSuggestion extends Suggestion {
    render() {
        const {item, isSelection} = this.props as {item: ExtendedUser; isSelection: boolean};

        let className = 'suggestion-list__item';
        if (isSelection) {
            className += ' suggestion--selected';
        }

        const username = item.username;
        let description = '';

        if ((item.first_name || item.last_name) && item.nickname) {
            description = `- ${getFullName(item)} (${item.nickname})`;
        } else if (item.nickname) {
            description = `- (${item.nickname})`;
        } else if (item.first_name || item.last_name) {
            description = `- ${getFullName(item)}`;
        }

        return (
            <div
                className={className}
                onClick={this.handleClick}
                onMouseMove={this.handleMouseMove}
                {...Suggestion.baseProps}
            >
                <Avatar
                    size={16}
                    className='Avatar'
                    username={description}
                    src={getUserProfilePictureUrl(item.id, item.last_picture_update)}
                />
                <div className='suggestion-list__ellipsis'>
                    <span className='suggestion-list__main'>{'@' + username}</span>
                    <span> {description}</span>
                </div>
                <BotBadge show={Boolean(item.is_bot)} />
                <GuestPlusBadge show={isGuestPlus(item.roles)} />
                <GuestBadge show={isGuest(item.roles)} />
                {item.outOfChannel && <OutOfChannelBadge show={true} />}
            </div>
        );
    }
}

export default class UserProvider extends Provider {
    autocompleteUsers: (partialUsername: User['username']) => Promise<UserAutocomplete>;
    allowUsersOutOfChannel = false;
    markOutOfChannel = false;

    constructor(
        searchUsersFunc: (partialUsername: User['username']) => Promise<UserAutocomplete>,
        allowUsersOutOfChannel?: boolean,
        markOutOfChannel?: boolean,
    ) {
        super();
        this.autocompleteUsers = searchUsersFunc;
        this.allowUsersOutOfChannel = Boolean(allowUsersOutOfChannel);
        this.markOutOfChannel = Boolean(markOutOfChannel);
    }
    async handlePretextChanged(
        pretext: string,
        resultsCallback: (payload: {
            matchedPretext: string;
            terms: string[];
            items: ExtendedUser[];
            component: typeof UserSuggestion;
        }) => void,
    ) {
        const normalizedPretext = pretext.toLowerCase();
        this.startNewRequest(normalizedPretext);

        const data = await this.autocompleteUsers(normalizedPretext);

        if (this.shouldCancelDispatch(normalizedPretext)) {
            return false;
        }

        const users: ExtendedUser[] = data?.users?.length ? [...data.users] : [];

        if (
            normalizedPretext?.length > 0 &&
            this.allowUsersOutOfChannel &&
            data?.out_of_channel &&
            data?.out_of_channel.length
        ) {
            const userInChannelIds = users.map((user) => user.id);

            for (const userOutOfChannel of data.out_of_channel) {
                if (userInChannelIds.includes(userOutOfChannel.id)) {
                    continue;
                }

                users.push(this.markOutOfChannel ? {...userOutOfChannel, outOfChannel: true} : userOutOfChannel);
            }
        }

        resultsCallback({
            matchedPretext: normalizedPretext,
            terms: users.map((user) => user.username),
            items: users,
            component: UserSuggestion,
        });

        return true;
    }
}
