import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {Channel} from '@mattermost/types/channels';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import type {User} from 'features/users';
import Constants from 'mattermost-redux/constants/general';

type Payload = {
    page: number;
    perPage: number;
    channelId: Channel['id'];
};

export const getChannelMembers = createAsyncThunk(
    'channels/api/getChannelMembers',
    async (payload: Payload, thunkAPI) => {
        const {channelId, page, perPage} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users`;

        try {
            const {data} = await requester.get<User[]>(url, {
                ...options,
                signal: thunkAPI.signal,
                params: {
                    in_channel: channelId,
                    active: true,
                    channel_roles: Constants.CHANNEL_USER_ROLE,
                    page,
                    per_page: perPage,
                },
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));

            throw error;
        }
    },
);
