import {createSelector} from 'reselect';

import {getAllChannels, getDirectChannelsSet} from 'mattermost-redux/selectors/entities/channels';
import {getCurrentUserProfile} from 'mattermost-redux/selectors/entities/common';
import {getMySystemPermissions} from 'mattermost-redux/selectors/entities/roles_helpers';
import Permissions from 'mattermost-redux/constants/permissions';
import {type GlobalState} from 'types/store';
import {type UserProfile} from '@mattermost/types/users';
import {isDmChannel} from 'utils/isDmChannel';

/**
 * Проверяет наличие прав для создания личной беседы.
 * Если нет прав, то разрешается открыть только существующую беседу.
 */
export const makeCanOpenDirectChannelSelector = () => {
    return createSelector(
        getMySystemPermissions,
        getAllChannels,
        getDirectChannelsSet,
        getCurrentUserProfile,
        (_state: GlobalState, userId?: UserProfile['id']) => userId,
        (mySystemPermissions, allChannels, directChannelsSet, currentUser, userId) => {
            if (currentUser && currentUser.id === userId) {
                return true;
            }

            if (mySystemPermissions.has(Permissions.CREATE_DIRECT_CHANNEL)) {
                return true;
            }

            for (const channelId of directChannelsSet) {
                const channel = allChannels[channelId];

                if (userId && channel && isDmChannel(channel)) {
                    const dmChannelUserIds = channel.name.split('__');

                    if (dmChannelUserIds.includes(userId)) {
                        return true;
                    }
                }
            }

            return false;
        },
    );
};
