import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';
import type {Channel} from '@mattermost/types/channels';
import type {User} from 'features/users';

/**
 * Get an object containing a key per group channel id in the query and its value as a list of users members of that group channel.
 * The user must be a member of the group ids in the query, or they will be omitted from the response.
 *
 * Requires an active session but no other permissions.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/GetUsersByGroupChannelIds
 * @example "/api/v4/users/group_channels"
 */
export const getUsersByGroupChannelsIds = createAsyncThunk(
    'sidebar/api/getUsersByGroupChannelsIds',

    /**
     * @param payload List of group channel ids
     */
    async (payload: Array<Channel['id']>, thunkAPI) => {
        const channelIds = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;

        const requester = Client4.requester;

        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/group_channels`;

        try {
            const {data} = await requester.post<Record<Channel['id'], User[]>>(url, channelIds, options);

            return data;
        } catch (e: unknown) {
            const error = e as AxiosError;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));

            throw error;
        }
    },
);
