const MINUTE = 1000 * 60;
const DEFAULT_TTL = MINUTE * 60 * 6; // 6 часов
import {DateTime} from 'luxon';

import {BaseLocalStorageLRU} from 'stores/local_storage_store';

/**
 * От 0 до 2 часов
 */
function getRandomAddition() {
    return Math.floor(Math.random() * 2) * 1000 * 60 * 60;
}

export class CacheUtil {
    private cache = new BaseLocalStorageLRU();

    private constructCacheKey(tag: string, key: string) {
        return `${tag}-${key}`;
    }

    async get<Result>(
        tag: string,
        key: string,
        callbackOnNotFound: () => Promise<Result>,
        options?: {cacheTtl?: number},
    ): Promise<Result> {
        const cacheKey = this.constructCacheKey(tag, key);
        const {cacheTtl} = options || {};

        const cached = this.cache.get(cacheKey) as {data: Result; expires: number};
        const cacheEmpty = !cached;
        const cacheExpired = !cacheEmpty && DateTime.now().toMillis() > cached?.expires;
        const cacheHasNoData = !cacheEmpty && !('data' in cached);

        if (cacheEmpty || cacheExpired || cacheHasNoData) {
            const ttl = cacheTtl ?? getRandomAddition() + DEFAULT_TTL;

            const result = await callbackOnNotFound();

            try {
                this.cache.set(cacheKey, {
                    expires: DateTime.now().plus({milliseconds: ttl}).toMillis(),
                    data: result,
                });
                // eslint-disable-next-line no-empty
            } catch {}

            return result;
        }

        return cached.data;
    }

    clear(tag: string, key: string) {
        const cacheKey = this.constructCacheKey(tag, key);
        try {
            this.cache.delete(cacheKey);
            // eslint-disable-next-line no-empty
        } catch {}
    }
}
