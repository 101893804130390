import {createSelector} from 'reselect';

import {shallowEqual} from 'react-redux';

import {type ChannelMembership, type Channel} from 'mattermost-redux/types/channels';
import {type TeamMembership} from 'mattermost-redux/types/teams';
import {type GlobalState} from 'mattermost-redux/types/store';
import {type UserProfile, type UserWithPrivileges} from 'mattermost-redux/types/users';
import {type RelationOneToOne, type IDMappedObjects} from 'mattermost-redux/types/utilities';
import {isGuest, isGuestPlus, isSystemAdmin} from 'mattermost-redux/utils/user_utils';

// Channels

export function getCurrentChannelId(state: GlobalState): string {
    return state.entities.channels.currentChannelId;
}

export function getMyChannelMemberships(state: GlobalState): RelationOneToOne<Channel, ChannelMembership> {
    return state.entities.channels.myMembers;
}

export const getMyCurrentChannelMembership: (a: GlobalState) => ChannelMembership | undefined = createSelector(
    getCurrentChannelId,
    getMyChannelMemberships,
    (currentChannelId, channelMemberships) => {
        return channelMemberships[currentChannelId];
    },
);

export function getMembersInChannel(state: GlobalState, channelId: string): Record<string, ChannelMembership> {
    return state.entities.channels?.membersInChannel?.[channelId] || {};
}

// Teams

export function getMembersInTeam(state: GlobalState, teamId: string): RelationOneToOne<UserProfile, TeamMembership> {
    return state.entities.teams?.membersInTeam?.[teamId] || {};
}

// Users

export const getCurrentUserProfile = createSelector(
    getUsers,
    getCurrentUserId,
    (profiles, currentUserId) => {
        return profiles[currentUserId];
    },
    {memoizeOptions: {
        resultEqualityCheck: shallowEqual,
    }},
);

export const getCurrentUser = createSelector(getCurrentUserProfile, (profile): UserWithPrivileges | undefined => {
    if (!profile) {
        return undefined;
    }
    return {
        ...profile,
        isSystemAdmin: isSystemAdmin(profile.roles),
        isGuestPlus: isGuestPlus(profile.roles),
        isGuest: isGuest(profile.roles),
    };
});

export function getCurrentUserId(state: GlobalState): string {
    return state.entities.users.currentUserId;
}

export function getUsers(state: GlobalState): IDMappedObjects<UserProfile> {
    return state.entities.users.profiles;
}

export const getUserById: (a: GlobalState, userId: UserProfile['id']) => UserProfile | undefined = createSelector(
    getUsers,
    (_: GlobalState, userId: UserProfile['id']) => userId,
    (users, userId) => users[userId],
);
