import {connect} from 'react-redux';

import {getConfig} from 'mattermost-redux/selectors/entities/general';

import {type GlobalState} from 'types/store';

import ExternalImage from './external_image';

function mapStateToProps(state: GlobalState) {
    const config = getConfig(state);

    return {
        hasImageProxy: config.HasImageProxy === 'true',
        additionalSiteURLs: config.AdditionalSiteURLs,
    };
}

export default connect(mapStateToProps)(ExternalImage);
