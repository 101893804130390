/* eslint-disable max-lines */
import FormData from 'form-data';

import {omit} from 'lodash';

import {TimeApiClient} from 'packages/request';
import type {TimeApiClientError, TimeApiClientOptions} from 'packages/request';

import type {SystemSetting} from 'mattermost-redux/types/general';
import type {AnalyticsRow, ClusterInfo, SchemaMigration} from 'mattermost-redux/types/admin';
import type {AppBinding, AppCallRequest, AppCallResponse} from 'mattermost-redux/types/apps';
import type {Audit} from 'mattermost-redux/types/audits';
import type {AutocompleteSuggestion, UserAutocomplete} from 'mattermost-redux/types/autocomplete';
import type {Bot, BotPatch} from 'mattermost-redux/types/bots';
import type {
    Address,
    CloudCustomer,
    CloudCustomerPatch,
    Invoice,
    Product,
    Subscription,
} from 'mattermost-redux/types/cloud';
import type {ChannelCategory, OrderedChannelCategories} from 'mattermost-redux/types/channel_categories';
import type {
    Channel,
    ChannelGroupsMembersCount,
    ChannelMembership,
    ChannelModeration,
    ChannelModerationPatch,
    ChannelSearchOpts,
    ChannelStats,
    ChannelsWithTotalCount,
    ChannelUnread,
    ChannelViewResponse,
    ChannelWithTeamData,
    ServerChannel,
} from 'mattermost-redux/types/channels';
import type {Options, StatusOK} from 'mattermost-redux/types/client4';
import {LogLevel} from 'mattermost-redux/types/client4';
import type {Compliance} from 'mattermost-redux/types/compliance';
import type {
    AdminConfig,
    ClientConfig,
    ClientLicense,
    DataRetentionPolicy,
    EnvironmentConfig,
    License,
} from 'mattermost-redux/types/config';
import type {CustomEmoji} from 'mattermost-redux/types/emojis';
import type {ServerError} from 'mattermost-redux/types/errors';
import type {FileInfo, FileSearchResults, FileUploadResponse} from 'mattermost-redux/types/files';
import type {
    CustomGroupPatch,
    Group,
    GroupCreateWithUserIds,
    GroupPatch,
    GroupSearachParams,
    GroupsWithCount,
    GroupSyncable,
    MixedUnlinkedGroup,
    SyncablePatch,
    UsersWithGroupsAndCount,
} from 'mattermost-redux/types/groups';
import type {PostActionResponse} from 'mattermost-redux/types/integration_actions';
import type {
    Command,
    CommandArgs,
    CommandResponse,
    DialogSubmission,
    IncomingWebhook,
    OAuthApp,
    OutgoingWebhook,
    SubmitDialogResponse,
} from 'mattermost-redux/types/integrations';
import type {Job} from 'mattermost-redux/types/jobs';
import type {MfaSecret} from 'mattermost-redux/types/mfa';
import type {ClientPluginManifest, PluginManifest, PluginsResponse, PluginStatus} from 'mattermost-redux/types/plugins';
import type {MarketplaceApp, MarketplacePlugin} from 'mattermost-redux/types/marketplace';
import type {OpenGraphMetadata, Post} from 'mattermost-redux/types/posts';
import type {PreferenceType} from 'mattermost-redux/types/preferences';
import type {Reaction} from 'mattermost-redux/types/reactions';
import type {Role} from 'mattermost-redux/types/roles';
import type {SamlCertificateStatus, SamlMetadataResponse} from 'mattermost-redux/types/saml';
import type {Scheme} from 'mattermost-redux/types/schemes';
import type {Session} from 'mattermost-redux/types/sessions';
import type {
    GetTeamMembersOpts,
    Team,
    TeamInviteWithError,
    TeamMembership,
    TeamMemberWithError,
    TeamSearchOpts,
    TeamStats,
    TeamsWithCount,
    TeamUnread,
} from 'mattermost-redux/types/teams';
import type {TermsOfService} from 'mattermost-redux/types/terms_of_service';
import {ProfilePictureTypes} from 'mattermost-redux/types/users';
import type {
    AuthChangeResponse,
    GetFilteredUsersStatsOpts,
    UserAccessToken,
    UserCustomStatus,
    UserProfile,
    UsersStats,
    UserStatus,
} from 'mattermost-redux/types/users';
import {type RelationOneToOne} from 'mattermost-redux/types/utilities';
import {type ProductNotices} from 'mattermost-redux/types/product_notices';
import type {
    CreateDataRetentionCustomPolicy,
    DataRetentionCustomPolicies,
    GetDataRetentionCustomPoliciesRequest,
    PatchDataRetentionCustomPolicy,
} from 'mattermost-redux/types/data_retention';
import type {CompleteOnboardingRequest} from 'mattermost-redux/types/setup';

import {buildQueryString} from 'mattermost-redux/utils/helpers_client';
import {cleanUrlForLogging} from 'mattermost-redux/utils/sentry';
import {isSystemAdmin} from 'mattermost-redux/utils/user_utils';

import {General} from '../constants';

import {CacheUtil} from '../../../cache';

import type {PostList} from 'features/posts/types/post_list';

import {JobTypes} from 'utils/constants';

import {type DeepPartial} from '../../../../types/utils';

import {type TelemetryHandler} from './telemetry';

const HEADER_AUTH = 'Authorization';
const HEADER_BEARER = 'BEARER';
const HEADER_CONTENT_TYPE = 'Content-Type';
const HEADER_REQUESTED_WITH = 'X-Requested-With';
const HEADER_USER_AGENT = 'User-Agent';
export const HEADER_X_CLUSTER_ID = 'X-Cluster-Id';
const HEADER_X_CSRF_TOKEN = 'X-CSRF-Token';
export const HEADER_X_VERSION_ID = 'X-Version-Id';
const PER_PAGE_DEFAULT = 60;
const LOGS_PER_PAGE_DEFAULT = 10000;
export const DEFAULT_LIMIT_BEFORE = 30;
export const DEFAULT_LIMIT_AFTER = 30;
const GRAPHQL_ENDPOINT = '/api/v5/graphql';

export default class Client4 {
    logToConsole = false;
    serverVersion = '';
    clusterId = '';
    token = '';
    csrf = '';
    url = '';
    urlVersion = '/api/v4';
    userAgent: string | null = null;
    enableLogging = false;
    defaultHeaders: {[x: string]: string} = {};
    userId = '';
    diagnosticId = '';
    includeCookies = true;
    setAuthHeader = true;
    translations = {
        connectionError: 'There appears to be a problem with your internet connection.',
        unknownError: 'We received an unexpected status code from the server.',
    };
    userRoles?: string;
    telemetryHandler?: TelemetryHandler;

    getUrl() {
        return this.url;
    }

    requester = TimeApiClient;

    getAbsoluteUrl(baseUrl: string) {
        if (typeof baseUrl !== 'string' || !baseUrl.startsWith('/')) {
            return baseUrl;
        }
        return this.getUrl() + baseUrl;
    }

    getGraphQLUrl() {
        return `${this.url}${GRAPHQL_ENDPOINT}`;
    }

    setUrl(url: string) {
        this.url = url;
    }

    setUserAgent(userAgent: string) {
        this.userAgent = userAgent;
    }

    getToken() {
        return this.token;
    }

    setToken(token: string) {
        this.token = token;
    }

    setCSRF(csrfToken: string) {
        this.csrf = csrfToken;
    }

    setAcceptLanguage(locale: string) {
        this.defaultHeaders['Accept-Language'] = locale;
    }

    setEnableLogging(enable: boolean) {
        this.enableLogging = enable;
    }

    setIncludeCookies(include: boolean) {
        this.includeCookies = include;
    }

    setUserId(userId: string) {
        this.userId = userId;
    }

    setUserRoles(roles: string) {
        this.userRoles = roles;
    }

    setDiagnosticId(diagnosticId: string) {
        this.diagnosticId = diagnosticId;
    }

    setTelemetryHandler(telemetryHandler?: TelemetryHandler) {
        this.telemetryHandler = telemetryHandler;
    }

    getServerVersion() {
        return this.serverVersion;
    }

    getUrlVersion() {
        return this.urlVersion;
    }

    getBaseRoute() {
        return `${this.url}${this.urlVersion}`;
    }

    // This function belongs to the Apps Framework feature.
    // Apps Framework feature is experimental, and this function is susceptible
    // to breaking changes without pushing the major version of this package.
    getAppsProxyRoute() {
        return `${this.url}/plugins/com.mattermost.apps`;
    }

    getUsersRoute() {
        return `${this.getBaseRoute()}/users`;
    }

    getUserRoute(userId: string) {
        return `${this.getUsersRoute()}/${userId}`;
    }

    getTeamsRoute() {
        return `${this.getBaseRoute()}/teams`;
    }

    getTeamRoute(teamId: string) {
        return `${this.getTeamsRoute()}/${teamId}`;
    }

    getTeamSchemeRoute(teamId: string) {
        return `${this.getTeamRoute(teamId)}/scheme`;
    }

    getTeamNameRoute(teamName: string) {
        return `${this.getTeamsRoute()}/name/${teamName}`;
    }

    getTeamMembersRoute(teamId: string) {
        return `${this.getTeamRoute(teamId)}/members`;
    }

    getTeamMemberRoute(teamId: string, userId: string) {
        return `${this.getTeamMembersRoute(teamId)}/${userId}`;
    }

    getChannelsRoute() {
        return `${this.getBaseRoute()}/channels`;
    }

    getChannelRoute(channelId: string) {
        return `${this.getChannelsRoute()}/${channelId}`;
    }

    getChannelMembersRoute(channelId: string) {
        return `${this.getChannelRoute(channelId)}/members`;
    }

    getChannelMemberRoute(channelId: string, userId: string) {
        return `${this.getChannelMembersRoute(channelId)}/${userId}`;
    }

    getChannelSchemeRoute(channelId: string) {
        return `${this.getChannelRoute(channelId)}/scheme`;
    }

    getChannelCategoriesRoute(userId: string, teamId: string) {
        return `${this.getBaseRoute()}/users/${userId}/teams/${teamId}/channels/categories`;
    }

    getPostsRoute() {
        return `${this.getBaseRoute()}/posts`;
    }

    getPostRoute(postId: string) {
        return `${this.getPostsRoute()}/${postId}`;
    }

    getReactionsRoute() {
        return `${this.getBaseRoute()}/reactions`;
    }

    getCommandsRoute() {
        return `${this.getBaseRoute()}/commands`;
    }

    getFilesRoute() {
        return `${this.getBaseRoute()}/files`;
    }

    getFileRoute(fileId: string) {
        return `${this.getFilesRoute()}/${fileId}`;
    }

    getPreferencesRoute(userId: string) {
        return `${this.getUserRoute(userId)}/preferences`;
    }

    getIncomingHooksRoute() {
        return `${this.getBaseRoute()}/hooks/incoming`;
    }

    getIncomingHookRoute(hookId: string) {
        return `${this.getBaseRoute()}/hooks/incoming/${hookId}`;
    }

    getOutgoingHooksRoute() {
        return `${this.getBaseRoute()}/hooks/outgoing`;
    }

    getOutgoingHookRoute(hookId: string) {
        return `${this.getBaseRoute()}/hooks/outgoing/${hookId}`;
    }

    getOAuthRoute() {
        return `${this.url}/oauth`;
    }

    getOAuthAppsRoute() {
        return `${this.getBaseRoute()}/oauth/apps`;
    }

    getOAuthAppRoute(appId: string) {
        return `${this.getOAuthAppsRoute()}/${appId}`;
    }

    getEmojisRoute() {
        return `${this.getBaseRoute()}/emoji`;
    }

    getEmojiRoute(emojiId: string) {
        return `${this.getEmojisRoute()}/${emojiId}`;
    }

    getBrandRoute() {
        return `${this.getBaseRoute()}/brand`;
    }

    getBrandImageUrl(timestamp: string) {
        return `${this.getBrandRoute()}/image?t=${timestamp}`;
    }

    getDataRetentionRoute() {
        return `${this.getBaseRoute()}/data_retention`;
    }

    getJobsRoute() {
        return `${this.getBaseRoute()}/jobs`;
    }

    getPluginsRoute() {
        return `${this.getBaseRoute()}/plugins`;
    }

    getPluginRoute(pluginId: string) {
        return `${this.getPluginsRoute()}/${pluginId}`;
    }

    getPluginsMarketplaceRoute() {
        return `${this.getPluginsRoute()}/marketplace`;
    }

    getRolesRoute() {
        return `${this.getBaseRoute()}/roles`;
    }

    getSchemesRoute() {
        return `${this.getBaseRoute()}/schemes`;
    }

    getRedirectLocationRoute() {
        return `${this.getBaseRoute()}/redirect_location`;
    }

    getBotsRoute() {
        return `${this.getBaseRoute()}/bots`;
    }

    getBotRoute(botUserId: string) {
        return `${this.getBotsRoute()}/${botUserId}`;
    }

    getGroupsRoute() {
        return `${this.getBaseRoute()}/groups`;
    }

    getGroupRoute(groupID: string) {
        return `${this.getGroupsRoute()}/${groupID}`;
    }

    getNoticesRoute() {
        return `${this.getBaseRoute()}/system/notices`;
    }

    getCloudRoute() {
        return `${this.getBaseRoute()}/cloud`;
    }

    getPermissionsRoute() {
        return `${this.getBaseRoute()}/permissions`;
    }

    getSystemRoute(): string {
        return `${this.getBaseRoute()}/system`;
    }

    getCSRFFromCookie() {
        if (typeof document !== 'undefined' && typeof document.cookie !== 'undefined') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i]?.trim() || '';
                if (cookie.startsWith('MMCSRF=')) {
                    return cookie.replace('MMCSRF=', '');
                }
            }
        }
        return '';
    }

    getOptions(options: Options) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const requestOptions = options;

        const newOptions: Options = {...requestOptions};

        const headers: {[x: string]: string} = {
            [HEADER_REQUESTED_WITH]: 'XMLHttpRequest',
            ...this.defaultHeaders,
        };

        if (this.setAuthHeader && this.token) {
            headers[HEADER_AUTH] = `${HEADER_BEARER} ${this.token}`;
        }

        const csrfToken = this.csrf || this.getCSRFFromCookie();
        if (options.method && options.method.toLowerCase() !== 'get' && csrfToken) {
            headers[HEADER_X_CSRF_TOKEN] = csrfToken;
        }

        if (this.includeCookies) {
            newOptions.credentials = 'include';
        }

        if (this.userAgent) {
            headers[HEADER_USER_AGENT] = this.userAgent;
        }

        if (options.body) {
            // when the body is an instance of FormData we let fetch to set the Content-Type header so it defines a correct boundary
            if (!(options.body instanceof FormData)) {
                headers[HEADER_CONTENT_TYPE] = 'application/json';
            }
        }

        if (newOptions.headers) {
            Object.assign(headers, newOptions.headers);
        }

        return {
            ...newOptions,
            headers,
        };
    }

    getTimeApiClientOptions(options: Options, noApiVFourInBaseUrl = false): TimeApiClientOptions {
        const timeApiClientOptions: TimeApiClientOptions = {
            headers: options.headers || {},
            method: options.method?.toUpperCase(),
            url: options.url,
            withCredentials: options.credentials && options.credentials !== 'omit',
            baseURL: noApiVFourInBaseUrl ? this.getBaseRoute().replace(/\/api\/v4/gm, '') : this.getBaseRoute(),
            'axios-retry': {
                retries: options.retries || 0,
            },
            signal: options.signal,
        };

        const headers: TimeApiClientOptions['headers'] = {
            ...this.defaultHeaders,
        };

        if (this.setAuthHeader && this.token) {
            headers[HEADER_AUTH] = `${HEADER_BEARER} ${this.token}`;
        }

        const csrfToken = this.csrf || this.getCSRFFromCookie();
        if (options.method && options.method.toLowerCase() !== 'get' && csrfToken) {
            headers[HEADER_X_CSRF_TOKEN] = csrfToken;
        }

        if (this.includeCookies) {
            timeApiClientOptions.withCredentials = true;
        }

        if (this.userAgent) {
            headers[HEADER_USER_AGENT] = this.userAgent;
        }

        if (options.body) {
            timeApiClientOptions.data = options.body;
        }

        if (timeApiClientOptions.headers) {
            Object.assign(headers, timeApiClientOptions.headers);
        }

        return {
            ...timeApiClientOptions,
            headers,
        };
    }

    // User Routes

    createUser = (user: UserProfile, token?: string, inviteId?: string, redirect?: string) => {
        this.trackEvent('api', 'api_users_create');

        const queryParams: any = {};

        if (token) {
            queryParams.t = token;
        }

        if (inviteId) {
            queryParams.iid = inviteId;
        }

        if (redirect) {
            queryParams.r = redirect;
        }

        return this.doFetch<UserProfile>(`${this.getUsersRoute()}${buildQueryString(queryParams)}`, {
            method: 'post',
            body: user,
        });
    };

    patchMe = (userPatch: Partial<UserProfile>) => {
        return this.doFetch<UserProfile>(`${this.getUserRoute('me')}/patch`, {method: 'put', body: userPatch});
    };

    patchUser = (userPatch: Partial<UserProfile> & {id: string}) => {
        this.trackEvent('api', 'api_users_patch');

        return this.doFetch<UserProfile>(`${this.getUserRoute(userPatch.id)}/patch`, {method: 'put', body: userPatch});
    };

    updateUser = (user: UserProfile) => {
        this.trackEvent('api', 'api_users_update');

        return this.doFetch<UserProfile>(`${this.getUserRoute(user.id)}`, {method: 'put', body: user});
    };

    promoteGuestToUser = (userId: string) => {
        this.trackEvent('api', 'api_users_promote_guest_to_user');

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/promote`, {method: 'post'});
    };

    demoteUserToGuest = (userId: string) => {
        this.trackEvent('api', 'api_users_demote_user_to_guest');

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/demote`, {method: 'post'});
    };

    updateUserRoles = (userId: string, roles: string) => {
        this.trackEvent('api', 'api_users_update_roles');

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/roles`, {method: 'put', body: {roles}});
    };

    updateUserMfa = (userId: string, activate: boolean, code: string) => {
        const body: any = {
            activate,
        };

        if (activate) {
            body.code = code;
        }

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/mfa`, {method: 'put', body});
    };

    updateUserPassword = (userId: string, currentPassword: string, newPassword: string) => {
        this.trackEvent('api', 'api_users_newpassword');

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/password`, {
            method: 'put',
            body: {current_password: currentPassword, new_password: newPassword},
        });
    };

    resetUserPassword = (token: string, newPassword: string) => {
        this.trackEvent('api', 'api_users_reset_password');

        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/password/reset`, {
            method: 'post',
            body: {token, new_password: newPassword},
        });
    };

    getKnownUsers = () => {
        return this.doFetch<Array<UserProfile['id']>>(`${this.getUsersRoute()}/known`, {method: 'get'});
    };

    sendPasswordResetEmail = (email: string) => {
        this.trackEvent('api', 'api_users_send_password_reset');

        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/password/reset/send`, {method: 'post', body: {email}});
    };

    updateUserActive = (userId: string, active: boolean) => {
        this.trackEvent('api', 'api_users_update_active');

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/active`, {method: 'put', body: {active}});
    };

    uploadProfileImage = (userId: string, imageData: File) => {
        this.trackEvent('api', 'api_users_update_profile_picture');

        return this.postFormData<StatusOK>(`${this.getUserRoute(userId)}/image`, {
            image: imageData,
        });
    };

    setDefaultProfileImage = (userId: string) => {
        this.trackEvent('api', 'api_users_set_default_profile_picture');

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/image`, {method: 'delete'});
    };

    verifyUserEmail = (token: string) => {
        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/email/verify`, {method: 'post', body: {token}});
    };

    updateMyTermsOfServiceStatus = (termsOfServiceId: string, accepted: boolean) => {
        return this.doFetch<StatusOK>(`${this.getUserRoute('me')}/terms_of_service`, {
            method: 'post',
            body: {termsOfServiceId, accepted},
        });
    };

    getTermsOfService = () => {
        return this.doFetch<TermsOfService>(`${this.getBaseRoute()}/terms_of_service`, {method: 'get'});
    };

    createTermsOfService = (text: string) => {
        return this.doFetch<TermsOfService>(`${this.getBaseRoute()}/terms_of_service`, {method: 'post', body: {text}});
    };

    sendVerificationEmail = (email: string) => {
        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/email/verify/send`, {method: 'post', body: {email}});
    };

    login = async (loginId: string, password: string, token = '', deviceId = '', ldapOnly = false) => {
        this.trackEvent('api', 'api_users_login');

        if (ldapOnly) {
            this.trackEvent('api', 'api_users_login_ldap');
        }

        const body: any = {
            device_id: deviceId,
            login_id: loginId,
            password,
            token,
        };

        if (ldapOnly) {
            body.ldap_only = 'true';
        }

        const {data: profile, headers} = await this.doFetchWithResponse<UserProfile>(`${this.getUsersRoute()}/login`, {
            method: 'post',
            body,
        });

        if (headers.has('Token')) {
            this.setToken(headers.get('Token')!);
        }

        return profile;
    };

    loginById = (id: string, password: string, token = '', deviceId = '') => {
        this.trackEvent('api', 'api_users_login');
        const body: any = {
            device_id: deviceId,
            id,
            password,
            token,
        };

        return this.doFetch<UserProfile>(`${this.getUsersRoute()}/login`, {method: 'post', body});
    };

    logout = async () => {
        this.trackEvent('api', 'api_users_logout');

        const {response} = await this.fetchWithResponse(`${this.getUsersRoute()}/logout`, {method: 'post'});

        if (response.ok) {
            this.setToken('');
        }

        this.serverVersion = '';

        return response;
    };

    getProfiles = (page = 0, perPage = PER_PAGE_DEFAULT, options = {}) => {
        return this.doFetch<UserProfile[]>(
            `${this.getUsersRoute()}${buildQueryString({page, per_page: perPage, ...options})}`,
            {method: 'get'},
        );
    };

    getProfilesByIds = (userIds: string[], options = {}, signal?: AbortSignal) => {
        return this.doFetch<UserProfile[]>(`${this.getUsersRoute()}/ids${buildQueryString(options)}`, {
            method: 'post',
            body: userIds,
            signal,
        });
    };

    getProfilesByUsernames = (usernames: string[]) => {
        return this.doFetch<UserProfile[]>(`${this.getUsersRoute()}/usernames`, {method: 'post', body: usernames});
    };

    getProfilesInTeam = (teamId: string, page = 0, perPage = PER_PAGE_DEFAULT, sort = '', options = {}) => {
        return this.doFetch<UserProfile[]>(
            `${this.getUsersRoute()}${buildQueryString({...options, in_team: teamId, page, per_page: perPage, sort})}`,
            {method: 'get'},
        );
    };

    getProfilesNotInTeam = (teamId: string, groupConstrained: boolean, page = 0, perPage = PER_PAGE_DEFAULT) => {
        const queryStringObj: any = {not_in_team: teamId, page, per_page: perPage};
        if (groupConstrained) {
            queryStringObj.group_constrained = true;
        }

        return this.doFetch<UserProfile[]>(`${this.getUsersRoute()}${buildQueryString(queryStringObj)}`, {
            method: 'get',
        });
    };

    getProfilesWithoutTeam = (page = 0, perPage = PER_PAGE_DEFAULT, options = {}) => {
        return this.doFetch<UserProfile[]>(
            `${this.getUsersRoute()}${buildQueryString({...options, without_team: 1, page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getProfilesInChannel = (
        channelId: string,
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        sort = '',
        options: {active?: boolean} = {},
    ) => {
        const queryStringObj = {in_channel: channelId, page, per_page: perPage, sort};

        return this.doFetch<UserProfile[]>(
            `${this.getUsersRoute()}${buildQueryString({...queryStringObj, ...options})}`,
            {method: 'get'},
        );
    };

    getProfilesInGroupChannels = (channelsIds: string[], fetchOptions: Partial<Options> = {}) => {
        return this.doFetch<Record<string, UserProfile[]>>(`${this.getUsersRoute()}/group_channels`, {
            ...fetchOptions,
            method: 'post',
            body: channelsIds,
        });
    };

    getProfilesNotInChannel = (
        teamId: string,
        channelId: string,
        groupConstrained: boolean,
        page = 0,
        perPage = PER_PAGE_DEFAULT,
    ) => {
        const queryStringObj: any = {in_team: teamId, not_in_channel: channelId, page, per_page: perPage};
        if (groupConstrained) {
            queryStringObj.group_constrained = true;
        }

        return this.doFetch<UserProfile[]>(`${this.getUsersRoute()}${buildQueryString(queryStringObj)}`, {
            method: 'get',
        });
    };

    getProfilesInGroup = (groupId: string, page = 0, perPage = PER_PAGE_DEFAULT, signal?: AbortSignal) => {
        return this.doFetch<UserProfile[]>(
            `${this.getUsersRoute()}${buildQueryString({in_group: groupId, page, per_page: perPage})}`,
            {method: 'get', signal},
        );
    };

    getProfilesNotInGroup = (groupId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<UserProfile[]>(
            `${this.getUsersRoute()}${buildQueryString({not_in_group: groupId, page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getMe = () => {
        return this.doFetch<UserProfile>(`${this.getUserRoute('me')}`, {method: 'get'});
    };

    getUser = (userId: string) => {
        return this.doFetch<UserProfile>(`${this.getUserRoute(userId)}`, {method: 'get'});
    };

    getUserByUsername = (username: string) => {
        return this.doFetch<UserProfile>(`${this.getUsersRoute()}/username/${username}`, {method: 'get'});
    };

    getUserByEmail = (email: string) => {
        return this.doFetch<UserProfile>(`${this.getUsersRoute()}/email/${email}`, {method: 'get'});
    };

    getUsersByEmails = (emails: string[]) => {
        return this.doFetch<UserProfile[] | null>(`${this.getUsersRoute()}/emails`, {method: 'post', body: emails});
    };

    getProfilePictureUrl = (userId: string, lastPictureUpdate?: number, type?: ProfilePictureTypes) => {
        const params: Record<string, number> = {};

        if (lastPictureUpdate) {
            params._ = lastPictureUpdate;
        }

        let imageUrlSuffixBySize = '';
        if (type === ProfilePictureTypes.LARGE) {
            imageUrlSuffixBySize = '/large';
        }

        if (type === ProfilePictureTypes.DEFAULT) {
            imageUrlSuffixBySize = '/default';
        }

        return `${this.getUserRoute(userId)}/image${imageUrlSuffixBySize}${buildQueryString(params)}`;
    };

    getLargeProfilePictureUrl = (userId: string, lastPictureUpdate?: number) => {
        return this.getProfilePictureUrl(userId, lastPictureUpdate, ProfilePictureTypes.LARGE);
    }

    getDefaultProfilePictureUrl = (userId: string) => {
        return this.getProfilePictureUrl(userId, undefined, ProfilePictureTypes.DEFAULT);
    };

    autocompleteUsers = (
        name: string,
        teamId: string,
        channelId: string,
        options?: {
            limit?: number;
            boost?: boolean;
        },
    ) => {
        const queryParams: {name: string; in_team: string; in_channel: string; limit?: number; boost?: boolean} = {
            in_team: teamId,
            in_channel: channelId,
            name,
            limit: options?.limit || General.AUTOCOMPLETE_LIMIT_DEFAULT,
        };

        if (options?.boost) {
            queryParams.boost = options.boost;
        }

        return this.doFetch<UserAutocomplete>(
            `${this.getUsersRoute()}/autocomplete${buildQueryString(queryParams)}`,
            {
                method: 'get',
            },
        );
    };

    getSessions = (userId: string) => {
        return this.doFetch<Session[]>(`${this.getUserRoute(userId)}/sessions`, {method: 'get'});
    };

    revokeSession = (userId: string, sessionId: string) => {
        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/sessions/revoke`, {
            method: 'post',
            body: {session_id: sessionId},
        });
    };

    revokeAllSessionsForUser = (userId: string) => {
        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/sessions/revoke/all`, {method: 'post'});
    };

    revokeSessionsForAllUsers = () => {
        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/sessions/revoke/all`, {method: 'post'});
    };

    getUserAudits = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Audit[]>(
            `${this.getUserRoute(userId)}/audits${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    checkUserMfa = (loginId: string) => {
        return this.doFetch<{mfa_required: boolean}>(`${this.getUsersRoute()}/mfa`, {
            method: 'post',
            body: {login_id: loginId},
        });
    };

    generateMfaSecret = (userId: string) => {
        return this.doFetch<MfaSecret>(`${this.getUserRoute(userId)}/mfa/generate`, {method: 'post'});
    };

    attachDevice = (deviceId: string) => {
        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/sessions/device`, {
            method: 'put',
            body: {device_id: deviceId},
        });
    };

    searchUsers = (term: string, options: any, fetchOptions: Partial<Options> = {}) => {
        this.trackEvent('api', 'api_search_users');

        return this.doFetch<UserProfile[]>(`${this.getUsersRoute()}/search`, {
            ...fetchOptions,
            method: 'post',
            body: {term, ...options},
        });
    };

    getStatusesByIds = (userIds: string[], signal?: AbortSignal) => {
        return this.doFetch<UserStatus[]>(`${this.getUsersRoute()}/status/ids`, {method: 'post', body: userIds, signal});
    };

    getStatus = (userId: string) => {
        return this.doFetch<UserStatus>(`${this.getUserRoute(userId)}/status`, {method: 'get'});
    };

    updateStatus = (status: UserStatus) => {
        return this.doFetch<UserStatus>(`${this.getUserRoute(status.user_id)}/status`, {method: 'put', body: status});
    };

    updateCustomStatus = (customStatus: UserCustomStatus) => {
        return this.doFetch(`${this.getUserRoute('me')}/status/custom`, {method: 'put', body: customStatus});
    };

    unsetCustomStatus = () => {
        return this.doFetch(`${this.getUserRoute('me')}/status/custom`, {method: 'delete'});
    };

    removeRecentCustomStatus = (customStatus: UserCustomStatus) => {
        return this.doFetch(`${this.getUserRoute('me')}/status/custom/recent/delete`, {
            method: 'post',
            body: customStatus,
        });
    };

    switchEmailToOAuth = (service: string, email: string, password: string, mfaCode = '') => {
        this.trackEvent('api', 'api_users_email_to_oauth');

        return this.doFetch<AuthChangeResponse>(`${this.getUsersRoute()}/login/switch`, {
            method: 'post',
            body: {current_service: 'email', new_service: service, email, password, mfa_code: mfaCode},
        });
    };

    switchOAuthToEmail = (currentService: string, email: string, password: string) => {
        this.trackEvent('api', 'api_users_oauth_to_email');

        return this.doFetch<AuthChangeResponse>(`${this.getUsersRoute()}/login/switch`, {
            method: 'post',
            body: {current_service: currentService, new_service: 'email', email, new_password: password},
        });
    };

    switchEmailToLdap = (email: string, emailPassword: string, ldapId: string, ldapPassword: string, mfaCode = '') => {
        this.trackEvent('api', 'api_users_email_to_ldap');

        return this.doFetch<AuthChangeResponse>(`${this.getUsersRoute()}/login/switch`, {
            method: 'post',
            body: {
                current_service: 'email',
                new_service: 'ldap',
                email,
                password: emailPassword,
                ldap_id: ldapId,
                new_password: ldapPassword,
                mfa_code: mfaCode,
            },
        });
    };

    switchLdapToEmail = (ldapPassword: string, email: string, emailPassword: string, mfaCode = '') => {
        this.trackEvent('api', 'api_users_ldap_to_email');

        return this.doFetch<AuthChangeResponse>(`${this.getUsersRoute()}/login/switch`, {
            method: 'post',
            body: {
                current_service: 'ldap',
                new_service: 'email',
                email,
                password: ldapPassword,
                new_password: emailPassword,
                mfa_code: mfaCode,
            },
        });
    };

    getAuthorizedOAuthApps = (userId: string) => {
        return this.doFetch<OAuthApp[]>(`${this.getUserRoute(userId)}/oauth/apps/authorized`, {method: 'get'});
    };

    authorizeOAuthApp = (responseType: string, clientId: string, redirectUri: string, state: string, scope: string) => {
        return this.doFetch<void>(`${this.url}/oauth/authorize`, {
            method: 'post',
            body: {client_id: clientId, response_type: responseType, redirect_uri: redirectUri, state, scope},
        });
    };

    deauthorizeOAuthApp = (clientId: string) => {
        return this.doFetch<StatusOK>(`${this.url}/oauth/deauthorize`, {method: 'post', body: {client_id: clientId}});
    };

    createUserAccessToken = (userId: string, description: string) => {
        this.trackEvent('api', 'api_users_create_access_token');

        return this.doFetch<UserAccessToken>(`${this.getUserRoute(userId)}/tokens`, {
            method: 'post',
            body: {description},
        });
    };

    getUserAccessToken = (tokenId: string) => {
        return this.doFetch<UserAccessToken>(`${this.getUsersRoute()}/tokens/${tokenId}`, {method: 'get'});
    };

    getUserAddChannelPossibility = (userId: string, signal?: AbortSignal) => {
        return this.doFetch<{status: boolean}>(`${this.getUserRoute(userId)}/add_channel/possibility`, {
            method: 'get',
            signal,
        });
    }

    getUserAccessTokensForUser = (userId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<UserAccessToken[]>(
            `${this.getUserRoute(userId)}/tokens${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getUserAccessTokens = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<UserAccessToken[]>(
            `${this.getUsersRoute()}/tokens${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    revokeUserAccessToken = (tokenId: string) => {
        this.trackEvent('api', 'api_users_revoke_access_token');

        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/tokens/revoke`, {
            method: 'post',
            body: {token_id: tokenId},
        });
    };

    disableUserAccessToken = (tokenId: string) => {
        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/tokens/disable`, {
            method: 'post',
            body: {token_id: tokenId},
        });
    };

    enableUserAccessToken = (tokenId: string) => {
        return this.doFetch<StatusOK>(`${this.getUsersRoute()}/tokens/enable`, {
            method: 'post',
            body: {token_id: tokenId},
        });
    };

    // Team Routes

    createTeam = (team: Team) => {
        this.trackEvent('api', 'api_teams_create');

        return this.doFetch<Team>(`${this.getTeamsRoute()}`, {method: 'post', body: team});
    };

    deleteTeam = (teamId: string) => {
        this.trackEvent('api', 'api_teams_delete');

        return this.doFetch<StatusOK>(`${this.getTeamRoute(teamId)}`, {method: 'delete'});
    };

    unarchiveTeam = (teamId: string) => {
        return this.doFetch<Team>(`${this.getTeamRoute(teamId)}/restore`, {method: 'post'});
    };

    updateTeam = (team: Team) => {
        this.trackEvent('api', 'api_teams_update_name', {team_id: team.id});

        return this.doFetch<Team>(`${this.getTeamRoute(team.id)}`, {method: 'put', body: team});
    };

    patchTeam = (team: Partial<Team> & {id: string}) => {
        this.trackEvent('api', 'api_teams_patch_name', {team_id: team.id});

        return this.doFetch<Team>(`${this.getTeamRoute(team.id)}/patch`, {method: 'put', body: team});
    };

    regenerateTeamInviteId = (teamId: string) => {
        this.trackEvent('api', 'api_teams_regenerate_invite_id', {team_id: teamId});

        return this.doFetch<Team>(`${this.getTeamRoute(teamId)}/regenerate_invite_id`, {method: 'post'});
    };

    updateTeamScheme = (teamId: string, schemeId: string) => {
        const patch = {scheme_id: schemeId};

        this.trackEvent('api', 'api_teams_update_scheme', {team_id: teamId, ...patch});

        return this.doFetch<StatusOK>(`${this.getTeamSchemeRoute(teamId)}`, {method: 'put', body: patch});
    };

    checkIfTeamExists = (teamName: string) => {
        return this.doFetch<{exists: boolean}>(`${this.getTeamNameRoute(teamName)}/exists`, {method: 'get'});
    };

    getTeams = (
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        options?: {
            includeTotalCount?: boolean;
            excludePolicyConstrained?: boolean;
            canJoin?: boolean;
            fetchOptions?: Options;
        },
    ) => {
        return this.doFetch<Team[] | TeamsWithCount>(
            `${this.getTeamsRoute()}${buildQueryString({
                page,
                per_page: perPage,
                include_total_count: options?.includeTotalCount || false,
                exclude_policy_constrained: options?.excludePolicyConstrained || false,
                can_join: options?.canJoin || false,
            })}`,
            {method: 'get', ...options?.fetchOptions},
        );
    };

    searchTeams = (term: string, opts: TeamSearchOpts) => {
        this.trackEvent('api', 'api_search_teams');

        return this.doFetch<Team[] | TeamsWithCount>(`${this.getTeamsRoute()}/search`, {
            method: 'post',
            body: {term, ...opts},
        });
    };

    getTeam = (teamId: string) => {
        return this.doFetch<Team>(this.getTeamRoute(teamId), {method: 'get'});
    };

    getTeamByName = (teamName: string) => {
        this.trackEvent('api', 'api_teams_get_team_by_name');

        return this.doFetch<Team>(this.getTeamNameRoute(teamName), {method: 'get'});
    };

    getMyTeams = () => {
        return this.doFetch<Team[]>(`${this.getUserRoute('me')}/teams`, {method: 'get'});
    };

    getTeamsForUser = (userId: string) => {
        return this.doFetch<Team[]>(`${this.getUserRoute(userId)}/teams`, {method: 'get'});
    };

    getMyTeamMembers = () => {
        return this.doFetch<TeamMembership[]>(`${this.getUserRoute('me')}/teams/members`, {method: 'get'});
    };

    getMyTeamUnreads = (includeCollapsedThreads = false) => {
        return this.doFetch<TeamUnread[]>(
            `${this.getUserRoute('me')}/teams/unread${buildQueryString({
                include_collapsed_threads: includeCollapsedThreads,
            })}`,
            {method: 'get'},
        );
    };

    getTeamMembers = (teamId: string, page = 0, perPage = PER_PAGE_DEFAULT, options: GetTeamMembersOpts) => {
        return this.doFetch<TeamMembership>(
            `${this.getTeamMembersRoute(teamId)}${buildQueryString({page, per_page: perPage, ...options})}`,
            {method: 'get'},
        );
    };

    getTeamMembersForUser = (userId: string) => {
        return this.doFetch<TeamMembership[]>(`${this.getUserRoute(userId)}/teams/members`, {method: 'get'});
    };

    getTeamMember = (teamId: string, userId: string) => {
        return this.doFetch<TeamMembership>(`${this.getTeamMemberRoute(teamId, userId)}`, {method: 'get'});
    };

    getTeamMembersByIds = (teamId: string, userIds: string[]) => {
        return this.doFetch<TeamMembership[]>(`${this.getTeamMembersRoute(teamId)}/ids`, {
            method: 'post',
            body: userIds,
        });
    };

    getTeamPrimaryChannel = (teamId: string) => {
        return this.doFetch(`${this.getTeamRoute(teamId)}/channels/primary`, {
            method: 'get',
        });
    };

    addToTeam = (teamId: string, userId: string) => {
        this.trackEvent('api', 'api_teams_invite_members', {team_id: teamId});

        const member = {user_id: userId, team_id: teamId};
        return this.doFetch<TeamMembership>(`${this.getTeamMembersRoute(teamId)}`, {method: 'post', body: member});
    };

    addToTeamFromInvite = (token = '', inviteId = '') => {
        this.trackEvent('api', 'api_teams_invite_members');

        const query = buildQueryString({token, invite_id: inviteId});
        return this.doFetch<TeamMembership>(`${this.getTeamsRoute()}/members/invite${query}`, {method: 'post'});
    };

    addUsersToTeam = (teamId: string, userIds: string[]) => {
        this.trackEvent('api', 'api_teams_batch_add_members', {team_id: teamId, count: userIds.length});

        const members: any = [];
        userIds.forEach((id) => members.push({team_id: teamId, user_id: id}));
        return this.doFetch<TeamMembership[]>(`${this.getTeamMembersRoute(teamId)}/batch`, {
            method: 'post',
            body: members,
        });
    };

    addUsersToTeamGracefully = (teamId: string, userIds: string[]) => {
        this.trackEvent('api', 'api_teams_batch_add_members', {team_id: teamId, count: userIds.length});

        const members: any = [];
        userIds.forEach((id) => members.push({team_id: teamId, user_id: id}));
        return this.doFetch<TeamMemberWithError[]>(`${this.getTeamMembersRoute(teamId)}/batch?graceful=true`, {
            method: 'post',
            body: members,
        });
    };

    removeFromTeam = (teamId: string, userId: string) => {
        this.trackEvent('api', 'api_teams_remove_members', {team_id: teamId});

        return this.doFetch<StatusOK>(`${this.getTeamMemberRoute(teamId, userId)}`, {method: 'delete'});
    };

    getTeamStats = (teamId: string) => {
        return this.doFetch<TeamStats>(`${this.getTeamRoute(teamId)}/stats`, {method: 'get'});
    };

    getTotalUsersStats = () => {
        return this.doFetch<UsersStats>(`${this.getUsersRoute()}/stats`, {method: 'get'});
    };

    getFilteredUsersStats = (options: GetFilteredUsersStatsOpts) => {
        return this.doFetch<UsersStats>(`${this.getUsersRoute()}/stats/filtered${buildQueryString(options)}`, {
            method: 'get',
        });
    };

    invalidateAllEmailInvites = () => {
        return this.doFetch<StatusOK>(`${this.getTeamsRoute()}/invites/email`, {method: 'delete'});
    };

    getTeamInviteInfo = (inviteId: string) => {
        return this.doFetch<{
            display_name: string;
            description: string;
            name: string;
            id: string;
        }>(`${this.getTeamsRoute()}/invite/${inviteId}`, {method: 'get'});
    };

    updateTeamMemberRoles = (teamId: string, userId: string, roles: string[]) => {
        this.trackEvent('api', 'api_teams_update_member_roles', {team_id: teamId});

        return this.doFetch<StatusOK>(`${this.getTeamMemberRoute(teamId, userId)}/roles`, {
            method: 'put',
            body: {roles},
        });
    };

    sendEmailInvitesToTeam = (teamId: string, emails: string[]) => {
        this.trackEvent('api', 'api_teams_invite_members', {team_id: teamId});

        return this.doFetch<StatusOK>(`${this.getTeamRoute(teamId)}/invite/email`, {method: 'post', body: emails});
    };

    sendEmailGuestInvitesToChannels = (teamId: string, channelIds: string[], emails: string[], message: string) => {
        this.trackEvent('api', 'api_teams_invite_guests', {team_id: teamId, channel_ids: channelIds});

        return this.doFetch<StatusOK>(`${this.getTeamRoute(teamId)}/invite-guests/email`, {
            method: 'post',
            body: {emails, channels: channelIds, message},
        });
    };

    sendEmailInvitesToTeamGracefully = (teamId: string, emails: string[]) => {
        this.trackEvent('api', 'api_teams_invite_members', {team_id: teamId});

        return this.doFetch<TeamInviteWithError>(`${this.getTeamRoute(teamId)}/invite/email?graceful=true`, {
            method: 'post',
            body: emails,
        });
    };

    sendEmailInvitesToTeamAndChannelsGracefully = (
        teamId: string,
        emails: string[],
        dryRun = false,
        signal?: AbortSignal,
    ) => {
        this.trackEvent('api', 'api_teams_invite_members_to_channels', {
            team_id: teamId,
        });

        return this.doFetch<TeamInviteWithError[]>(`${this.getTeamRoute(teamId)}/invite/email?graceful=true&dry_run=${dryRun}`, {
            method: 'post',
            body: {emails},
            signal,
        });
    };

    sendEmailGuestInvitesToChannelsGracefully = async (
        teamId: string,
        channelIds: string[],
        emails: string[],
        message: string,
        dryRun = false,
        signal?: AbortSignal,
    ) => {
        this.trackEvent('api', 'api_teams_invite_guests', {team_id: teamId, channel_ids: channelIds});

        return this.doFetch<TeamInviteWithError[]>(`${this.getTeamRoute(teamId)}/invite-guests/email?graceful=true&dry_run=${dryRun}`, {
            method: 'post',
            body: {emails, channels: channelIds, message, dry_run: dryRun},
            signal,
        });
    };

    getTeamIconUrl = (teamId: string, lastTeamIconUpdate: number) => {
        const params: any = {};
        if (lastTeamIconUpdate) {
            params._ = lastTeamIconUpdate;
        }

        return `${this.getTeamRoute(teamId)}/image${buildQueryString(params)}`;
    };

    setTeamIcon = (teamId: string, imageData: File) => {
        this.trackEvent('api', 'api_team_set_team_icon');

        return this.postFormData<StatusOK>(`${this.getTeamRoute(teamId)}/image`, {
            image: imageData,
        });
    };

    removeTeamIcon = (teamId: string) => {
        this.trackEvent('api', 'api_team_remove_team_icon');

        return this.doFetch<StatusOK>(`${this.getTeamRoute(teamId)}/image`, {method: 'delete'});
    };

    updateTeamMemberSchemeRoles = (teamId: string, userId: string, isSchemeUser: boolean, isSchemeAdmin: boolean) => {
        const body = {scheme_user: isSchemeUser, scheme_admin: isSchemeAdmin};
        return this.doFetch<StatusOK>(`${this.getTeamRoute(teamId)}/members/${userId}/schemeRoles`, {
            method: 'put',
            body,
        });
    };

    // Channel Routes

    getAllChannels = (
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        notAssociatedToGroup = '',
        excludeDefaultChannels = false,
        includeTotalCount = false,
        includeDeleted = false,
        excludePolicyConstrained = false,
    ) => {
        const queryData = {
            page,
            per_page: perPage,
            not_associated_to_group: notAssociatedToGroup,
            exclude_default_channels: excludeDefaultChannels,
            include_total_count: includeTotalCount,
            include_deleted: includeDeleted,
            exclude_policy_constrained: excludePolicyConstrained,
        };
        return this.doFetch<ChannelWithTeamData[] | ChannelsWithTotalCount>(
            `${this.getChannelsRoute()}${buildQueryString(queryData)}`,
            {method: 'get'},
        );
    };

    createChannel = (channel: Channel) => {
        this.trackEvent('api', 'api_channels_create', {team_id: channel.team_id});

        return this.doFetch<ServerChannel>(`${this.getChannelsRoute()}`, {method: 'post', body: channel});
    };

    createDirectChannel = (userIds: string[]) => {
        this.trackEvent('api', 'api_channels_create_direct');

        return this.doFetch<ServerChannel>(`${this.getChannelsRoute()}/direct`, {method: 'post', body: userIds});
    };

    createGroupChannel = (userIds: string[]) => {
        this.trackEvent('api', 'api_channels_create_group');

        return this.doFetch<ServerChannel>(`${this.getChannelsRoute()}/group`, {method: 'post', body: userIds});
    };

    deleteChannel = (channelId: string) => {
        this.trackEvent('api', 'api_channels_delete', {channel_id: channelId});

        return this.doFetch<StatusOK>(`${this.getChannelRoute(channelId)}`, {method: 'delete'});
    };

    unarchiveChannel = (channelId: string) => {
        this.trackEvent('api', 'api_channels_unarchive', {channel_id: channelId});

        return this.doFetch<ServerChannel>(`${this.getChannelRoute(channelId)}/restore`, {method: 'post'});
    };

    updateChannel = (channel: Channel) => {
        this.trackEvent('api', 'api_channels_update', {channel_id: channel.id});

        return this.doFetch<ServerChannel>(`${this.getChannelRoute(channel.id)}`, {method: 'put', body: channel});
    };

    transformGroupToChannel = (channelId: string, teamId: string, channelName: string, displayName: string) => {
        this.trackEvent('api', 'api_channels_transform_group_to_channel', {channel_id: channelId});

        return this.doFetch<ServerChannel>(`${this.getChannelRoute(channelId)}/transform`, {
            method: 'post',
            body: {team_id: teamId, name: channelName, display_name: displayName},
        });
    };

    updateChannelPrivacy = (channelId: string, privacy: any) => {
        this.trackEvent('api', 'api_channels_update_privacy', {channel_id: channelId, privacy});

        return this.doFetch<ServerChannel>(`${this.getChannelRoute(channelId)}/privacy`, {
            method: 'put',
            body: {privacy},
        });
    };

    patchChannel = (channelId: string, channelPatch: Partial<Channel>) => {
        this.trackEvent('api', 'api_channels_patch', {channel_id: channelId});

        return this.doFetch<ServerChannel>(`${this.getChannelRoute(channelId)}/patch`, {
            method: 'put',
            body: channelPatch,
        });
    };

    updateChannelNotifyProps = (props: any) => {
        this.trackEvent('api', 'api_users_update_channel_notifications', {channel_id: props.channel_id});

        return this.doFetch<StatusOK>(`${this.getChannelMemberRoute(props.channel_id, props.user_id)}/notify_props`, {
            method: 'put',
            body: props,
        });
    };

    updateChannelScheme = (channelId: string, schemeId: string) => {
        const patch = {scheme_id: schemeId};

        this.trackEvent('api', 'api_channels_update_scheme', {channel_id: channelId, ...patch});

        return this.doFetch<StatusOK>(`${this.getChannelSchemeRoute(channelId)}`, {method: 'put', body: patch});
    };

    getChannel = (channelId: string) => {
        return this.doFetch<ServerChannel>(`${this.getChannelRoute(channelId)}`, {method: 'get'});
    };

    getChannelByName = (teamId: string, channelName: string, includeDeleted = false) => {
        return this.doFetch<ServerChannel>(
            `${this.getTeamRoute(teamId)}/channels/name/${channelName}?include_deleted=${includeDeleted}`,
            {method: 'get'},
        );
    };

    getChannelByNameAndTeamName = (teamName: string, channelName: string, includeDeleted = false) => {
        return this.doFetch<ServerChannel>(
            `${this.getTeamNameRoute(teamName)}/channels/name/${channelName}?include_deleted=${includeDeleted}`,
            {method: 'get'},
        );
    };

    getChannels = (teamId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<ServerChannel[]>(
            `${this.getTeamRoute(teamId)}/channels${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getAllTeamsChannels = () => {
        return this.doFetch<ServerChannel[]>(`${this.getUsersRoute()}/me/channels`, {method: 'get'});
    };

    getArchivedChannels = (teamId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<ServerChannel[]>(
            `${this.getTeamRoute(teamId)}/channels/deleted${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getMyChannels = (teamId: string, includeDeleted = false) => {
        // eslint-disable-next-line no-console
        console.log(
            `[Time:API]: getMyChannels request triggered with teamId "${teamId}" and includeDeleted "${includeDeleted.toString()}"`,
        );

        return this.doFetch<ServerChannel[]>(
            `${this.getUserRoute('me')}/teams/${teamId}/channels${buildQueryString({include_deleted: includeDeleted})}`,
            {
                method: 'get',
            },
        );
    };

    getAllChannelsMembers = (userId: string) => {
        return this.doFetch<ChannelMembership[]>(`${this.getUserRoute(userId)}/channel_members`, {method: 'get'});
    };

    getMyChannelMember = (channelId: string) => {
        return this.doFetch<ChannelMembership>(`${this.getChannelMemberRoute(channelId, 'me')}`, {method: 'get'});
    };

    getMyChannelMembers = (teamId: string) => {
        return this.doFetch<ChannelMembership[]>(`${this.getUserRoute('me')}/teams/${teamId}/channels/members`, {
            method: 'get',
        });
    };

    getChannelMembers = (channelId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<ChannelMembership[]>(
            `${this.getChannelMembersRoute(channelId)}${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getChannelTimezones = (channelId: string) => {
        return this.doFetch<string[]>(`${this.getChannelRoute(channelId)}/timezones`, {method: 'get'});
    };

    getChannelMember = (channelId: string, userId: string) => {
        return this.doFetch<ChannelMembership>(`${this.getChannelMemberRoute(channelId, userId)}`, {method: 'get'});
    };

    getChannelMembersByIds = (channelId: string, userIds: string[]) => {
        return this.doFetch<ChannelMembership[]>(`${this.getChannelMembersRoute(channelId)}/ids`, {
            method: 'post',
            body: userIds,
        });
    };

    addToChannel = (userId: string, channelId: string, postRootId = '') => {
        this.trackEvent('api', 'api_channels_add_member', {channel_id: channelId});

        const member = {user_id: userId, channel_id: channelId, post_root_id: postRootId};
        return this.doFetch<ChannelMembership>(`${this.getChannelMembersRoute(channelId)}`, {
            method: 'post',
            body: member,
        });
    };

    addToChannelMany = (userIds: string[], channelId: string) => {
        return this.doFetch<Array<{
            user_id: string;
            member: ChannelMembership;
            error?: {id: string};
        }>>(`${this.getChannelMembersRoute(channelId)}/batch`, {
            method: 'post',
            body: userIds,
        });
    }

    removeFromChannel = (userId: string, channelId: string) => {
        this.trackEvent('api', 'api_channels_remove_member', {channel_id: channelId});

        return this.doFetch<StatusOK>(`${this.getChannelMemberRoute(channelId, userId)}`, {method: 'delete'});
    };

    updateChannelMemberRoles = (channelId: string, userId: string, roles: string) => {
        return this.doFetch<StatusOK>(`${this.getChannelMemberRoute(channelId, userId)}/roles`, {
            method: 'put',
            body: {roles},
        });
    };

    getChannelStats = (channelId: string) => {
        return this.doFetch<ChannelStats>(`${this.getChannelRoute(channelId)}/stats`, {method: 'get'});
    };

    getChannelModerations = (channelId: string) => {
        return this.doFetch<ChannelModeration[]>(`${this.getChannelRoute(channelId)}/moderations`, {method: 'get'});
    };

    patchChannelModerations = (channelId: string, channelModerationsPatch: ChannelModerationPatch[]) => {
        return this.doFetch<ChannelModeration[]>(`${this.getChannelRoute(channelId)}/moderations/patch`, {
            method: 'put',
            body: channelModerationsPatch,
        });
    };

    getChannelMemberCountsByGroupIDs = (channelId: string, groupIds: string[], includeTimezones: boolean) => {
        return this.doFetch<ChannelGroupsMembersCount[]>(
            `${this.getChannelRoute(channelId)}/groups/members/counts`,
            {
                method: 'post',
                body: {
                    group_ids: groupIds,
                    include_timezones: includeTimezones,
                },
            },
        );
    };

    viewMyChannel = (channelId: string, prevChannelId?: string) => {
        const data = {channel_id: channelId, prev_channel_id: prevChannelId, collapsed_threads_supported: true};
        return this.doFetch<ChannelViewResponse>(`${this.getChannelsRoute()}/members/me/view`, {
            method: 'post',
            body: data,
        });
    };

    autocompleteChannels = (teamId: string, name: string, opts?: {archived?: boolean; boost?: boolean}) => {
        const queryParams: {name: string; archived?: boolean; boost?: boolean} = {name};

        if (opts?.archived !== undefined) {
            queryParams.archived = opts.archived;
        }

        if (opts?.boost !== undefined) {
            queryParams.boost = opts.boost;
        }

        return this.doFetch<Channel[]>(
            `${this.getTeamRoute(teamId)}/channels/autocomplete${buildQueryString(queryParams)}`,
            {method: 'get'},
        );
    };

    autocompleteChannelsForSearch = (teamId: string, name: string) => {
        return this.doFetch<Channel[]>(
            `${this.getTeamRoute(teamId)}/channels/search_autocomplete${buildQueryString({name})}`,
            {method: 'get'},
        );
    };

    searchChannels = (teamId: string, term: string) => {
        return this.doFetch<Channel[]>(`${this.getTeamRoute(teamId)}/channels/search`, {method: 'post', body: {term}});
    };

    searchArchivedChannels = (teamId: string, term: string) => {
        return this.doFetch<Channel[]>(`${this.getTeamRoute(teamId)}/channels/search_archived`, {
            method: 'post',
            body: {term},
        });
    };

    searchAllChannels = (term: string, opts: ChannelSearchOpts = {}) => {
        const body = {
            term,
            ...opts,
        };
        const includeDeleted = Boolean(opts.include_deleted);
        const nonAdminSearch = Boolean(opts.nonAdminSearch);
        let queryParams: {
            include_deleted?: boolean;
            system_console?: boolean;
            page?: number;
            per_page?: number;
        } = {include_deleted: includeDeleted};
        if (nonAdminSearch) {
            queryParams = {system_console: false};
            delete body.nonAdminSearch;
        }
        if (opts.per_page !== undefined) {
            queryParams.per_page = opts.per_page;
        }
        if (opts.page !== undefined) {
            queryParams.page = opts.page;
        }
        return this.doFetch<Channel[] | ChannelsWithTotalCount>(
            `${this.getChannelsRoute()}/search${buildQueryString(queryParams)}`,
            {method: 'post', body},
        );
    };

    searchGroupChannels = (term: string, fetchOptions: Partial<Options> = {}) => {
        return this.doFetch<Channel[]>(`${this.getChannelsRoute()}/group/search`, {...fetchOptions, method: 'post', body: {term}});
    };

    updateChannelMemberSchemeRoles = (
        channelId: string,
        userId: string,
        isSchemeUser: boolean,
        isSchemeAdmin: boolean,
    ) => {
        const body = {scheme_user: isSchemeUser, scheme_admin: isSchemeAdmin};
        return this.doFetch<StatusOK>(`${this.getChannelRoute(channelId)}/members/${userId}/schemeRoles`, {
            method: 'put',
            body,
        });
    };

    // Channel Category Routes

    getChannelCategories = (userId: string, teamId: string) => {
        return this.doFetch<OrderedChannelCategories>(`${this.getChannelCategoriesRoute(userId, teamId)}`, {
            method: 'get',
        });
    };

    createChannelCategory = (userId: string, teamId: string, category: Partial<ChannelCategory>) => {
        return this.doFetch<ChannelCategory>(`${this.getChannelCategoriesRoute(userId, teamId)}`, {
            method: 'post',
            body: category,
        });
    };

    updateChannelCategories = (userId: string, teamId: string, categories: ChannelCategory[]) => {
        return this.doFetch<ChannelCategory[]>(`${this.getChannelCategoriesRoute(userId, teamId)}`, {
            method: 'put',
            body: categories,
        });
    };

    getChannelCategoryOrder = (userId: string, teamId: string) => {
        return this.doFetch<string[]>(`${this.getChannelCategoriesRoute(userId, teamId)}/order`, {method: 'get'});
    };

    updateChannelCategoryOrder = (userId: string, teamId: string, categoryOrder: string[]) => {
        return this.doFetch<string[]>(`${this.getChannelCategoriesRoute(userId, teamId)}/order`, {
            method: 'put',
            body: categoryOrder,
        });
    };

    getChannelCategory = (userId: string, teamId: string, categoryId: string) => {
        return this.doFetch<ChannelCategory>(`${this.getChannelCategoriesRoute(userId, teamId)}/${categoryId}`, {
            method: 'get',
        });
    };

    updateChannelCategory = (userId: string, teamId: string, category: ChannelCategory) => {
        return this.doFetch<ChannelCategory>(`${this.getChannelCategoriesRoute(userId, teamId)}/${category.id}`, {
            method: 'put',
            body: category,
        });
    };

    deleteChannelCategory = (userId: string, teamId: string, categoryId: string) => {
        return this.doFetch<ChannelCategory>(`${this.getChannelCategoriesRoute(userId, teamId)}/${categoryId}`, {
            method: 'delete',
        });
    };

    // Post Routes

    createPost = async (post: Post) => {
        const result = await this.doFetch<Post>(`${this.getPostsRoute()}`, {method: 'post', body: post});
        const analyticsData = {
            channel_id: result.channel_id,
            post_id: result.id,
            user_actual_id: result.user_id,
            root_id: result.root_id,
        };
        this.trackEvent('api', 'api_posts_create', analyticsData);

        if (result.root_id != null && result.root_id !== '') {
            this.trackEvent('api', 'api_posts_replied', analyticsData);
        }
        return result;
    };

    updatePost = (post: Post) => {
        this.trackEvent('api', 'api_posts_update', {channel_id: post.channel_id, post_id: post.id});

        return this.doFetch<Post>(`${this.getPostRoute(post.id)}`, {method: 'put', body: post});
    };

    getPost = (postId: string) => {
        return this.doFetch<Post>(`${this.getPostRoute(postId)}`, {method: 'get'});
    };

    patchPost = async (postPatch: Partial<Post> & {id: string}, currentPost: Post) => {
        this.trackEvent('api', 'api_posts_patch', {channel_id: postPatch.channel_id, post_id: postPatch.id});

        const idsToDelete = currentPost?.file_ids?.filter((id) => !postPatch.file_ids?.includes(id)) || [];

        for await (const idToDelete of idsToDelete) {
            try {
                const result = await this.deleteFile(idToDelete);
                if (result.data.status !== 'OK') {
                    return new Error('failed to delete file');
                }
                this.trackEvent('api', 'api_files_delete', {
                    channel_id: postPatch.channel_id,
                    post_id: postPatch.id,
                    file_id: idToDelete,
                });
            } catch (error: unknown) {
                if (error instanceof ClientError && error.status_code === 404) {
                    continue;
                }
                return error;
            }
        }

        return this.doFetch<Post>(`${this.getPostRoute(postPatch.id)}/patch`, {method: 'put', body: postPatch});
    };

    deletePost = (postId: string) => {
        this.trackEvent('api', 'api_posts_delete');

        return this.doFetch<StatusOK>(`${this.getPostRoute(postId)}`, {method: 'delete'});
    };

    /**
     * @TODO: Перенести в features/threads/(api|actions)
     */
    getPostThread = (
        postId: string,
        fetchThreads = true,
        collapsedThreads = false,
        collapsedThreadsExtended = false,
        signal?: AbortSignal,
    ) => {
        return this.doFetch<PostList>(
            `${this.getPostRoute(postId)}/thread${buildQueryString({
                skipFetchThreads: !fetchThreads,
                collapsedThreads,
                collapsedThreadsExtended,
            })}`,
            {
                method: 'get',
                headers: {

                    /**
                     * Навешиваем этот заголовок, чтобы всегда получать актуальное состояние
                     * постов в треде, так как есть репорты, что у треда может не показываться
                     * актуальное состояние ответов.
                     * Иначе в заголовок отправляется браузером последний etag, который
                     * может возвращать неактуальное состояние
                     */
                    'if-none-match': 'always-get-updated-thread-posts',
                },
                signal,
            },
        );
    };

    getPosts = (
        channelId: string,
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        fetchThreads = true,
        collapsedThreads = false,
        collapsedThreadsExtended = false,
    ) => {
        return this.doFetch<PostList>(
            `${this.getChannelRoute(channelId)}/posts${buildQueryString({
                page,
                per_page: perPage,
                skipFetchThreads: !fetchThreads,
                collapsedThreads,
                collapsedThreadsExtended,
            })}`,
            {method: 'get'},
        );
    };

    getPostsSince = (
        channelId: string,
        since: number,
        fetchThreads = true,
        collapsedThreads = false,
        collapsedThreadsExtended = false,
    ) => {
        return this.doFetch<PostList>(
            `${this.getChannelRoute(channelId)}/posts${buildQueryString({
                since,
                skipFetchThreads: !fetchThreads,
                collapsedThreads,
                collapsedThreadsExtended,
            })}`,
            {method: 'get'},
        );
    };

    getPostsBefore = (
        channelId: string,
        postId: string,
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        fetchThreads = true,
        collapsedThreads = false,
        collapsedThreadsExtended = false,
    ) => {
        return this.doFetch<PostList>(
            `${this.getChannelRoute(channelId)}/posts${buildQueryString({
                before: postId,
                page,
                per_page: perPage,
                skipFetchThreads: !fetchThreads,
                collapsedThreads,
                collapsedThreadsExtended,
            })}`,
            {method: 'get'},
        );
    };

    getPostsAfter = (
        channelId: string,
        postId: string,
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        fetchThreads = true,
        collapsedThreads = false,
        collapsedThreadsExtended = false,
    ) => {
        return this.doFetch<PostList>(
            `${this.getChannelRoute(channelId)}/posts${buildQueryString({
                after: postId,
                page,
                per_page: perPage,
                skipFetchThreads: !fetchThreads,
                collapsedThreads,
                collapsedThreadsExtended,
            })}`,
            {method: 'get'},
        );
    };

    getFileInfosForPost = (postId: string) => {
        return this.doFetch<FileInfo[]>(`${this.getPostRoute(postId)}/files/info`, {method: 'get'});
    };

    getFlaggedPosts = (userId: string, {channelId = '', teamId = '', page = 0, perPage = PER_PAGE_DEFAULT}, fetchOptions: Options) => {
        this.trackEvent('api', 'api_posts_get_flagged', {team_id: teamId});

        return this.doFetch<PostList>(
            `${this.getUserRoute(userId)}/posts/flagged${buildQueryString({
                channel_id: channelId,
                team_id: teamId,
                page,
                per_page: perPage,
            })}`,
            {method: 'get', ...fetchOptions},
        );
    };

    getPinnedPosts = (channelId: string) => {
        this.trackEvent('api', 'api_posts_get_pinned', {channel_id: channelId});
        return this.doFetch<PostList>(`${this.getChannelRoute(channelId)}/pinned`, {method: 'get'});
    };

    markPostAsUnread = (userId: string, postId: string) => {
        this.trackEvent('api', 'api_post_set_unread_post');

        return this.doFetch<ChannelUnread>(`${this.getUserRoute(userId)}/posts/${postId}/set_unread`, {
            method: 'post',
            body: {collapsed_threads_supported: true},
        });
    };

    pinPost = (postId: string) => {
        this.trackEvent('api', 'api_posts_pin');

        return this.doFetch<StatusOK>(`${this.getPostRoute(postId)}/pin`, {method: 'post'});
    };

    unpinPost = (postId: string) => {
        this.trackEvent('api', 'api_posts_unpin');

        return this.doFetch<StatusOK>(`${this.getPostRoute(postId)}/unpin`, {method: 'post'});
    };

    getPostsByIds = (postIds: string[]) => {
        return this.doFetch<Post[]>(`${this.getPostsRoute()}/ids`, {method: 'post', body: postIds});
    };

    addReaction = (userId: string, postId: string, emojiName: string) => {
        this.trackEvent('api', 'api_reactions_save', {post_id: postId});

        return this.doFetch<Reaction>(`${this.getReactionsRoute()}`, {
            method: 'post',
            body: {user_id: userId, post_id: postId, emoji_name: emojiName},
        });
    };

    removeReaction = (userId: string, postId: string, emojiName: string) => {
        this.trackEvent('api', 'api_reactions_delete', {post_id: postId});

        return this.doFetch<StatusOK>(`${this.getUserRoute(userId)}/posts/${postId}/reactions/${emojiName}`, {
            method: 'delete',
        });
    };

    getReactionsForPost = (postId: string) => {
        return this.doFetch<Reaction[]>(`${this.getPostRoute(postId)}/reactions`, {method: 'get'});
    };

    searchPostsWithParams = (teamId: string, params: any) => {
        this.trackEvent('api', 'api_posts_search', {team_id: teamId});

        let route = `${this.getPostsRoute()}/search`;
        if (teamId) {
            route = `${this.getTeamRoute(teamId)}/posts/search`;
        }

        return this.doFetch<PostList & {matches: Record<Post['id'], string[]>}>(route, {method: 'post', body: params});
    };

    searchPosts = (teamId: string, terms: string, isOrSearch: boolean) => {
        return this.searchPostsWithParams(teamId, {terms, is_or_search: isOrSearch});
    };

    searchFilesWithParams = (teamId: string, params: any) => {
        this.trackEvent('api', 'api_files_search', {team_id: teamId});

        return this.doFetch<FileSearchResults>(`${this.getTeamRoute(teamId)}/files/search`, {
            method: 'post',
            body: params,
        });
    };

    searchFiles = (teamId: string, terms: string, isOrSearch: boolean) => {
        return this.searchFilesWithParams(teamId, {terms, is_or_search: isOrSearch});
    };

    getOpenGraphMetadata = (url: string) => {
        return this.doFetch<OpenGraphMetadata>(`${this.getBaseRoute()}/opengraph`, {method: 'post', body: {url}});
    };

    doPostAction = (postId: string, actionId: string, selectedOption = '') => {
        return this.doPostActionWithCookie(postId, actionId, '', selectedOption);
    };

    doPostActionWithCookie = (postId: string, actionId: string, actionCookie: string, selectedOption = '') => {
        if (selectedOption) {
            this.trackEvent('api', 'api_interactive_messages_menu_selected');
        } else {
            this.trackEvent('api', 'api_interactive_messages_button_clicked');
        }

        const msg: any = {
            selected_option: selectedOption,
        };
        if (actionCookie !== '') {
            msg.cookie = actionCookie;
        }
        return this.doFetch<PostActionResponse>(
            `${this.getPostRoute(postId)}/actions/${encodeURIComponent(actionId)}`,
            {method: 'post', body: msg},
        );
    };

    // Files Routes

    getFileUrl(fileId: string, timestamp: number) {
        let url = `${this.getFileRoute(fileId)}`;
        if (timestamp) {
            url += `?${timestamp}`;
        }

        return url;
    }

    getFileThumbnailUrl(fileId: string, timestamp: number) {
        let url = `${this.getFileRoute(fileId)}/thumbnail`;
        if (timestamp) {
            url += `?${timestamp}`;
        }

        return url;
    }

    getFilePreviewUrl(fileId: string, timestamp: number) {
        let url = `${this.getFileRoute(fileId)}/preview`;
        if (timestamp) {
            url += `?${timestamp}`;
        }

        return url;
    }

    uploadFile = (fileFormData: any) => {
        this.trackEvent('api', 'api_files_upload');

        return this.postFormData<FileUploadResponse>(this.getFilesRoute(), fileFormData);
    };

    deleteFile = (fileId: string) => {
        this.trackEvent('api', 'api_files_delete');

        const url = this.getFileRoute(fileId);

        return this.doFetchWithResponse<{status: string}>(url, {
            method: 'delete',
        });
    };

    getFilePublicLink = (fileId: string) => {
        return this.doFetch<{
            link: string;
        }>(`${this.getFileRoute(fileId)}/link`, {method: 'get'});
    };

    // Preference Routes

    savePreferences = (userId: string, preferences: PreferenceType[]) => {
        return this.doFetch<StatusOK>(`${this.getPreferencesRoute(userId)}`, {method: 'put', body: preferences});
    };

    getMyPreferences = () => {
        return this.doFetch<PreferenceType>(`${this.getPreferencesRoute('me')}`, {method: 'get'});
    };

    deletePreferences = (userId: string, preferences: PreferenceType[]) => {
        return this.doFetch<StatusOK>(`${this.getPreferencesRoute(userId)}/delete`, {
            method: 'post',
            body: preferences,
        });
    };

    // General Routes

    ping = () => {
        return this.doFetch<{
            status: string;
        }>(`${this.getBaseRoute()}/system/ping?time=${Date.now()}`, {method: 'get'});
    };

    upgradeToEnterprise = async () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/upgrade_to_enterprise`, {method: 'post'});
    };

    upgradeToEnterpriseStatus = async () => {
        return this.doFetch<{
            percentage: number;
            error: string | null;
        }>(`${this.getBaseRoute()}/upgrade_to_enterprise/status`, {method: 'get'});
    };

    restartServer = async () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/restart`, {method: 'post'});
    };

    logClientError = (message: string, level = LogLevel.Error) => {
        const url = `${this.getBaseRoute()}/logs`;

        if (!this.enableLogging) {
            throw new ClientError(this.getUrl(), {
                message: 'Logging disabled.',
                url,
            });
        }

        return this.doFetch<{
            message: string;
        }>(url, {method: 'post', body: {message, level}});
    };

    getClientConfigOld = () => {
        return this.doFetch<ClientConfig>(`${this.getBaseRoute()}/config/client?format=old`, {
            method: 'get',
        });
    };

    getClientLicenseOld = () => {
        return this.doFetch<ClientLicense>(`${this.getBaseRoute()}/license/client?format=old`, {
            method: 'get',
        });
    };

    getClientLicenseUsage = () => {
        return this.doFetch<Record<string, string>>(`${this.getBaseRoute()}/license/usage`, {
            method: 'get',
        });
    };

    getWarnMetricsStatus = async () => {
        return this.doFetch(`${this.getBaseRoute()}/warn_metrics/status`, {method: 'get'});
    };

    sendWarnMetricAck = async (warnMetricId: string, forceAckVal: boolean) => {
        return this.doFetch(`${this.getBaseRoute()}/warn_metrics/ack/${encodeURI(warnMetricId)}`, {
            method: 'post',
            body: {forceAck: forceAckVal},
        });
    };

    setFirstAdminVisitMarketplaceStatus = async () => {
        return this.doFetch<StatusOK>(`${this.getPluginsRoute()}/marketplace/first_admin_visit`, {
            method: 'post',
            body: {first_admin_visit_marketplace_status: true},
        });
    };

    getFirstAdminVisitMarketplaceStatus = async () => {
        return this.doFetch<SystemSetting>(`${this.getPluginsRoute()}/marketplace/first_admin_visit`, {method: 'get'});
    };

    getFirstAdminSetupComplete = async () => {
        return this.doFetch<SystemSetting>(`${this.getSystemRoute()}/onboarding/complete`, {method: 'get'});
    };

    getTranslations = (url: string) => {
        return this.doFetch<Record<string, string>>(url, {method: 'get'});
    };

    getWebSocketUrl = () => {
        return `${this.getBaseRoute()}/websocket`;
    };

    // Integration Routes

    createIncomingWebhook = (hook: IncomingWebhook) => {
        this.trackEvent('api', 'api_integrations_created', {team_id: hook.team_id});

        return this.doFetch<IncomingWebhook>(`${this.getIncomingHooksRoute()}`, {method: 'post', body: hook});
    };

    getIncomingWebhook = (hookId: string) => {
        return this.doFetch<IncomingWebhook>(`${this.getIncomingHookRoute(hookId)}`, {method: 'get'});
    };

    getIncomingWebhooks = (teamId = '', page = 0, perPage = PER_PAGE_DEFAULT) => {
        const queryParams: any = {
            page,
            per_page: perPage,
        };

        if (teamId) {
            queryParams.team_id = teamId;
        }

        return this.doFetch<IncomingWebhook[]>(`${this.getIncomingHooksRoute()}${buildQueryString(queryParams)}`, {
            method: 'get',
        });
    };

    removeIncomingWebhook = (hookId: string) => {
        this.trackEvent('api', 'api_integrations_deleted');

        return this.doFetch<StatusOK>(`${this.getIncomingHookRoute(hookId)}`, {method: 'delete'});
    };

    updateIncomingWebhook = (hook: IncomingWebhook) => {
        this.trackEvent('api', 'api_integrations_updated', {team_id: hook.team_id});

        return this.doFetch<IncomingWebhook>(`${this.getIncomingHookRoute(hook.id)}`, {method: 'put', body: hook});
    };

    createOutgoingWebhook = (hook: OutgoingWebhook) => {
        this.trackEvent('api', 'api_integrations_created', {team_id: hook.team_id});

        return this.doFetch<OutgoingWebhook>(`${this.getOutgoingHooksRoute()}`, {method: 'post', body: hook});
    };

    getOutgoingWebhook = (hookId: string) => {
        return this.doFetch<OutgoingWebhook>(`${this.getOutgoingHookRoute(hookId)}`, {method: 'get'});
    };

    getOutgoingWebhooks = (channelId = '', teamId = '', page = 0, perPage = PER_PAGE_DEFAULT) => {
        const queryParams: any = {
            page,
            per_page: perPage,
        };

        if (channelId) {
            queryParams.channel_id = channelId;
        }

        if (teamId) {
            queryParams.team_id = teamId;
        }

        return this.doFetch<OutgoingWebhook[]>(`${this.getOutgoingHooksRoute()}${buildQueryString(queryParams)}`, {
            method: 'get',
        });
    };

    removeOutgoingWebhook = (hookId: string) => {
        this.trackEvent('api', 'api_integrations_deleted');

        return this.doFetch<StatusOK>(`${this.getOutgoingHookRoute(hookId)}`, {method: 'delete'});
    };

    updateOutgoingWebhook = (hook: OutgoingWebhook) => {
        this.trackEvent('api', 'api_integrations_updated', {team_id: hook.team_id});

        return this.doFetch<OutgoingWebhook>(`${this.getOutgoingHookRoute(hook.id)}`, {method: 'put', body: hook});
    };

    regenOutgoingHookToken = (id: string) => {
        return this.doFetch<OutgoingWebhook>(`${this.getOutgoingHookRoute(id)}/regen_token`, {method: 'post'});
    };

    getCommandsList = (teamId: string) => {
        return this.doFetch<Command[]>(`${this.getCommandsRoute()}?team_id=${teamId}`, {method: 'get'});
    };

    getCommandAutocompleteSuggestionsList = (userInput: string, teamId: string, commandArgs: CommandArgs) => {
        return this.doFetch<AutocompleteSuggestion[]>(
            `${this.getTeamRoute(teamId)}/commands/autocomplete_suggestions${buildQueryString({
                ...commandArgs,
                user_input: userInput,
            })}`,
            {method: 'get'},
        );
    };

    getAutocompleteCommandsList = (teamId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Command[]>(
            `${this.getTeamRoute(teamId)}/commands/autocomplete${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getCustomTeamCommands = (teamId: string) => {
        return this.doFetch<Command[]>(`${this.getCommandsRoute()}?team_id=${teamId}&custom_only=true`, {
            method: 'get',
        });
    };

    executeCommand = (command: string, commandArgs: CommandArgs) => {
        this.trackEvent('api', 'api_integrations_used');

        return this.doFetch<CommandResponse>(`${this.getCommandsRoute()}/execute`, {
            method: 'post',
            body: {command, ...commandArgs},
        });
    };

    addCommand = (command: Command) => {
        this.trackEvent('api', 'api_integrations_created');

        return this.doFetch<Command>(`${this.getCommandsRoute()}`, {method: 'post', body: command});
    };

    editCommand = (command: Command) => {
        this.trackEvent('api', 'api_integrations_created');

        return this.doFetch<Command>(`${this.getCommandsRoute()}/${command.id}`, {method: 'put', body: command});
    };

    regenCommandToken = (id: string) => {
        return this.doFetch<{
            token: string;
        }>(`${this.getCommandsRoute()}/${id}/regen_token`, {method: 'put'});
    };

    deleteCommand = (id: string) => {
        this.trackEvent('api', 'api_integrations_deleted');

        return this.doFetch<StatusOK>(`${this.getCommandsRoute()}/${id}`, {method: 'delete'});
    };

    createOAuthApp = (app: OAuthApp) => {
        this.trackEvent('api', 'api_apps_register');

        return this.doFetch<OAuthApp>(`${this.getOAuthAppsRoute()}`, {method: 'post', body: app});
    };

    editOAuthApp = (app: OAuthApp) => {
        return this.doFetch<OAuthApp>(`${this.getOAuthAppsRoute()}/${app.id}`, {method: 'put', body: app});
    };

    getOAuthApps = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<OAuthApp[]>(`${this.getOAuthAppsRoute()}${buildQueryString({page, per_page: perPage})}`, {
            method: 'get',
        });
    };

    getAppsOAuthAppIDs = () => {
        return this.doFetch<string[]>(`${this.getAppsProxyRoute()}/api/v1/oauth-app-ids`, {method: 'get'});
    };

    getAppsBotIDs = () => {
        return this.doFetch<string[]>(`${this.getAppsProxyRoute()}/api/v1/bot-ids`, {method: 'get'});
    };

    getOAuthApp = (appId: string) => {
        return this.doFetch<OAuthApp>(`${this.getOAuthAppRoute(appId)}`, {method: 'get'});
    };

    getOAuthAppInfo = (appId: string) => {
        return this.doFetch<OAuthApp>(`${this.getOAuthAppRoute(appId)}/info`, {method: 'get'});
    };

    deleteOAuthApp = (appId: string) => {
        this.trackEvent('api', 'api_apps_delete');

        return this.doFetch<StatusOK>(`${this.getOAuthAppRoute(appId)}`, {method: 'delete'});
    };

    regenOAuthAppSecret = (appId: string) => {
        return this.doFetch<OAuthApp>(`${this.getOAuthAppRoute(appId)}/regen_secret`, {method: 'post'});
    };

    submitInteractiveDialog = (data: DialogSubmission) => {
        this.trackEvent('api', 'api_interactive_messages_dialog_submitted');
        return this.doFetch<SubmitDialogResponse>(`${this.getBaseRoute()}/actions/dialogs/submit`, {
            method: 'post',
            body: data,
        });
    };

    // Emoji Routes

    createCustomEmoji = (emoji: CustomEmoji, imageData: File) => {
        this.trackEvent('api', 'api_emoji_custom_add');

        return this.postFormData<CustomEmoji>(this.getEmojisRoute(), {
            image: imageData,
            emoji: JSON.stringify(emoji),
        });
    };

    postFormData = async <R extends Record<string, any>>(url: string, data: any, options = {}) => {
        const result = await this.fetchWithResponse<R>(url, {...options, body: data}, true);

        return result.data;
    };

    getCustomEmoji = (id: string) => {
        return this.doFetch<CustomEmoji>(`${this.getEmojisRoute()}/${id}`, {method: 'get'});
    };

    getCustomEmojiByName = (name: string) => {
        const requester = () => this.doFetch<CustomEmoji>(`${this.getEmojisRoute()}/name/${name}`, {method: 'get'});
        return this.cacheUtil.get('emoji-by-name', name, requester);
    };

    getCustomEmojis = (page = 0, perPage = PER_PAGE_DEFAULT, sort = '') => {
        return this.doFetch<CustomEmoji[]>(
            `${this.getEmojisRoute()}${buildQueryString({page, per_page: perPage, sort})}`,
            {method: 'get'},
        );
    };

    deleteCustomEmoji = (emojiId: string) => {
        this.trackEvent('api', 'api_emoji_custom_delete');

        return this.doFetch<StatusOK>(`${this.getEmojiRoute(emojiId)}`, {method: 'delete'});
    };

    getSystemEmojiImageUrl = (filename: string) => {
        const extension = filename.endsWith('.png') ? '' : '.png';
        return `${this.url}/static/emoji/${filename}${extension}`;
    };

    getCustomEmojiImageUrl = (id: string) => {
        return `${this.getEmojiRoute(id)}/image`;
    };

    searchCustomEmoji = (term: string, options = {}) => {
        return this.doFetch<CustomEmoji[]>(`${this.getEmojisRoute()}/search`, {
            method: 'post',
            body: {term, ...options},
        });
    };

    autocompleteCustomEmoji = (name: string) => {
        return this.doFetch<CustomEmoji[]>(`${this.getEmojisRoute()}/autocomplete${buildQueryString({name})}`, {
            method: 'get',
        });
    };

    // Data Retention

    getDataRetentionPolicy = () => {
        return this.doFetch<DataRetentionPolicy>(`${this.getDataRetentionRoute()}/policy`, {method: 'get'});
    };

    getDataRetentionCustomPolicies = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<GetDataRetentionCustomPoliciesRequest>(
            `${this.getDataRetentionRoute()}/policies${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getDataRetentionCustomPolicy = (id: string) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}`, {
            method: 'get',
        });
    };

    deleteDataRetentionCustomPolicy = (id: string) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}`, {
            method: 'delete',
        });
    };

    searchDataRetentionCustomPolicyChannels = (policyId: string, term: string, opts: ChannelSearchOpts) => {
        return this.doFetch<DataRetentionCustomPolicies>(
            `${this.getDataRetentionRoute()}/policies/${policyId}/channels/search`,
            {method: 'post', body: {term, ...opts}},
        );
    };

    searchDataRetentionCustomPolicyTeams = (policyId: string, term: string, opts: TeamSearchOpts) => {
        return this.doFetch<DataRetentionCustomPolicies>(
            `${this.getDataRetentionRoute()}/policies/${policyId}/teams/search`,
            {method: 'post', body: {term, ...opts}},
        );
    };

    getDataRetentionCustomPolicyTeams = (id: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Team[]>(
            `${this.getDataRetentionRoute()}/policies/${id}/teams${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getDataRetentionCustomPolicyChannels = (id: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<{channels: Channel[]; total_count: number}>(
            `${this.getDataRetentionRoute()}/policies/${id}/channels${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    createDataRetentionPolicy = (policy: CreateDataRetentionCustomPolicy) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies`, {
            method: 'post',
            body: policy,
        });
    };

    updateDataRetentionPolicy = (id: string, policy: PatchDataRetentionCustomPolicy) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}`, {
            method: 'PATCH',
            body: policy,
        });
    };
    addDataRetentionPolicyTeams = (id: string, teams: string[]) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}/teams`, {
            method: 'post',
            body: teams,
        });
    };
    removeDataRetentionPolicyTeams = (id: string, teams: string[]) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}/teams`, {
            method: 'delete',
            body: teams,
        });
    };
    addDataRetentionPolicyChannels = (id: string, channels: string[]) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}/channels`, {
            method: 'post',
            body: channels,
        });
    };
    removeDataRetentionPolicyChannels = (id: string, channels: string[]) => {
        return this.doFetch<DataRetentionCustomPolicies>(`${this.getDataRetentionRoute()}/policies/${id}/channels`, {
            method: 'delete',
            body: channels,
        });
    };

    // Jobs Routes
    getJob = (id: string) => {
        return this.doFetch<Job>(`${this.getJobsRoute()}/${id}`, {method: 'get'});
    };

    getJobs = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Job[]>(`${this.getJobsRoute()}${buildQueryString({page, per_page: perPage})}`, {
            method: 'get',
        });
    };

    getJobsByType = (type: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Job[]>(
            `${this.getJobsRoute()}/type/${type}${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    createJob = (job: Job) => {
        return this.doFetch<Job>(`${this.getJobsRoute()}`, {method: 'post', body: job});
    };

    cancelJob = (id: string) => {
        return this.doFetch<StatusOK>(`${this.getJobsRoute()}/${id}/cancel`, {method: 'post'});
    };

    // Admin Routes

    getLogs = (page = 0, perPage = LOGS_PER_PAGE_DEFAULT) => {
        return this.doFetch<string[]>(
            `${this.getBaseRoute()}/logs${buildQueryString({page, logs_per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getAudits = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Audit[]>(`${this.getBaseRoute()}/audits${buildQueryString({page, per_page: perPage})}`, {
            method: 'get',
        });
    };

    getConfig = () => {
        return this.doFetch<AdminConfig>(`${this.getBaseRoute()}/config`, {method: 'get'});
    };

    updateConfig = (config: AdminConfig) => {
        return this.doFetch<AdminConfig>(`${this.getBaseRoute()}/config`, {method: 'put', body: config});
    };

    patchConfig = (patch: DeepPartial<AdminConfig>) => {
        return this.doFetch<AdminConfig>(`${this.getBaseRoute()}/config/patch`, {method: 'put', body: patch});
    };

    reloadConfig = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/config/reload`, {method: 'post'});
    };

    getEnvironmentConfig = () => {
        return this.doFetch<EnvironmentConfig>(`${this.getBaseRoute()}/config/environment`, {method: 'get'});
    };

    testEmail = (config: AdminConfig) => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/email/test`, {method: 'post', body: config});
    };

    testSiteURL = (siteURL: string) => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/site_url/test`, {
            method: 'post',
            body: {site_url: siteURL},
        });
    };

    testS3Connection = (config: ClientConfig) => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/file/s3_test`, {method: 'post', body: config});
    };

    invalidateCaches = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/caches/invalidate`, {method: 'post'});
    };

    recycleDatabase = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/database/recycle`, {method: 'post'});
    };

    createComplianceReport = (job: Partial<Compliance>) => {
        return this.doFetch<Compliance>(`${this.getBaseRoute()}/compliance/reports`, {method: 'post', body: job});
    };

    getComplianceReport = (reportId: string) => {
        return this.doFetch<Compliance>(`${this.getBaseRoute()}/compliance/reports/${reportId}`, {method: 'get'});
    };

    getComplianceReports = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Compliance[]>(
            `${this.getBaseRoute()}/compliance/reports${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    uploadBrandImage = (imageData: File) => {
        return this.postFormData<StatusOK>(`${this.getBrandRoute()}/image`, {
            image: imageData,
        });
    };

    deleteBrandImage = () => {
        return this.doFetch<StatusOK>(`${this.getBrandRoute()}/image`, {method: 'delete'});
    };

    getClusterStatus = () => {
        return this.doFetch<ClusterInfo[]>(`${this.getBaseRoute()}/cluster/status`, {method: 'get'});
    };

    testLdap = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/ldap/test`, {method: 'post'});
    };

    syncLdap = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/ldap/sync`, {method: 'post'});
    };

    getLdapGroups = (page = 0, perPage = PER_PAGE_DEFAULT, opts = {}) => {
        const query = {page, per_page: perPage, ...opts};
        return this.doFetch<{
            count: number;
            groups: MixedUnlinkedGroup[];
        }>(`${this.getBaseRoute()}/ldap/groups${buildQueryString(query)}`, {method: 'get'});
    };

    linkLdapGroup = (key: string) => {
        return this.doFetch<Group>(`${this.getBaseRoute()}/ldap/groups/${encodeURI(key)}/link`, {method: 'post'});
    };

    unlinkLdapGroup = (key: string) => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/ldap/groups/${encodeURI(key)}/link`, {method: 'delete'});
    };

    getSamlCertificateStatus = () => {
        return this.doFetch<SamlCertificateStatus>(`${this.getBaseRoute()}/saml/certificate/status`, {method: 'get'});
    };

    uploadPublicSamlCertificate = (fileData: File) => {
        return this.postFormData<StatusOK>(`${this.getBaseRoute()}/saml/certificate/public`, {
            certificate: fileData,
        });
    };

    uploadPrivateSamlCertificate = (fileData: File) => {
        return this.postFormData<StatusOK>(`${this.getBaseRoute()}/saml/certificate/private`, {
            certificate: fileData,
        });
    };

    uploadPublicLdapCertificate = (fileData: File) => {
        return this.postFormData<StatusOK>(`${this.getBaseRoute()}/ldap/certificate/public`, {
            certificate: fileData,
        });
    };

    uploadPrivateLdapCertificate = (fileData: File) => {
        return this.postFormData<StatusOK>(`${this.getBaseRoute()}/ldap/certificate/private`, {
            certificate: fileData,
        });
    };

    uploadIdpSamlCertificate = (fileData: File) => {
        return this.postFormData<StatusOK>(`${this.getBaseRoute()}/saml/certificate/idp`, {
            certificate: fileData,
        });
    };

    deletePublicSamlCertificate = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/saml/certificate/public`, {method: 'delete'});
    };

    deletePrivateSamlCertificate = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/saml/certificate/private`, {method: 'delete'});
    };

    deletePublicLdapCertificate = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/ldap/certificate/public`, {method: 'delete'});
    };

    deletePrivateLdapCertificate = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/ldap/certificate/private`, {method: 'delete'});
    };

    deleteIdpSamlCertificate = () => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/saml/certificate/idp`, {method: 'delete'});
    };

    testElasticsearch = (config: ClientConfig) => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/elasticsearch/test`, {method: 'post', body: config});
    };

    purgeElasticsearchIndexes = (data: string[]) => {
        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/elasticsearch/purge_indexes`, {method: 'post', body: data});
    };

    recalcElasticsearchIndexes = (data: Record<string, string>) => {
        return this.doFetch<Job>(`${this.getJobsRoute()}`, {method: 'post', body: {type: JobTypes.ELASTICSEARCH_POST_INDEXING, data}});
    };

    uploadLicense = (fileData: File) => {
        this.trackEvent('api', 'api_license_upload');

        return this.postFormData<License>(`${this.getBaseRoute()}/license`, {
            license: fileData,
        });
    };

    getAnalytics = (name = 'standard', teamId = '') => {
        return this.doFetch<AnalyticsRow[]>(
            `${this.getBaseRoute()}/analytics/old${buildQueryString({name, team_id: teamId})}`,
            {method: 'get'},
        );
    };

    // Role Routes

    getRole = (roleId: string) => {
        return this.doFetch<Role>(`${this.getRolesRoute()}/${roleId}`, {method: 'get'});
    };

    getRoleByName = (roleName: string) => {
        return this.doFetch<Role>(`${this.getRolesRoute()}/name/${roleName}`, {method: 'get'});
    };

    getRolesByNames = (rolesNames: string[]) => {
        return this.doFetch<Role[]>(`${this.getRolesRoute()}/names`, {method: 'post', body: rolesNames});
    };

    patchRole = (roleId: string, rolePatch: Partial<Role>) => {
        return this.doFetch<Role>(`${this.getRolesRoute()}/${roleId}/patch`, {method: 'put', body: rolePatch});
    };

    // Scheme Routes

    getSchemes = (scope = '', page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Scheme[]>(
            `${this.getSchemesRoute()}${buildQueryString({scope, page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    createScheme = (scheme: Scheme) => {
        this.trackEvent('api', 'api_schemes_create');

        return this.doFetch<Scheme>(`${this.getSchemesRoute()}`, {method: 'post', body: scheme});
    };

    getScheme = (schemeId: string) => {
        return this.doFetch<Scheme>(`${this.getSchemesRoute()}/${schemeId}`, {method: 'get'});
    };

    deleteScheme = (schemeId: string) => {
        this.trackEvent('api', 'api_schemes_delete');

        return this.doFetch<StatusOK>(`${this.getSchemesRoute()}/${schemeId}`, {method: 'delete'});
    };

    patchScheme = (schemeId: string, schemePatch: Partial<Scheme>) => {
        this.trackEvent('api', 'api_schemes_patch', {scheme_id: schemeId});

        return this.doFetch<Scheme>(`${this.getSchemesRoute()}/${schemeId}/patch`, {method: 'put', body: schemePatch});
    };

    getSchemeTeams = (schemeId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Team[]>(
            `${this.getSchemesRoute()}/${schemeId}/teams${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getSchemeChannels = (schemeId: string, page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Channel[]>(
            `${this.getSchemesRoute()}/${schemeId}/channels${buildQueryString({page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    // Plugin Routes - EXPERIMENTAL - SUBJECT TO CHANGE

    uploadPlugin = async (fileData: File, force = false) => {
        this.trackEvent('api', 'api_plugin_upload');

        const data: {
            plugin: File;
            force?: 'true';
        } = {
            plugin: fileData,
        };

        if (force) {
            data.force = 'true';
        }

        return this.postFormData<PluginManifest>(this.getPluginsRoute(), data);
    };

    installPluginFromUrl = (pluginDownloadUrl: string, force = false) => {
        this.trackEvent('api', 'api_install_plugin');

        const queryParams = {plugin_download_url: pluginDownloadUrl, force};

        return this.doFetch<PluginManifest>(
            `${this.getPluginsRoute()}/install_from_url${buildQueryString(queryParams)}`,
            {method: 'post'},
        );
    };

    getPlugins = () => {
        return this.doFetch<PluginsResponse>(this.getPluginsRoute(), {method: 'get'});
    };

    getMarketplacePlugins = (filter: string, localOnly = false) => {
        return this.doFetch<MarketplacePlugin[]>(
            `${this.getPluginsMarketplaceRoute()}${buildQueryString({filter: filter || '', local_only: localOnly})}`,
            {method: 'get'},
        );
    };

    installMarketplacePlugin = (id: string, version: string) => {
        this.trackEvent('api', 'api_install_marketplace_plugin');

        return this.doFetch<MarketplacePlugin>(`${this.getPluginsMarketplaceRoute()}`, {
            method: 'post',
            body: {id, version},
        });
    };

    // This function belongs to the Apps Framework feature.
    // Apps Framework feature is experimental, and this function is susceptible
    // to breaking changes without pushing the major version of this package.
    getMarketplaceApps = (filter: string) => {
        return this.doFetch<MarketplaceApp[]>(
            `${this.getAppsProxyRoute()}/api/v1/marketplace${buildQueryString({filter: filter || ''})}`,
            {method: 'get'},
        );
    };

    getPluginStatuses = () => {
        return this.doFetch<PluginStatus[]>(`${this.getPluginsRoute()}/statuses`, {method: 'get'});
    };

    removePlugin = (pluginId: string) => {
        return this.doFetch<StatusOK>(this.getPluginRoute(pluginId), {method: 'delete'});
    };

    getWebappPlugins = () => {
        return this.doFetch<ClientPluginManifest[]>(`${this.getPluginsRoute()}/webapp`, {method: 'get'});
    };

    enablePlugin = (pluginId: string) => {
        return this.doFetch<StatusOK>(`${this.getPluginRoute(pluginId)}/enable`, {method: 'post'});
    };

    disablePlugin = (pluginId: string) => {
        return this.doFetch<StatusOK>(`${this.getPluginRoute(pluginId)}/disable`, {method: 'post'});
    };

    // Groups

    linkGroupSyncable = (groupID: string, syncableID: string, syncableType: string, patch: SyncablePatch) => {
        return this.doFetch<GroupSyncable>(`${this.getGroupRoute(groupID)}/${syncableType}s/${syncableID}/link`, {
            method: 'post',
            body: patch,
        });
    };

    unlinkGroupSyncable = (groupID: string, syncableID: string, syncableType: string) => {
        return this.doFetch<StatusOK>(`${this.getGroupRoute(groupID)}/${syncableType}s/${syncableID}/link`, {
            method: 'delete',
        });
    };

    getGroupSyncables = (groupID: string, syncableType: string) => {
        return this.doFetch<GroupSyncable[]>(`${this.getGroupRoute(groupID)}/${syncableType}s`, {method: 'get'});
    };

    getGroup = (groupID: string, includeMemberCount = false) => {
        return this.doFetch<Group>(
            `${this.getGroupRoute(groupID)}${buildQueryString({include_member_count: includeMemberCount})}`,
            {method: 'get'},
        );
    };

    getGroupsByNames = (names: string[]) => {
        return this.doFetch<Group[]>(`${this.getGroupsRoute()}/names`, {method: 'post', body: {names}});
    }

    getGroupStats = (groupID: string) => {
        return this.doFetch<Group>(`${this.getGroupRoute(groupID)}/stats`, {method: 'get'});
    };

    getGroups = (
        filterAllowReference = false,
        page = 0,
        perPage = 10,
        includeMemberCount = false,
        hasFilterMember = false,
    ) => {
        const qs: any = {
            filter_allow_reference: filterAllowReference,
            page,
            per_page: perPage,
            include_member_count: includeMemberCount,
        };

        if (hasFilterMember) {
            qs.filter_has_member = hasFilterMember;
        }
        return this.doFetch<Group[]>(`${this.getGroupsRoute()}${buildQueryString(qs)}`, {method: 'get'});
    };

    getGroupsByUserId = (userID: string) => {
        return this.doFetch<Group[]>(`${this.getUsersRoute()}/${userID}/groups`, {method: 'get'});
    };

    getGroupsNotAssociatedToTeam = (teamID: string, q = '', page = 0, perPage = PER_PAGE_DEFAULT, source = 'ldap') => {
        this.trackEvent('api', 'api_groups_get_not_associated_to_team', {team_id: teamID});
        return this.doFetch<Group[]>(
            `${this.getGroupsRoute()}${buildQueryString({
                not_associated_to_team: teamID,
                page,
                per_page: perPage,
                q,
                include_member_count: true,
                group_source: source,
            })}`,
            {method: 'get'},
        );
    };

    getGroupsNotAssociatedToChannel = (
        channelID: string,
        q = '',
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        filterParentTeamPermitted = false,
        source = 'ldap',
    ) => {
        this.trackEvent('api', 'api_groups_get_not_associated_to_channel', {channel_id: channelID});
        const query = {
            not_associated_to_channel: channelID,
            page,
            per_page: perPage,
            q,
            include_member_count: true,
            filter_parent_team_permitted: filterParentTeamPermitted,
            group_source: source,
        };
        return this.doFetch<Group[]>(`${this.getGroupsRoute()}${buildQueryString(query)}`, {method: 'get'});
    };

    createGroupWithUserIds = (group: GroupCreateWithUserIds) => {
        return this.doFetch<Group>(this.getGroupsRoute(), {method: 'post', body: group});
    };

    addUsersToGroup = (groupId: string, userIds: string[]) => {
        return this.doFetch<UserProfile[]>(`${this.getGroupRoute(groupId)}/members`, {
            method: 'post',
            body: {user_ids: userIds},
        });
    };

    removeUsersFromGroup = (groupId: string, userIds: string[]) => {
        return this.doFetch<UserProfile[]>(`${this.getGroupRoute(groupId)}/members`, {
            method: 'delete',
            body: {user_ids: userIds},
        });
    };

    searchGroups = (params: GroupSearachParams) => {
        return this.doFetch<Group[]>(`${this.getGroupsRoute()}${buildQueryString(params)}`, {method: 'get'});
    };

    // This function belongs to the Apps Framework feature.
    // Apps Framework feature is experimental, and this function is susceptible
    // to breaking changes without pushing the major version of this package.
    executeAppCall = async (call: AppCallRequest, trackAsSubmit: boolean) => {
        const callCopy: AppCallRequest = {
            ...call,
            context: {
                ...call.context,
                track_as_submit: trackAsSubmit,
                user_agent: 'webapp',
            },
        };
        return this.doFetch<AppCallResponse>(`${this.getAppsProxyRoute()}/api/v1/call`, {
            method: 'post',
            body: callCopy,
        });
    };

    // This function belongs to the Apps Framework feature.
    // Apps Framework feature is experimental, and this function is susceptible
    // to breaking changes without pushing the major version of this package.
    getAppsBindings = async (userID: string, channelID: string, teamID: string) => {
        const params = {
            user_id: userID,
            channel_id: channelID,
            team_id: teamID,
            user_agent: 'webapp',
        };

        return this.doFetch<AppBinding[]>(`${this.getAppsProxyRoute()}/api/v1/bindings${buildQueryString(params)}`, {
            method: 'get',
        });
    };

    getGroupsAssociatedToTeam = (
        teamID: string,
        q = '',
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        filterAllowReference = false,
    ) => {
        this.trackEvent('api', 'api_groups_get_associated_to_team', {team_id: teamID});

        return this.doFetch<{
            groups: Group[];
            total_group_count: number;
        }>(
            `${this.getBaseRoute()}/teams/${teamID}/groups${buildQueryString({
                page,
                per_page: perPage,
                q,
                include_member_count: true,
                filter_allow_reference: filterAllowReference,
            })}`,
            {method: 'get'},
        );
    };

    getGroupsAssociatedToChannel = (
        channelID: string,
        q = '',
        page = 0,
        perPage = PER_PAGE_DEFAULT,
        filterAllowReference = false,
    ) => {
        this.trackEvent('api', 'api_groups_get_associated_to_channel', {channel_id: channelID});

        return this.doFetch<{
            groups: Group[];
            total_group_count: number;
        }>(
            `${this.getBaseRoute()}/channels/${channelID}/groups${buildQueryString({
                page,
                per_page: perPage,
                q,
                include_member_count: true,
                filter_allow_reference: filterAllowReference,
            })}`,
            {method: 'get'},
        );
    };

    getAllGroupsAssociatedToTeam = (teamID: string, filterAllowReference = false, includeMemberCount = false) => {
        return this.doFetch<GroupsWithCount>(
            `${this.getBaseRoute()}/teams/${teamID}/groups${buildQueryString({
                paginate: false,
                filter_allow_reference: filterAllowReference,
                include_member_count: includeMemberCount,
            })}`,
            {method: 'get'},
        );
    };

    getAllGroupsAssociatedToChannelsInTeam = (teamID: string, filterAllowReference = false) => {
        return this.doFetch<{
            groups: RelationOneToOne<Channel, Group>;
        }>(
            `${this.getBaseRoute()}/teams/${teamID}/groups_by_channels${buildQueryString({
                paginate: false,
                filter_allow_reference: filterAllowReference,
            })}`,
            {method: 'get'},
        );
    };

    getAllGroupsAssociatedToChannel = (channelID: string, filterAllowReference = false, includeMemberCount = false) => {
        return this.doFetch<GroupsWithCount>(
            `${this.getBaseRoute()}/channels/${channelID}/groups${buildQueryString({
                paginate: false,
                filter_allow_reference: filterAllowReference,
                include_member_count: includeMemberCount,
            })}`,
            {method: 'get'},
        );
    };

    patchGroupSyncable = (groupID: string, syncableID: string, syncableType: string, patch: SyncablePatch) => {
        return this.doFetch<GroupSyncable>(`${this.getGroupRoute(groupID)}/${syncableType}s/${syncableID}/patch`, {
            method: 'put',
            body: patch,
        });
    };

    patchGroup = (groupID: string, patch: GroupPatch | CustomGroupPatch) => {
        return this.doFetch<Group>(`${this.getGroupRoute(groupID)}/patch`, {method: 'put', body: patch});
    };

    archiveGroup = (groupId: string) => {
        return this.doFetch<Group>(`${this.getGroupRoute(groupId)}`, {method: 'delete'});
    };

    // Redirect Location
    getRedirectLocation = (urlParam: string) => {
        if (!urlParam.length) {
            return Promise.resolve();
        }
        const url = `${this.getRedirectLocationRoute()}${buildQueryString({url: urlParam})}`;
        return this.doFetch<{
            location: string;
        }>(url, {method: 'get'});
    };

    // Bot Routes

    createBot = (bot: Bot) => {
        return this.doFetch<Bot>(`${this.getBotsRoute()}`, {method: 'post', body: bot});
    };

    patchBot = (botUserId: string, botPatch: BotPatch) => {
        return this.doFetch<Bot>(`${this.getBotRoute(botUserId)}`, {method: 'put', body: botPatch});
    };

    getBot = (botUserId: string) => {
        return this.doFetch<Bot>(`${this.getBotRoute(botUserId)}`, {method: 'get'});
    };

    getBots = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Bot[]>(`${this.getBotsRoute()}${buildQueryString({page, per_page: perPage})}`, {
            method: 'get',
        });
    };

    getBotsIncludeDeleted = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Bot[]>(
            `${this.getBotsRoute()}${buildQueryString({include_deleted: true, page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    getBotsOrphaned = (page = 0, perPage = PER_PAGE_DEFAULT) => {
        return this.doFetch<Bot[]>(
            `${this.getBotsRoute()}${buildQueryString({only_orphaned: true, page, per_page: perPage})}`,
            {method: 'get'},
        );
    };

    disableBot = (botUserId: string) => {
        return this.doFetch<Bot>(`${this.getBotRoute(botUserId)}/disable`, {method: 'post'});
    };

    enableBot = (botUserId: string) => {
        return this.doFetch<Bot>(`${this.getBotRoute(botUserId)}/enable`, {method: 'post'});
    };

    assignBot = (botUserId: string, newOwnerId: string) => {
        return this.doFetch<Bot>(`${this.getBotRoute(botUserId)}/assign/${newOwnerId}`, {method: 'post'});
    };

    // Cloud routes
    getCloudProducts = (includeLegacyProducts?: boolean) => {
        let query = '';
        if (includeLegacyProducts) {
            query = '?include_legacy=true';
        }
        return this.doFetch<Product[]>(`${this.getCloudRoute()}/products${query}`, {method: 'get'});
    };

    createPaymentMethod = async () => {
        return this.doFetch(`${this.getCloudRoute()}/payment`, {method: 'post'});
    };

    getCloudCustomer = () => {
        return this.doFetch<CloudCustomer>(`${this.getCloudRoute()}/customer`, {method: 'get'});
    };

    updateCloudCustomer = (customerPatch: CloudCustomerPatch) => {
        return this.doFetch<CloudCustomer>(`${this.getCloudRoute()}/customer`, {method: 'put', body: customerPatch});
    };

    updateCloudCustomerAddress = (address: Address) => {
        return this.doFetch<CloudCustomer>(`${this.getCloudRoute()}/customer/address`, {method: 'put', body: address});
    };

    confirmPaymentMethod = async (stripeSetupIntentID: string) => {
        return this.doFetch(`${this.getCloudRoute()}/payment/confirm`, {
            method: 'post',
            body: {stripe_setup_intent_id: stripeSetupIntentID},
        });
    };

    subscribeCloudProduct = (productId: string) => {
        return this.doFetch<CloudCustomer>(`${this.getCloudRoute()}/subscription`, {
            method: 'put',
            body: {product_id: productId},
        });
    };

    getSubscription = () => {
        return this.doFetch<Subscription>(`${this.getCloudRoute()}`, {method: 'get'});
    };

    getRenewalLink = () => {
        return this.doFetch<{renewal_link: string}>(`${this.getBaseRoute()}/license/renewal`, {method: 'get'});
    };

    getInvoices = () => {
        return this.doFetch<Invoice[]>(`${this.getCloudRoute()}/subscription/invoices`, {method: 'get'});
    };

    getInvoicePdfUrl = (invoiceId: string) => {
        return `${this.getCloudRoute()}/subscription/invoices/${invoiceId}/pdf`;
    };

    teamMembersMinusGroupMembers = (teamID: string, groupIDs: string[], page: number, perPage: number) => {
        const query = `group_ids=${groupIDs.join(',')}&page=${page}&per_page=${perPage}`;
        return this.doFetch<UsersWithGroupsAndCount>(
            `${this.getTeamRoute(teamID)}/members_minus_group_members?${query}`,
            {method: 'get'},
        );
    };

    channelMembersMinusGroupMembers = (channelID: string, groupIDs: string[], page: number, perPage: number) => {
        const query = `group_ids=${groupIDs.join(',')}&page=${page}&per_page=${perPage}`;
        return this.doFetch<UsersWithGroupsAndCount>(
            `${this.getChannelRoute(channelID)}/members_minus_group_members?${query}`,
            {method: 'get'},
        );
    };

    getSamlMetadataFromIdp = (samlMetadataURL: string) => {
        return this.doFetch<SamlMetadataResponse>(`${this.getBaseRoute()}/saml/metadatafromidp`, {
            method: 'post',
            body: {saml_metadata_url: samlMetadataURL},
        });
    };

    setSamlIdpCertificateFromMetadata = (certData: string) => {
        const request: any = {
            method: 'post',
            body: certData,
        };

        request.headers = {
            'Content-Type': 'application/x-pem-file',
        };

        return this.doFetch<StatusOK>(`${this.getBaseRoute()}/saml/certificate/idp`, request);
    };

    getInProductNotices = (teamId: string, client: string, clientVersion: string) => {
        return this.doFetch<ProductNotices>(
            `${this.getNoticesRoute()}/${teamId}?client=${client}&clientVersion=${clientVersion}`,
            {method: 'get'},
        );
    };

    updateNoticesAsViewed = (noticeIds: string[]) => {
        // Only one notice is marked as viewed at a time so using 0 index
        this.trackEvent('ui', `notice_seen_${noticeIds[0]}`);
        return this.doFetch<StatusOK>(`${this.getNoticesRoute()}/view`, {method: 'put', body: noticeIds});
    };

    getAncillaryPermissions = (subsectionPermissions: string[]) => {
        return this.doFetch<string[]>(
            `${this.getPermissionsRoute()}/ancillary?subsection_permissions=${subsectionPermissions.join(',')}`,
            {method: 'get'},
        );
    };

    completeSetup = (completeOnboardingRequest: CompleteOnboardingRequest) => {
        return this.doFetch<StatusOK>(`${this.getSystemRoute()}/onboarding/complete`, {
            method: 'post',
            body: completeOnboardingRequest,
        });
    };

    getAppliedSchemaMigrations = () => {
        return this.doFetch<SchemaMigration[]>(`${this.getSystemRoute()}/schema/version`, {method: 'get'});
    };

    /**
     * @param query string query of graphQL, pass the json stringified version of the query
     * eg.  const query = {query: `{license, config}`};
     *      client4.fetchWithGraphQL(query);
     */
    fetchWithGraphQL = async <DataResponse>(query: string) => {
        return this.doFetch<DataResponse>(this.getGraphQLUrl(), {method: 'post', body: query});
    };

    // Client Helpers

    doFetch = async <ClientDataResponse>(
        url: string,
        options: Options,
    ): Promise<ClientDataResponse> => {
        const {data} = await this.fetchWithResponse<ClientDataResponse>(url, options, false);

        return data;
    };

    doFetchWithResponse = <ClientDataResponse>(url: string, options: Options) => {
        return this.fetchWithResponse<ClientDataResponse>(url, options, false);
    };

    fetchWithResponse = async <Data>(url: string, options: Options, isFormData = false) => {
        const urlWithApiVFour = url.includes('/api/v4');
        const requestOptions = isFormData ? omit(this.getTimeApiClientOptions(options, !urlWithApiVFour), ['data']) : this.getTimeApiClientOptions(options, !urlWithApiVFour);

        try {
            const properUrl = url.replace(/^\/api\/v4\//gm, '');
            const request = isFormData ? this.requester.postForm<Data>(properUrl, options.body, requestOptions) : this.requester.request<Data>({...requestOptions, url: properUrl});
            const response = await request;

            // There are some Symbol properties in axios headers
            const responseHeaders = JSON.parse(JSON.stringify(response?.headers || {}));
            const headersObj = new Headers(responseHeaders);
            const headers = parseAndMergeNestedHeaders(headersObj);
            const data = response.data;

            if (headers.has(HEADER_X_VERSION_ID) && !headers.get('Cache-Control')) {
                const serverVersion = headers.get(HEADER_X_VERSION_ID);
                if (serverVersion && this.serverVersion !== serverVersion) {
                    this.serverVersion = serverVersion;
                }
            }

            if (headers.has(HEADER_X_CLUSTER_ID)) {
                const clusterId = headers.get(HEADER_X_CLUSTER_ID);
                if (clusterId && this.clusterId !== clusterId) {
                    this.clusterId = clusterId;
                }
            }

            return {
                response: {
                    ...response,
                    ok: true,
                    headers: new Headers(responseHeaders),
                    url: response?.config.url as string,
                } as unknown as Response,
                headers,
                data,
            };
        } catch (err: unknown) {
            const requestError = err as TimeApiClientError;

            // В тестах не все запросы замокированны, что вызывает тут ошибку
            // eslint-disable-next-line no-process-env
            if (process.env.NODE_ENV === 'test' && requestError.code === 'ERR_NETWORK') {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                // eslint-disable-next-line prefer-promise-reject-errors
                return Promise.reject({
                    error: err,
                });
            }

            if (requestError.response && requestError.response.data) {
                const data = requestError.response.data as {
                    message?: string;
                    id: string;
                    detailed_error?: string;
                    status_code?: number;
                    params?: Record<string, unknown>;
                };
                const msg = data?.message || '';

                if (this.logToConsole && msg) {
                    console.error(msg); // eslint-disable-line no-console
                }

                throw new ClientError(this.getUrl(), {
                    message: msg,
                    server_error_id: data?.id,
                    detailedMessage: data.detailed_error,
                    status_code: data?.status_code || requestError.response?.status,
                    url,
                    params: data?.params,
                });
            }

            if (requestError.request) {
                throw new ClientError(this.getUrl(), {
                    status_code: (requestError.code as unknown as number) || 0,
                    message: 'Received invalid response from the server.',
                    intl: {
                        id: 'mobile.request.invalid_response',
                        defaultMessage: 'Received invalid response from the server.',
                    },
                    url,
                });
            }

            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject({});
        }
    };

    trackEvent(category: string, event: string, props?: any) {
        if (this.telemetryHandler) {
            const userRoles =
                this.userRoles && isSystemAdmin(this.userRoles) ? 'system_admin, system_user' : 'system_user';
            this.telemetryHandler.trackEvent(this.userId, userRoles, category, event, props);
        }
    }

    pageVisited(category: string, name: string) {
        if (this.telemetryHandler) {
            const userRoles =
                this.userRoles && isSystemAdmin(this.userRoles) ? 'system_admin, system_user' : 'system_user';
            this.telemetryHandler.pageVisited(this.userId, userRoles, category, name);
        }
    }

    private cacheUtil = new CacheUtil();
}

export function parseAndMergeNestedHeaders(originalHeaders: any) {
    const headers = new Map();
    let nestedHeaders = new Map();
    originalHeaders.forEach((val: string, key: string) => {
        const capitalizedKey = key.replace(/\b[a-z]/g, (l) => l.toUpperCase());
        let realVal = val;
        if (val && val.match(/\n\S+:\s\S+/)) {
            const nestedHeaderStrings = val.split('\n');
            realVal = nestedHeaderStrings.shift() as string;
            const moreNestedHeaders = new Map(nestedHeaderStrings.map((h: any) => h.split(/:\s/)));
            nestedHeaders = new Map([...nestedHeaders, ...moreNestedHeaders]);
        }
        headers.set(capitalizedKey, realVal);
    });
    return new Map([...headers, ...nestedHeaders]);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SENTRY_IGNORE_MESSAGES = [
    'Logging disabled',
    'You do not have the appropriate permissions',
    'У вас нет соответствующих прав',
    'ResizeObserver loop limit exceeded',
];

export class ClientError extends Error implements ServerError {
    url?: string;
    intl?: {
        id: string;
        defaultMessage: string;
        values?: any;
    };
    detailedMessage?: string;
    server_error_id?: string;
    status_code?: ServerError['status_code'];
    params?: Record<string, unknown>;
    originalMessage?: string;

    constructor(
        baseUrl: string,
        data: ServerError,
    ) {
        super(data.message.replace(/\.$/, '') + ': ' + cleanUrlForLogging(baseUrl, data.url || ''));
        this.url = data.url;
        this.intl = data.intl;
        this.server_error_id = data.server_error_id;
        this.detailedMessage = data.detailedMessage;
        this.status_code = data.status_code;
        this.params = data?.params;
        this.originalMessage = data.message;

        // Ensure message is treated as a property of this class when object spreading. Without this,
        // copying the object by using `{...error}` would not include the message.
        Object.defineProperty(this, 'message', {enumerable: true});
    }
}
