import type {GlobalState} from 'types/store';

export function getPrefix(state: GlobalState) {
    if (state && state.entities && state.entities.users && state.entities.users.profiles) {
        const user = state.entities.users.profiles[state.entities.users.currentUserId];
        if (user) {
            return user.id + '_';
        }
    }

    return 'unknown_';
}

export function isLocalStorageSupported() {
    let localStorageSupported = false;

    try {
        localStorage.set('__testLocal__', '1');
        if (localStorage.get('__testLocal__') === '1') {
            localStorageSupported = true;
        }
        localStorage.remove('__testLocal__');
    } catch (e) {
        localStorageSupported = false;
    }

    return localStorageSupported;
}
