import axios, {HttpStatusCode} from 'axios';

import {sendToStatist} from '@time-webkit/statist';

/* eslint-disable no-console */
const OFFLINE_PING_URL = '/api/v4/system/ping';
const LOG_PREFIX = '[Time Ping]: ';

function isApiOK(response: { status: 'OK' }) {
    return response?.status === 'OK';
}

export function redirectToOfflinePage() {
    sendToStatist('app.connectionScreen');

    // eslint-disable-next-line no-process-env
    const replaceUrl = '/time-offline-page/' + process.env.OFFLINE_SW_VERSION + '/index.html';
    window.location.replace(`${replaceUrl}?offline_page_redirect_to=${encodeURIComponent(window.location.href)}`);
}

export async function pingApiOnStartup() {
    try {
        const response = await axios.get(OFFLINE_PING_URL);

        console.debug(`${LOG_PREFIX} response`, response);

        if (response.status !== HttpStatusCode.Ok) {
            console.error(`${LOG_PREFIX} Received not OK response`, response);

            if (response?.status && response.status !== 408) {
                redirectToOfflinePage();
            }
        }

        if (!isApiOK(response.data)) {
            console.error(
                `${LOG_PREFIX} Received not OK response from ping api`,
                response.data,
            );

            redirectToOfflinePage();
        }
    // eslint-disable-next-line no-empty
    } catch {}
}

