/* eslint-disable max-lines */
import type {AnyAction} from 'redux';

import {uniq} from 'lodash';

import {isAxiosError} from 'axios';

import {batchActions} from 'utils/batch_actions';

import {Client4} from 'mattermost-redux/client';

import type {
    ActionFunc,
    ActionResult,
    DispatchFunc,
    GenericAction,
    GetStateFunc,
    ThunkActionWithResult,
} from 'mattermost-redux/types/actions';
import type {
    UserProfile,
    UserStatus,
    GetFilteredUsersStatsOpts,
    UsersStats,
    UserCustomStatus,
} from 'mattermost-redux/types/users';
import {UserTypes, AdminTypes} from 'mattermost-redux/action_types';

import {setServerVersion, getClientConfig, getLicenseConfig} from 'mattermost-redux/actions/general';
import {getMyTeams, getMyTeamMembers} from 'mattermost-redux/actions/teams';
import {loadRolesIfNeeded} from 'mattermost-redux/actions/roles';
import {bindClientFunc, forceLogoutIfNecessary, debounce} from 'mattermost-redux/actions/helpers';
import {getMyPreferences} from 'mattermost-redux/actions/preferences';

import {getServerVersion} from 'mattermost-redux/selectors/entities/general';
import {getCurrentUserId, getUsers} from 'mattermost-redux/selectors/entities/users';

import {isMinimumServerVersion} from 'mattermost-redux/utils/helpers';
import {General} from 'mattermost-redux/constants';
import {getDmUsers} from 'mattermost-redux/selectors/entities/getDmUsers';
import {receivedCurrentUser, receivedUser, receivedUsers} from 'features/users';
import {batchAsyncDebounce} from 'mattermost-redux/utils/debounce';
import {fetchUsersByIds, fetchUsersByIdsDebounced} from 'features/users/actions/fetch_users_by_ids';
import {getProfileIfAllowed} from 'features/profiles';
import {updateUserPropsInStatist} from '@time-webkit/statist';
import type {Options} from '@mattermost/types/client4';
import {receivedUsersInChannels} from 'features/sidebar';
import {isDesktopApp} from 'utils/user_agent';
import {ModalIdentifiers, ServerErrorIds} from 'utils/constants';
import {closeModal} from 'actions/views/modals';
import {openInsufficientPermissionsModal} from 'actions/views/open_insufficient_permissions_modal';
import {ClientError} from 'mattermost-redux/client/client4';

type ActionFn = (dispatch: DispatchFunc, getState: GetStateFunc) => Promise<ActionResult> | ActionResult

export function checkMfa(loginId: string): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        dispatch({type: UserTypes.CHECK_MFA_REQUEST, data: null});
        try {
            const data = await Client4.checkUserMfa(loginId);
            dispatch({type: UserTypes.CHECK_MFA_SUCCESS, data: null});
            return {data: data.mfa_required};
        } catch (error: any) {
            dispatch({type: UserTypes.CHECK_MFA_FAILURE, error});
            return {error};
        }
    };
}

export function generateMfaSecret(userId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.generateMfaSecret,
        params: [userId],
    });
}

export function createUser(user: UserProfile, token: string, inviteId: string, redirect: string): ActionFn {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let created;

        try {
            created = await Client4.createUser(user, token, inviteId, redirect);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        await dispatch(receivedUser(created));

        return {data: created};
    };
}

export function login(loginId: string, password: string, mfaToken = '', ldapOnly = false): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        dispatch({type: UserTypes.LOGIN_REQUEST, data: null});

        const deviceId = getState().entities.general.deviceToken;

        try {
            await Client4.login(loginId, password, mfaToken, deviceId, ldapOnly);

            const dataFromLoadMe = await dispatch(loadMe());

            if (dataFromLoadMe && dataFromLoadMe.data) {
                dispatch({type: UserTypes.LOGIN_SUCCESS});
            }
        } catch (error: any) {
            dispatch({
                type: UserTypes.LOGIN_FAILURE,
                error,
            });
            return {error};
        }

        return {data: true};
    };
}

export function loginById(id: string, password: string, mfaToken = ''): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        dispatch({type: UserTypes.LOGIN_REQUEST, data: null});

        const deviceId = getState().entities.general.deviceToken;

        try {
            await Client4.loginById(id, password, mfaToken, deviceId);
            const dataFromLoadMe = await dispatch(loadMe());

            if (dataFromLoadMe && dataFromLoadMe.data) {
                dispatch({type: UserTypes.LOGIN_SUCCESS});
            }
        } catch (error: any) {
            dispatch({
                type: UserTypes.LOGIN_FAILURE,
                error,
            });
            return {error};
        }

        return {data: true};
    };
}

/**
 * Используется (createUserWithoutDispatch и loginByIdAndSyncLocale)
 * для первичной регистрации для случая когда локаль пользователя
 * в браузере отличается от локали поумолчанию для новых пользователей на сервере
 * @see https://jira.tcsbank.ru/browse/TIME-7235
 * удалить или исправить во время работы над
 * @see https://jira.tcsbank.ru/browse/TIME-7330
 */

export function createUserWithoutDispatch(...args: Parameters<typeof Client4.createUser>) {
    return Client4.createUser(...args);
}
export function loginByIdAndSyncLocale(id: string, password: string, mfaToken = '', locale: string) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        dispatch({type: UserTypes.LOGIN_REQUEST, data: null});

        const deviceId = getState().entities.general.deviceToken;

        try {
            await Client4.loginById(id, password, mfaToken, deviceId);
            await Client4.patchMe({locale});
            const dataFromLoadMe = await dispatch(loadMe());

            if (dataFromLoadMe && dataFromLoadMe.data) {
                dispatch({type: UserTypes.LOGIN_SUCCESS});
            }
        } catch (error: any) {
            dispatch({
                type: UserTypes.LOGIN_FAILURE,
                error,
            });
            return {error};
        }

        return {data: true};
    };
}

export function loadMe(initialLoad = false) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();

        const deviceId = state.entities.general.deviceToken;
        if (deviceId) {
            Client4.attachDevice(deviceId);
        }

        // Sometimes the server version is set in one or the other
        const serverVersion = Client4.getServerVersion() || getState().entities.general.serverVersion;
        dispatch(setServerVersion(serverVersion));

        try {
            if (initialLoad) {
                const rolesToPreload = [
                    General.SYSTEM_USER_ROLE,
                    General.SYSTEM_ADMIN_ROLE,
                    General.CHANNEL_USER_ROLE,
                    General.TEAM_USER_ROLE,
                    General.TEAM_ADMIN_ROLE,
                    General.CHANNEL_ADMIN_ROLE,
                ];

                await dispatch(loadRolesIfNeeded(rolesToPreload));
            }

            const promises = initialLoad ? [] : [dispatch(getClientConfig(initialLoad)), dispatch(getLicenseConfig(initialLoad))];

            promises.push(
                dispatch(getMe(initialLoad)),
                dispatch(getMyPreferences(initialLoad)),
                dispatch(getMyTeams(initialLoad)),
                dispatch(getMyTeamMembers(initialLoad)),
            );

            const errors = (await Promise.allSettled(promises))
                .filter((res): res is PromiseRejectedResult => res.status === 'rejected')
                .map((res) => res.reason);

            // Если вернулась 500 ошибка на один из запросов, то выбрасываем ее.
            const error = errors.find((err) => {
                if (isAxiosError(err)) {
                    return err.response && err.response.status >= 500;
                }

                return err.status_code && err.status_code >= 500;
            }) ?? errors[0];

            if (error) {
                throw error;
            }
        } catch (error: any) {
            if (initialLoad) {
                throw error;
            }
            return {error};
        }

        const {currentUserId} = getState().entities.users;
        if (currentUserId) {
            Client4.setUserId(currentUserId);
            await dispatch(getProfileIfAllowed(currentUserId) as unknown as GenericAction);
        }

        const user = getState().entities.users.profiles[currentUserId];

        if (user) {
            Client4.setUserRoles(user.roles);
            updateUserPropsInStatist({
                userId: user.id,
                language: user.locale,
                timezone: user.timezone?.automaticTimezone,
            });

            if (isDesktopApp()) {
                try {
                    const usedResources = await window.internalAPI?.getUsedResources();

                    if (usedResources) {
                        updateUserPropsInStatist({
                            runTime: Date.now() - usedResources.creationTime,
                            memoryAvailable: usedResources.totalMemoryKB,
                            memoryUsage: usedResources.usedMemoryKB,
                        });
                    }
                } catch (error) {
                    // опускаем ошибку так как версия десктопа может не поддерживать этот запрос
                }
            }
        }

        return {data: true};
    };
}

export function logout() {
    return async (dispatch: DispatchFunc) => {
        dispatch({type: UserTypes.LOGOUT_REQUEST, data: null});

        try {
            await Client4.logout();
        } catch (error: any) {
            // nothing to do here
        }

        dispatch({type: UserTypes.LOGOUT_SUCCESS, data: null});

        return {data: true};
    };
}

export function getTotalUsersStats(): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getTotalUsersStats,
        onSuccess: UserTypes.RECEIVED_USER_STATS,
    });
}

export function getFilteredUsersStats(options: GetFilteredUsersStatsOpts = {}): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let stats: UsersStats;
        try {
            stats = await Client4.getFilteredUsersStats(options);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: UserTypes.RECEIVED_FILTERED_USER_STATS,
            data: stats,
        });

        return {data: stats};
    };
}

export function getProfiles(page = 0, perPage: number = General.PROFILE_CHUNK_SIZE, options: any = {}): ThunkActionWithResult {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles: UserProfile[];

        try {
            profiles = await Client4.getProfiles(page, perPage, options);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        return {data: profiles};
    };
}

export function getMissingProfilesByIds(userIds: string[], fetchStatus = true): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const {profiles} = getState().entities.users;

        const userIdsUnique = uniq(userIds);

        const dmUsers = getDmUsers(getState());
        const missingStatusIds: string[] = [];

        const missingIds: string[] = [];

        userIdsUnique.forEach((id) => {
            // Sometimes we have profiles with ids only
            if (!profiles[id]?.username) {
                missingIds.push(id);
            }

            if (dmUsers.includes(id)) {
                missingStatusIds.push(id);
            }
        });

        if (missingStatusIds.length > 0 && fetchStatus) {
            dispatch(getStatusesByIds(missingStatusIds));
        }

        if (missingIds.length > 0) {
            return dispatch(fetchUsersByIdsDebounced(missingIds));
        }

        return {data: []};
    };
}

export function getMissingProfilesByUsernames(usernames: string[]): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const {profiles} = getState().entities.users;

        if (!usernames.length) {
            return {data: []};
        }

        const existingUsernamesMap = Object.values(profiles).reduce((acc, profile: UserProfile) => {
            if (profile.username) {
                acc[profile.username] = true;
            }
            return acc;
        }, {} as Record<UserProfile['username'], boolean>);

        const missingUsernames: string[] = [];

        usernames.forEach((username) => {
            if (!existingUsernamesMap[username]) {
                missingUsernames.push(username);
            }
        });

        if (missingUsernames.length) {
            return dispatch(getProfilesByUsernames(missingUsernames));
        }

        return {data: []};
    };
}

export const getProfilesByUsernames = batchAsyncDebounce((usernames: string[]) => {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;

        try {
            profiles = await Client4.getProfilesByUsernames(usernames);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }
        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        return {data: profiles};
    };
});

export function getProfilesInTeam(
    teamId: string,
    page: number,
    perPage: number = General.PROFILE_CHUNK_SIZE,
    sort = '',
    options: any = {},
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;

        try {
            profiles = await Client4.getProfilesInTeam(teamId, page, perPage, sort, options);
        } catch (error: unknown) {
            if (error instanceof ClientError && error.server_error_id === ServerErrorIds.INSUFFICIENT_PERMISSIONS) {
                dispatch(closeModal(ModalIdentifiers.TEAM_MEMBERS));
                dispatch(openInsufficientPermissionsModal('VIEW_TEAM_MEMBERS'));
            } else {
                forceLogoutIfNecessary(error, dispatch, getState);
            }

            return {error};
        }

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: UserTypes.RECEIVED_PROFILES_LIST_IN_TEAM,
            data: profiles,
            id: teamId,
        });

        return {data: profiles};
    };
}

export function getProfilesNotInTeam(
    teamId: string,
    groupConstrained: boolean,
    page: number,
    perPage: number = General.PROFILE_CHUNK_SIZE,
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;
        try {
            profiles = await Client4.getProfilesNotInTeam(teamId, groupConstrained, page, perPage);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const receivedProfilesListActionType = groupConstrained ? UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_TEAM_AND_REPLACE : UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_TEAM;

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: receivedProfilesListActionType,
            data: profiles,
            id: teamId,
        });

        return {data: profiles};
    };
}

export function getProfilesWithoutTeam(
    page: number,
    perPage: number = General.PROFILE_CHUNK_SIZE,
    options: any = {},
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles = null;
        try {
            profiles = await Client4.getProfilesWithoutTeam(page, perPage, options);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: UserTypes.RECEIVED_PROFILES_LIST_WITHOUT_TEAM,
            data: profiles,
        });

        return {data: profiles};
    };
}

export function getProfilesInChannel(
    channelId: string,
    page: number,
    perPage: number = General.PROFILE_CHUNK_SIZE,
    sort = '',
    options: {active?: boolean} = {},
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;

        try {
            profiles = await Client4.getProfilesInChannel(channelId, page, perPage, sort, options);
        } catch (error: unknown) {
            if (error instanceof ClientError && error.server_error_id === ServerErrorIds.INSUFFICIENT_PERMISSIONS) {
                dispatch(closeModal(ModalIdentifiers.CHANNEL_MEMBERS));
                dispatch(openInsufficientPermissionsModal('VIEW_CHANNEL_MEMBERS'));
            } else {
                forceLogoutIfNecessary(error, dispatch, getState);
            }

            return {error};
        }

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: UserTypes.RECEIVED_PROFILES_LIST_IN_CHANNEL,
            data: profiles,
            id: channelId,
        });

        return {data: profiles};
    };
}

export function getProfilesInGroupChannels(channelsIds: string[], fetchOptions: Partial<Options>): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let channelProfiles;

        try {
            channelProfiles = await Client4.getProfilesInGroupChannels(
                channelsIds.slice(0, General.MAX_GROUP_CHANNELS_FOR_PROFILES),
                fetchOptions,
            );
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const uniqUsers: UserProfile[] = [];
        const uniqUserIds = new Set<UserProfile['id']>();

        for (const channelId in channelProfiles) {
            if (channelProfiles.hasOwnProperty(channelId)) {
                const profiles = channelProfiles[channelId];
                if (!profiles) {
                    continue;
                }

                for (const profile of profiles) {
                    if (profile.id === currentUserId || uniqUserIds.has(profile.id)) {
                        continue;
                    }

                    uniqUsers.push(profile);
                }
            }
        }

        dispatch(receivedUsersInChannels(channelProfiles));
        await dispatch(receivedUser(uniqUsers));

        return {data: channelProfiles};
    };
}

export function getProfilesNotInChannel(
    teamId: string,
    channelId: string,
    groupConstrained: boolean,
    page: number,
    perPage: number = General.PROFILE_CHUNK_SIZE,
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;

        try {
            profiles = await Client4.getProfilesNotInChannel(teamId, channelId, groupConstrained, page, perPage);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const receivedProfilesListActionType = groupConstrained ? UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_CHANNEL_AND_REPLACE : UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_CHANNEL;

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: receivedProfilesListActionType,
            data: profiles,
            id: channelId,
        });

        return {data: profiles};
    };
}

export function getMe(pureError?: boolean): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const getMeFunc = bindClientFunc({
            clientFunc: Client4.getMe,
            onSuccess: receivedCurrentUser.type,
            pureError,
        });

        const me = await getMeFunc(dispatch, getState);

        if ('error' in me) {
            return me;
        }
        if ('data' in me) {
            dispatch(loadRolesIfNeeded(me.data.roles.split(' ')));
        }
        return me;
    };
}
export function getProfilesInGroup(
    groupId: string,
    page = 0,
    perPage: number = General.PROFILE_CHUNK_SIZE,
    replace?: boolean,
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;

        try {
            profiles = await Client4.getProfilesInGroup(groupId, page, perPage);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: UserTypes.RECEIVED_PROFILES_LIST_IN_GROUP,
            data: profiles,
            id: groupId,
            replace,
        });

        return {data: profiles};
    };
}

export function getProfilesNotInGroup(
    groupId: string,
    page = 0,
    perPage: number = General.PROFILE_CHUNK_SIZE,
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;

        try {
            profiles = await Client4.getProfilesNotInGroup(groupId, page, perPage);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        if (profiles.length) {
            await dispatch(receivedUsers(profiles.filter(({id}) => id !== currentUserId)));
        }

        dispatch({
            type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_GROUP,
            data: profiles,
            id: groupId,
        });

        return {data: profiles};
    };
}

export function getTermsOfService(): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getTermsOfService,
    });
}

export function promoteGuestToUser(userId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.promoteGuestToUser,
        params: [userId],
    });
}

export function demoteUserToGuest(userId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.demoteUserToGuest,
        params: [userId],
    });
}

export function createTermsOfService(text: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.createTermsOfService,
        params: [text],
    });
}

export function getUser(id: string): ActionFunc {
    // @TODO: Переписать с хелпера
    return bindClientFunc({
        clientFunc: Client4.getUser,
        onSuccess: receivedUser,
        params: [id],
    });
}

export function getUserByUsername(username: string): ActionFunc {
    // @TODO: Переписать с хелпера
    return bindClientFunc({
        clientFunc: Client4.getUserByUsername,
        onSuccess: receivedUser,
        params: [username],
    });
}

export function getUserByEmail(email: string): ActionFunc {
    // @TODO: Переписать с хелпера
    return bindClientFunc({
        clientFunc: Client4.getUserByEmail,
        onSuccess: receivedUser,
        params: [email],
    });
}

// We create an array to hold the id's that we want to get a status for. We build our
// debounced function that will get called after a set period of idle time in which
// the array of id's will be passed to the getStatusesByIds with a cb that clears out
// the array. Helps with performance because instead of making 75 different calls for
// statuses, we are only making one call for 75 ids.
// We could maybe clean it up somewhat by storing the array of ids in redux state possbily?
let ids: string[] = [];
const debouncedGetStatusesByIds = debounce(
    async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        getStatusesByIds([...new Set(ids)])(dispatch, getState);
    },
    20,
    false,
    () => {
        ids = [];
    },
);
export function getStatusesByIdsBatchedDebounced(id: string) {
    ids = [...ids, id];
    return debouncedGetStatusesByIds;
}

export const getStatusesByIds = batchAsyncDebounce((userIds: string[]) => {
    return bindClientFunc({
        clientFunc: Client4.getStatusesByIds,
        onSuccess: UserTypes.RECEIVED_STATUSES,
        params: [userIds],
    });
});

export function getStatus(userId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getStatus,
        onSuccess: UserTypes.RECEIVED_STATUS,
        params: [userId],
    });
}

export function setStatus(status: UserStatus): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.updateStatus(status);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: UserTypes.RECEIVED_STATUS,
            data: status,
        });

        return {data: status};
    };
}

export function setCustomStatus(customStatus: UserCustomStatus): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.updateCustomStatus,
        params: [customStatus],
    });
}

export function unsetCustomStatus(): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.unsetCustomStatus,
    });
}

export function removeRecentCustomStatus(customStatus: UserCustomStatus): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.removeRecentCustomStatus,
        params: [customStatus],
    });
}

export function getSessions(userId: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getSessions,
        onSuccess: UserTypes.RECEIVED_SESSIONS,
        params: [userId],
    });
}

export function revokeSession(userId: string, sessionId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.revokeSession(userId, sessionId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: UserTypes.RECEIVED_REVOKED_SESSION,
            sessionId,
            data: null,
        });

        return {data: true};
    };
}

export function revokeAllSessionsForUser(userId: string) {
    return async (dispatch: DispatchFunc, getState: GetStateFunc): Promise<ActionResult> => {
        try {
            await Client4.revokeAllSessionsForUser(userId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }
        const data = {isCurrentUser: userId === getCurrentUserId(getState())};
        dispatch(
            batchActions([
                {
                    type: UserTypes.REVOKE_ALL_USER_SESSIONS_SUCCESS,
                    data,
                },
            ]),
        );

        return {data: true};
    };
}

export function revokeSessionsForAllUsers(): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.revokeSessionsForAllUsers();
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }
        dispatch({
            type: UserTypes.REVOKE_SESSIONS_FOR_ALL_USERS_SUCCESS,
            data: null,
        });
        return {data: true};
    };
}

export function getUserAudits(userId: string, page = 0, perPage: number = General.AUDITS_CHUNK_SIZE): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getUserAudits,
        onSuccess: UserTypes.RECEIVED_AUDITS,
        params: [userId, page, perPage],
    });
}

export function autocompleteUsers(
    term: string,
    teamId = '',
    channelId = '',
    options: {
        limit?: number;
        boost?: boolean;
    },
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        dispatch({type: UserTypes.AUTOCOMPLETE_USERS_REQUEST, data: null});

        let data;
        try {
            data = await Client4.autocompleteUsers(term, teamId, channelId, options);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            dispatch({type: UserTypes.AUTOCOMPLETE_USERS_FAILURE, error});
            return {error};
        }

        let users = [...data.users];
        if (data.out_of_channel) {
            users = [...users, ...data.out_of_channel];
        }

        const actions: AnyAction[] = [
            {
                type: UserTypes.AUTOCOMPLETE_USERS_SUCCESS,
            },
            receivedUsers(users.filter(({id}) => id !== currentUserId)),
        ];

        if (channelId) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_IN_CHANNEL,
                data: data.users,
                id: channelId,
            });
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_CHANNEL,
                data: data.out_of_channel || [],
                id: channelId,
            });
        }

        if (teamId) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_IN_TEAM,
                data: users,
                id: teamId,
            });
        }

        await Promise.all(actions.map((action: any) => dispatch(action)));

        return {data};
    };
}

export function searchProfiles(term: string, options: any = {}, fetchOptions: Partial<Options> = {}): ThunkActionWithResult {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        let profiles;
        try {
            profiles = await Client4.searchUsers(term, options, fetchOptions);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const actions: AnyAction[] = [receivedUsers(profiles.filter(({id}) => id !== currentUserId))];

        if (options.in_channel_id) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_IN_CHANNEL,
                data: profiles,
                id: options.in_channel_id,
            });
        }

        if (options.not_in_channel_id) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_CHANNEL,
                data: profiles,
                id: options.not_in_channel_id,
            });
        }

        if (options.team_id) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_IN_TEAM,
                data: profiles,
                id: options.team_id,
            });
        }

        if (options.not_in_team_id) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_TEAM,
                data: profiles,
                id: options.not_in_team_id,
            });
        }

        if (options.in_group_id) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_IN_GROUP,
                data: profiles,
                id: options.in_group_id,
            });
        }

        if (options.not_in_group_id) {
            actions.push({
                type: UserTypes.RECEIVED_PROFILES_LIST_NOT_IN_GROUP,
                data: profiles,
                id: options.not_in_group_id,
            });
        }

        await Promise.all(actions.map((action: any) => dispatch(action)));

        return {data: profiles};
    };
}

export function updateMe(user: UserProfile): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        dispatch({type: UserTypes.UPDATE_ME_REQUEST, data: null});

        let data;
        try {
            data = await Client4.patchMe(user);
        } catch (error: any) {
            dispatch({type: UserTypes.UPDATE_ME_FAILURE, error});
            return {error};
        }

        if (data) {
            dispatch(batchActions([receivedCurrentUser(data), {type: UserTypes.UPDATE_ME_SUCCESS}]));
            dispatch(loadRolesIfNeeded(data.roles.split(' ')));
        }

        return {data};
    };
}

export function patchUser(user: UserProfile): ActionFunc {
    return async (dispatch: DispatchFunc) => {
        let data: UserProfile;
        try {
            data = await Client4.patchUser(user);
        } catch (error: any) {
            return {error};
        }

        await dispatch(receivedUser(data));

        return {data};
    };
}

export function updateUserRoles(userId: string, roles: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.updateUserRoles(userId, roles);
        } catch (error: any) {
            return {error};
        }

        const profile = getState().entities.users.profiles[userId];
        if (profile) {
            await dispatch(receivedUser({...profile, roles}));
        }

        return {data: true};
    };
}

export function updateUserMfa(userId: string, activate: boolean, code = ''): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.updateUserMfa(userId, activate, code);
        } catch (error: any) {
            return {error};
        }

        const profile = getState().entities.users.profiles[userId];
        if (profile) {
            await dispatch(receivedUser({...profile, mfa_active: activate}));
        }

        return {data: true};
    };
}

export function updateUserPassword(userId: string, currentPassword: string, newPassword: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.updateUserPassword(userId, currentPassword, newPassword);
        } catch (error: any) {
            return {error};
        }

        const profile = getState().entities.users.profiles[userId];
        if (profile) {
            await dispatch(receivedUser({...profile, last_password_update_at: new Date().getTime()}));
        }

        return {data: true};
    };
}

export function updateUserActive(userId: string, active: boolean): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.updateUserActive(userId, active);
        } catch (error: any) {
            return {error};
        }

        const profile = getState().entities.users.profiles[userId];
        if (profile) {
            const deleteAt = active ? 0 : new Date().getTime();
            await dispatch(receivedUser({...profile, delete_at: deleteAt}));
        }

        return {data: true};
    };
}

export function verifyUserEmail(token: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.verifyUserEmail,
        params: [token],
    });
}

export function sendVerificationEmail(email: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.sendVerificationEmail,
        params: [email],
    });
}

export function resetUserPassword(token: string, newPassword: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.resetUserPassword,
        params: [token, newPassword],
    });
}

export function sendPasswordResetEmail(email: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.sendPasswordResetEmail,
        params: [email],
    });
}

export function setDefaultProfileImage(userId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.setDefaultProfileImage(userId);
        } catch (error: any) {
            return {error};
        }

        const profile = getState().entities.users.profiles[userId];

        if (profile) {
            await dispatch(receivedUser({...profile, last_picture_update: 0}));
        }

        return {data: true};
    };
}

export function uploadProfileImage(userId: string, imageData: any): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.uploadProfileImage(userId, imageData);
        } catch (error: any) {
            return {error};
        }

        const profile = getState().entities.users.profiles[userId];
        if (profile) {
            await dispatch(receivedUser({...profile, last_picture_update: new Date().getTime()}));
        }

        return {data: true};
    };
}

export function switchEmailToOAuth(service: string, email: string, password: string, mfaCode = ''): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.switchEmailToOAuth,
        params: [service, email, password, mfaCode],
    });
}

export function switchOAuthToEmail(currentService: string, email: string, password: string): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.switchOAuthToEmail,
        params: [currentService, email, password],
    });
}

export function switchEmailToLdap(
    email: string,
    emailPassword: string,
    ldapId: string,
    ldapPassword: string,
    mfaCode = '',
): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.switchEmailToLdap,
        params: [email, emailPassword, ldapId, ldapPassword, mfaCode],
    });
}

export function switchLdapToEmail(
    ldapPassword: string,
    email: string,
    emailPassword: string,
    mfaCode = '',
): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.switchLdapToEmail,
        params: [ldapPassword, email, emailPassword, mfaCode],
    });
}

export function createUserAccessToken(userId: string, description: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;

        try {
            data = await Client4.createUserAccessToken(userId, description);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const actions: AnyAction[] = [
            {
                type: AdminTypes.RECEIVED_USER_ACCESS_TOKEN,
                data: {...data, token: ''},
            },
        ];

        const {currentUserId} = getState().entities.users;
        if (userId === currentUserId) {
            actions.push({
                type: UserTypes.RECEIVED_MY_USER_ACCESS_TOKEN,
                data: {...data, token: ''},
            });
        }

        dispatch(batchActions(actions));

        return {data};
    };
}

export function getUserAccessToken(tokenId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;
        try {
            data = await Client4.getUserAccessToken(tokenId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const actions: AnyAction[] = [
            {
                type: AdminTypes.RECEIVED_USER_ACCESS_TOKEN,
                data,
            },
        ];

        const {currentUserId} = getState().entities.users;
        if (data.user_id === currentUserId) {
            actions.push({
                type: UserTypes.RECEIVED_MY_USER_ACCESS_TOKEN,
                data,
            });
        }

        dispatch(batchActions(actions));

        return {data};
    };
}

export function getUserAccessTokens(page = 0, perPage: number = General.PROFILE_CHUNK_SIZE): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;

        try {
            data = await Client4.getUserAccessTokens(page, perPage);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: AdminTypes.RECEIVED_USER_ACCESS_TOKENS,
            data,
        });

        return {data};
    };
}

export function getUserAccessTokensForUser(
    userId: string,
    page = 0,
    perPage: number = General.PROFILE_CHUNK_SIZE,
): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        let data;
        try {
            data = await Client4.getUserAccessTokensForUser(userId, page, perPage);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        const actions: AnyAction[] = [
            {
                type: AdminTypes.RECEIVED_USER_ACCESS_TOKENS_FOR_USER,
                data,
                userId,
            },
        ];
        const state = getState();
        const currentUserId = getCurrentUserId(state);

        if (userId === currentUserId) {
            actions.push({
                type: UserTypes.RECEIVED_MY_USER_ACCESS_TOKENS,
                data,
            });
        }

        dispatch(batchActions(actions));

        return {data};
    };
}

export function revokeUserAccessToken(tokenId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.revokeUserAccessToken(tokenId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: UserTypes.REVOKED_USER_ACCESS_TOKEN,
            data: tokenId,
        });

        return {data: true};
    };
}

export function disableUserAccessToken(tokenId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.disableUserAccessToken(tokenId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: UserTypes.DISABLED_USER_ACCESS_TOKEN,
            data: tokenId,
        });

        return {data: true};
    };
}

export function enableUserAccessToken(tokenId: string): ActionFunc {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        try {
            await Client4.enableUserAccessToken(tokenId);
        } catch (error: any) {
            forceLogoutIfNecessary(error, dispatch, getState);
            return {error};
        }

        dispatch({
            type: UserTypes.ENABLED_USER_ACCESS_TOKEN,
            data: tokenId,
        });

        return {data: true};
    };
}

export function getKnownUsers(): ActionFunc {
    return bindClientFunc({
        clientFunc: Client4.getKnownUsers,
    });
}

export function clearUserAccessTokens(): ActionFunc {
    return async (dispatch) => {
        dispatch({type: UserTypes.CLEAR_MY_USER_ACCESS_TOKENS, data: null});
        return {data: true};
    };
}

export function checkForModifiedUsers() {
    return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
        const state = getState();
        const users = getUsers(state);
        const lastDisconnectAt = state.websocket.lastDisconnectAt;
        const serverVersion = getServerVersion(state);

        if (!isMinimumServerVersion(serverVersion, 5, 14)) {
            return {data: true};
        }

        await dispatch(fetchUsersByIds({
            userIds: Object.keys(users),
            since: lastDisconnectAt,
        }));

        return {data: true};
    };
}

export default {
    checkMfa,
    generateMfaSecret,
    login,
    logout,
    getProfiles,
    getProfilesInTeam,
    getProfilesInChannel,
    getProfilesNotInChannel,
    getUser,
    getMe,
    getUserByUsername,
    getStatus,
    getStatusesByIds,
    getSessions,
    getTotalUsersStats,
    revokeSession,
    revokeAllSessionsForUser,
    revokeSessionsForAllUsers,
    getUserAudits,
    searchProfiles,
    updateMe,
    updateUserRoles,
    updateUserMfa,
    updateUserPassword,
    updateUserActive,
    verifyUserEmail,
    sendVerificationEmail,
    resetUserPassword,
    sendPasswordResetEmail,
    uploadProfileImage,
    switchEmailToOAuth,
    switchOAuthToEmail,
    switchEmailToLdap,
    switchLdapToEmail,
    getTermsOfService,
    createTermsOfService,
    createUserAccessToken,
    getUserAccessToken,
    getUserAccessTokensForUser,
    revokeUserAccessToken,
    disableUserAccessToken,
    enableUserAccessToken,
    checkForModifiedUsers,
};
