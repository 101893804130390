import {useIntl} from 'react-intl';

import {LockScreenLayout} from '@time-webkit/all/molecules/lock-screen-layout';

import {BackButton} from 'components/back_button';

import {SIGNIN_ROUTE} from 'utils/route';
import {browserHistory} from 'utils/browser_history';

export const LockScreenForNoLicensesToSignIn = () => {
    const {formatMessage} = useIntl();

    return (
        <LockScreenLayout
            icon={<span>&#128274;</span>}
            heading={formatMessage({
                id: 'error.license.noLicense_to_sign_in.title',
                defaultMessage: 'Failed to sign in',
            })}
            body={formatMessage({
                id: 'error.license.noLicense_to_sign_in.description',
                defaultMessage: 'No licenses available — contact support service',
            })}
            leftTopActionButton={<BackButton onPress={() => browserHistory.push(SIGNIN_ROUTE)} />}
        />
    );
};
