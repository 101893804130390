import {type AxiosInstance, isAxiosError} from 'axios';
import {handleHttpError} from 'packages/http/src';

export function addHttpErrorInterceptor(axios: AxiosInstance) {
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (isAxiosError(error) && error.response) {
                handleHttpError({
                    status: error.response.status,
                    message: error.message,
                    responseData: error.response.data,
                    url: error.response.config?.url,
                    method: error.config?.method,
                });
            }
            return Promise.reject(error);
        },
    );
}
