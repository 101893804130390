import {createAsyncThunk} from '@reduxjs/toolkit';

import omit from '@tinkoff/utils/object/omit';

import type {AxiosError} from 'axios';

import type {ChannelCategory} from '@mattermost/types/channel_categories';
import type {AppDispatch} from 'stores/redux_store';
import {Client4} from 'mattermost-redux/client';
import {forceLogoutIfNecessaryThunkAxios} from 'mattermost-redux/actions/helpers';

type Payload = {

    /**
     * Team GUID
     */
    teamId: string;

    /**
     * User GUID
     */
    userId: string;
    categories: ChannelCategory[];
};

/**
 * Update any number of sidebar categories for the user on the given team.
 * This can be used to reorder the channels in these categories.
 *
 * Must be authenticated and have the `list_team_channels` permission.
 *
 * @see https://messenger.pages.devplatform.tcsbank.ru/time-api-reference/#operation/UpdateSidebarCategoriesForTeamForUser
 * @example /api/v4/users/{user_id}/teams/{team_id}/channels/categories
 */
export const updateSidebarCategoriesForTeamForUser = createAsyncThunk(
    'sidebar/api/updateSidebarCategoriesForTeamForUser',
    async (payload: Payload, thunkAPI) => {
        const {userId, teamId, categories} = payload;
        const dispatch = thunkAPI.dispatch as AppDispatch;
        const requester = Client4.requester;
        const options = omit(['url'], Client4.getTimeApiClientOptions({}));

        const url = `${Client4.getUrl()}/api/v4/users/${userId}/teams/${teamId}/channels/categories`;

        try {
            const {data} = await requester.put<ChannelCategory[]>(url, categories, {
                ...options,
                id: 'updateSidebarCategoriesForTeamForUser',
                cache: false,
                'axios-retry': {
                    retries: 3,
                    retryDelay: (count: number) => count * 100,
                },
                timeout: 3 * 1000,
            });

            return data;
        } catch (e: any) {
            const error: AxiosError = e;

            dispatch(forceLogoutIfNecessaryThunkAxios(error));

            throw error;
        }
    },
);
