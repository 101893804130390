import {connect} from 'react-redux';
import {type ActionCreatorsMapObject, bindActionCreators, type Dispatch} from 'redux';

import {
    getCurrentUserId,
    getStatusForUserId,
    getUser,
    getUserRoleTitle,
} from 'mattermost-redux/selectors/entities/users';
import {getCurrentTeam, getCurrentRelativeTeamUrl} from 'mattermost-redux/selectors/entities/teams';
import {getChannelMembersInChannels, getCurrentChannelId} from 'mattermost-redux/selectors/entities/channels';

import {openDirectChannelToUserId} from 'actions/channel_actions';
import {getMembershipForEntities} from 'actions/views/profile_popover';
import {closeModal, openModal} from 'actions/views/modals';
import {showChannelInfo, showUserInfo} from 'actions/views/rhs';

import {getRhsState, getSelectedPost} from 'selectors/rhs';
import {getIsMobileView} from 'selectors/views/browser';

import {makeGetCustomStatus} from 'selectors/views/custom_status';
import {getChannelByName} from 'mattermost-redux/actions/channels';
import {type Action} from 'mattermost-redux/types/actions';

import {type ModalData} from 'types/actions';
import {type GlobalState} from 'types/store';

import {type ServerError} from 'mattermost-redux/types/errors';

import {localizeMessage} from 'mattermost-redux/utils/i18n_utils';

import {getDisplayNameByUser} from 'utils/utils';

import {getStatusesByIds} from 'mattermost-redux/actions/users';

import {getProfileIfAllowed, getProfileByUserId} from 'features/profiles';
import {makeCanOpenDirectChannelSelector} from 'features/channels/selectors/can_open_direct_channel';

import {isGuest} from 'mattermost-redux/utils/user_utils';

import ProfilePopover from './profile_popover';

type OwnProps = {
    userId: string;
    channelId?: string;
};

function getDefaultChannelId(state: GlobalState) {
    const selectedPost = getSelectedPost(state);
    return selectedPost.exists ? selectedPost.channel_id : getCurrentChannelId(state);
}

function makeMapStateToProps() {
    const getCustomStatus = makeGetCustomStatus();
    const canOpenDirectChannel = makeCanOpenDirectChannelSelector();

    return (state: GlobalState, {userId, channelId = getDefaultChannelId(state)}: OwnProps) => {
        const team = getCurrentTeam(state);
        const currentUserId = getCurrentUserId(state);
        const currentUser = getUser(state, currentUserId);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        let userRoleTitle = getUserRoleTitle(state, userId, {ignoreBot: true, ignoreGuest: true});
        if (!userRoleTitle) {
            const channelMember = getChannelMembersInChannels(state)?.[channelId]?.[userId];
            if (channelMember?.scheme_admin && getRhsState(state) !== 'search') {
                userRoleTitle = localizeMessage('admin.permissions.roles.channel_admin.name', 'Channel Admin');
            }
        }

        const customStatus = getCustomStatus(state, userId);
        const user = getUser(state, userId);
        const profile = getProfileByUserId(state, userId);

        return {
            canAddUserToChannel: !isGuest(currentUser?.roles),
            canOpenDirectChannel: canOpenDirectChannel(state, user?.id),
            displayName: getDisplayNameByUser(state, user),
            currentTeamId: team?.id,
            currentUserId,
            userRoleTitle,
            status: getStatusForUserId(state, userId),
            teamUrl: getCurrentRelativeTeamUrl(state),
            user,
            profile,
            modals: state.views.modals,
            customStatus,
            channelId,
            isMobileView: getIsMobileView(state),
        };
    };
}

type Actions = {
    openModal: <P>(modalData: ModalData<P>) => void;
    closeModal: (modalId: string) => void;
    openDirectChannelToUserId: (userId?: string) => Promise<{error: ServerError}>;
    getMembershipForEntities: (teamId: string, userId: string, channelId?: string) => Promise<void>;
};

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        actions: bindActionCreators<ActionCreatorsMapObject<Action | any>, Actions>(
            {
                closeModal,
                openDirectChannelToUserId,
                openModal,
                getMembershipForEntities,
                showChannelInfo,
                showUserInfo,
                getChannelByName,
                getStatusesByIds,
                getProfileIfAllowed,
            },
            dispatch,
        ),
    };
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connect(makeMapStateToProps, mapDispatchToProps)(ProfilePopover);
