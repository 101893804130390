import React from 'react';
import {Modal} from 'react-bootstrap';
import {FormattedMessage} from 'react-intl';
import debounce from 'lodash/debounce';

import {Client4} from 'mattermost-redux/client';
import {type RelationOneToOne} from 'mattermost-redux/types/utilities';
import {type ActionResult} from 'mattermost-redux/types/actions';
import {type Channel} from 'mattermost-redux/types/channels';
import {ClientError} from 'mattermost-redux/client/client4';
import {type UserProfile} from 'mattermost-redux/types/users';

import {filterProfilesStartingWithTerm, isGuest, isGuestPlus} from 'mattermost-redux/utils/user_utils';
import {displayEntireNameForUser, localizeMessage, localizeAndFormatMessage} from 'utils/utils';
import ProfilePicture from 'components/profile_picture';
import MultiSelect, {type Value} from 'components/multiselect/multiselect';
import AddIcon from 'components/widgets/icons/fa_add_icon';
import GuestPlusBadge from 'components/widgets/badges/guest_plus_badge';
import GuestBadge from 'components/widgets/badges/guest_badge';
import BotBadge from 'components/widgets/badges/bot_badge';
import ToggleModalButton from 'components/toggle_modal_button';

import Constants, {ModalIdentifiers, ServerErrorIds} from 'utils/constants';
import {SearchTimeMeasurer, sendToStatist} from '@time-webkit/statist';
import {InviteMembersModal} from '../invite_members_modal';
import {Alert, AlertType} from '@time-webkit/all/atoms/alert';

import './channel_invite_modal.css';

const USERS_PER_PAGE = 50;

const searchTimeMeasurer = new SearchTimeMeasurer('channel.membersAdd.result');

type UserProfileValue = Value & UserProfile;

export type Props = {
    profilesNotInCurrentChannel: UserProfileValue[];
    profilesNotInCurrentTeam: UserProfileValue[];
    userStatuses: RelationOneToOne<UserProfile, string>;
    onExited: () => void;
    channel: Channel;

    // skipCommit = true used with onAddCallback will result in users not being committed immediately
    skipCommit?: boolean;

    // onAddCallback takes an array of UserProfiles and should set usersToAdd in state of parent component
    onAddCallback?: (userProfiles?: UserProfileValue[]) => void;

    // Dictionaries of userid mapped users to exclude or include from this list
    excludeUsers?: Record<string, UserProfileValue>;
    includeUsers?: Record<string, UserProfileValue>;

    canInviteGuests?: boolean;
    emailInvitationsEnabled?: boolean;
    actions: {
        addUsersToChannel: (channelId: string, userIds: string[]) => Promise<ActionResult>;
        getProfilesNotInChannel: (teamId: string, channelId: string, groupConstrained: boolean, page: number, perPage?: number) => Promise<ActionResult>;
        getTeamStats: (teamId: string) => void;
        loadStatusesForProfilesList: (users: UserProfile[]) => void;
        searchProfiles: (term: string, options: any) => Promise<ActionResult>;
        closeModal: (modalId: string) => void;
        openInsufficientPermissionsModal: (value: 'ADD_TO_CHANNEL') => void;
    };
}

type State = {
    values: UserProfileValue[];
    term: string;
    show: boolean;
    saving: boolean;
    loadingUsers: boolean;
    inviteError?: string;
    guestsWithLimit: UserProfileValue[];
}

export default class ChannelInviteModal extends React.PureComponent<Props, State> {
    private selectedItemRef = React.createRef<HTMLDivElement>();

    public static defaultProps = {
        includeUsers: {},
        excludeUsers: {},
        skipCommit: false,
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            values: [],
            term: '',
            show: true,
            saving: false,
            loadingUsers: true,
            guestsWithLimit: [],
        } as State;
    }

    private addValue = (value: UserProfileValue, number: number): void => {
        const values: UserProfileValue[] = Object.assign([], this.state.values);
        if (values.indexOf(value) === -1) {
            values.push(value);
        }

        sendToStatist('channel.membersAdd.resultTap', {
            resultNumber: number + 1,
            addType: 'direct',
            channelid: this.props.channel.id,
        });

        this.setState({values});
    };

    public componentDidMount(): void {
        this.props.actions.getProfilesNotInChannel(this.props.channel.team_id, this.props.channel.id, this.props.channel.group_constrained, 0).then(() => {
            this.setUsersLoadingState(false);
        });
        this.props.actions.getTeamStats(this.props.channel.team_id);
        this.props.actions.loadStatusesForProfilesList(this.props.profilesNotInCurrentChannel);
    }

    public onHide = (): void => {
        this.setState({show: false});
        this.props.actions.loadStatusesForProfilesList(this.props.profilesNotInCurrentChannel);
    };

    public handleInviteError = (err: any): void => {
        if (err) {
            this.setState({
                saving: false,
                inviteError: err.message,
            });
        }
    };

    private handleDelete = (values: UserProfileValue[]): void => {
        const guestsWithLimit = values.filter((value) => this.state.guestsWithLimit.includes(value));
        this.setState({
            values,
            guestsWithLimit,
        });
    };

    private setUsersLoadingState = (loadingState: boolean): void => {
        this.setState({
            loadingUsers: loadingState,
        });
    };

    private handlePageChange = (page: number, prevPage: number): void => {
        if (page > prevPage) {
            this.setUsersLoadingState(true);
            this.props.actions.getProfilesNotInChannel(
                this.props.channel.team_id,
                this.props.channel.id,
                this.props.channel.group_constrained,
                page + 1, USERS_PER_PAGE).then(() => this.setUsersLoadingState(false));
        }
    };

    public handleSubmit = async (): Promise<void> => {
        const {actions, channel} = this.props;

        const userIds = this.state.values.map((v) => v.id);
        if (userIds.length === 0) {
            return;
        }

        const guestsToCheckPossibility = this.state.values.filter((value) => isGuest(value.roles));
        if (guestsToCheckPossibility.length > 0) {
            const guestsCheck = await Promise.all(
                guestsToCheckPossibility.map((guest) => Client4.getUserAddChannelPossibility(guest.id)
                    .then(({status}) => (status ? null : guest))
                    .catch(() => guest)),
            );

            const guestsWithLimit = guestsCheck.filter((guest): guest is UserProfileValue => Boolean(guest));

            if (guestsWithLimit.length > 0) {
                this.setState({guestsWithLimit});
                return;
            }
        }

        if (this.props.skipCommit && this.props.onAddCallback) {
            this.props.onAddCallback(this.state.values);
            this.setState({
                saving: false,
                inviteError: undefined,
            });
            this.onHide();
            return;
        }

        this.setState({saving: true});

        actions.addUsersToChannel(channel.id, userIds).then((result: any) => {
            if (result.error) {
                this.handleInviteError(result.error);
            } else {
                this.setState({
                    saving: false,
                    inviteError: undefined,
                });
                this.onHide();
            }
        }).catch((error: unknown) => {
            if (error instanceof ClientError && error.server_error_id === ServerErrorIds.INSUFFICIENT_PERMISSIONS) {
                this.props.actions.closeModal(ModalIdentifiers.CHANNEL_INVITE);
                this.props.actions.openInsufficientPermissionsModal('ADD_TO_CHANNEL');
            }
        });
    };

    private search = debounce(async (term: string) => {
        if (!term) {
            this.setUsersLoadingState(false);
            return;
        }

        searchTimeMeasurer.measure(term);

        const options = {
            team_id: this.props.channel.team_id,
            not_in_channel_id: this.props.channel.id,
            group_constrained: this.props.channel.group_constrained,
        };

        await this.props.actions.searchProfiles(term, options);

        searchTimeMeasurer.measure(term, this.getUsers().length, {channelid: this.props.channel.id});

        this.setUsersLoadingState(false);
    }, Constants.SEARCH_TIMEOUT_MILLISECONDS);

    public handleInput = (searchTerm: string): void => {
        const term = searchTerm.trim();

        this.setState({
            term,
        });
        this.setUsersLoadingState(true);

        this.search(term);
    };

    private renderAriaLabel = (option: UserProfileValue): string => {
        if (!option) {
            return '';
        }
        return option.username;
    }

    renderOption = (option: UserProfileValue, isSelected: boolean, onAdd: (user: UserProfileValue) => void, onMouseMove: (user: UserProfileValue) => void) => {
        let rowSelected = '';
        if (isSelected) {
            rowSelected = 'more-modal__row--selected';
        }

        return (
            <div
                key={option.id}
                ref={isSelected ? this.selectedItemRef : option.id}
                className={'more-modal__row clickable ' + rowSelected}
                onClick={() => onAdd(option)}
                onMouseMove={() => onMouseMove(option)}
            >
                <ProfilePicture
                    src={Client4.getProfilePictureUrl(option.id, option.last_picture_update)}
                    status={this.props.userStatuses[option.id]}
                    size={36}
                    userId={option.id}
                    username={option.username}
                />
                <div className='more-modal__details'>
                    <div className='more-modal__name'>
                        {displayEntireNameForUser(option)}
                        <BotBadge
                            show={Boolean(option.is_bot)}
                            className='badge-popoverlist'
                        />
                        <GuestPlusBadge
                            show={isGuestPlus(option.roles)}
                            className='popoverlist'
                        />
                        <GuestBadge
                            show={isGuest(option.roles)}
                            className='popoverlist'
                        />
                    </div>
                </div>
                <div className='more-modal__actions'>
                    <div className='more-modal__actions--round'>
                        <AddIcon/>
                    </div>
                </div>
            </div>
        );
    };

    getUsers() {
        return filterProfilesStartingWithTerm(this.props.profilesNotInCurrentChannel, this.state.term).filter((user) => {
            return user.delete_at === 0 &&
                !this.props.profilesNotInCurrentTeam.includes(user as UserProfileValue) &&
                (this.props.excludeUsers !== undefined && !this.props.excludeUsers[user.id]);
        }).map((user) => user as UserProfileValue);
    }

    renderErrorAlert() {
        if (this.state.guestsWithLimit.length === 0) {
            return null;
        }

        return (
            <Alert
                className='limit-alert-invite'
                size='medium'
                type={AlertType.Danger}
                message={localizeMessage(
                    'channel_invite.limit_error.title',
                    'Failed to add',
                )}
                secondaryMessage={localizeAndFormatMessage(
                    'channel_invite.limit_error.desc',
                    'The channel limit for the guest has been reached at {members}',
                    {members: this.state.guestsWithLimit.map((user) => '@' + user.username).join(', ')},
                )}
            />
        );
    }

    render() {
        let inviteError = null;
        if (this.state.inviteError) {
            inviteError = (<label className='has-error control-label'>{this.state.inviteError}</label>);
        }

        const header = (
            <h1>
                <FormattedMessage
                    id='channel_invite.addNewMembers'
                    defaultMessage='Add people to {channel}'
                    values={{
                        channel: this.props.channel.display_name,
                    }}
                />
            </h1>
        );

        const buttonSubmitText = localizeMessage('multiselect.add', 'Add');
        const buttonSubmitLoadingText = localizeMessage('multiselect.adding', 'Adding...');

        let users = this.getUsers();

        if (this.props.includeUsers) {
            const includeUsers = Object.values(this.props.includeUsers);
            users = [...users, ...includeUsers];
        }

        const closeMembersInviteModal = () => {
            this.props.actions.closeModal(ModalIdentifiers.CHANNEL_INVITE);
        };

        const InviteModalLink = ({
            children,
            inviteAsGuest,
        }: {children: React.ReactNode; inviteAsGuest?: boolean}) => {
            return (
                <ToggleModalButton
                    id='inviteGuest'
                    className={`${inviteAsGuest ? 'invite-as-guest' : ''} btn btn-link`}
                    modalId={ModalIdentifiers.INVITE_MEMBERS}
                    dialogType={InviteMembersModal}
                    dialogProps={{
                        initialChannels: [this.props.channel],
                        role: 'team_guest',
                    }}
                    onClick={closeMembersInviteModal}
                >
                    {children}
                </ToggleModalButton>
            );
        };

        const customNoOptionsMessage = (
            <div className='custom-no-options-message'>
                <FormattedMessage
                    id='channel_invite.no_options_message'
                    defaultMessage='No matches found - <InvitationModalLink>Invite them to the team</InvitationModalLink>'
                    values={{
                        InvitationModalLink: (chunks: string) => (
                            <InviteModalLink>
                                {chunks}
                            </InviteModalLink>
                        ),
                    }}
                />
            </div>
        );

        const content = (
            <MultiSelect
                key='addUsersToChannelKey'
                options={users}
                optionRenderer={this.renderOption}
                selectedItemRef={this.selectedItemRef}
                values={this.state.values}
                ariaLabelRenderer={this.renderAriaLabel}
                saveButtonPosition={'bottom'}
                perPage={USERS_PER_PAGE}
                handlePageChange={this.handlePageChange}
                handleInput={this.handleInput}
                handleDelete={this.handleDelete}
                handleAdd={this.addValue}
                handleSubmit={this.handleSubmit}
                handleCancel={closeMembersInviteModal}
                buttonSubmitText={buttonSubmitText}
                buttonSubmitLoadingText={buttonSubmitLoadingText}
                saving={this.state.saving}
                loading={this.state.loadingUsers}
                placeholderText={localizeMessage('multiselect.placeholder', 'Search for people')}
                valueWithImage={true}
                backButtonText={localizeMessage('multiselect.cancel', 'Cancel')}
                backButtonClick={closeMembersInviteModal}
                backButtonClass={'btn-cancel tertiary-button'}
                customNoOptionsMessage={this.props.emailInvitationsEnabled ? customNoOptionsMessage : null}
            />
        );

        const inviteGuestLink = (
            <InviteModalLink inviteAsGuest={true}>
                <FormattedMessage
                    id='channel_invite.invite_guest'
                    defaultMessage='Invite as a Guest +'
                />
            </InviteModalLink>
        );

        return (
            <Modal
                id='addUsersToChannelModal'
                dialogClassName='a11y__modal channel-invite'
                show={this.state.show}
                onHide={this.onHide}
                onExited={this.props.onExited}
                role='dialog'
                aria-labelledby='channelInviteModalLabel'
            >
                <Modal.Header
                    id='channelInviteModalLabel'
                    closeButton={true}
                />
                <Modal.Body
                    role='application'
                    className='overflow--visible'
                >
                    <div className='channel-invite__header'>
                        {header}
                    </div>
                    {inviteError}
                    <div className='channel-invite__content'>
                        {content}
                        {this.renderErrorAlert()}
                        {(this.props.emailInvitationsEnabled && this.props.canInviteGuests) && inviteGuestLink}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
