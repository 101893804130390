import './entry.js';

import {createRoot} from 'react-dom/client';

import {initialize as initPerf10t} from '@time-webkit/performance';

import {getLogErrorAction} from 'mattermost-redux/actions/errors';

// Import our styles
import 'sass/styles.scss';
import 'katex/dist/katex.min.css';

import '@mattermost/compass-icons/css/compass-icons.css';

import {isDevMode, setCSRFFromCookie} from 'utils/utils';
import {AnnouncementBarTypes} from 'utils/constants';
import store from 'stores/redux_store';
import App from 'components/app';

import {initialize as initStatist, updateUserPropsInStatist} from '@time-webkit/statist';

import {mark, Marks} from 'packages/performance';

import {pingApiOnStartup} from './utils/offline-ping';
import {isDesktopApp} from './utils/user_agent';

// eslint-disable-next-line no-process-env
const isProd = process.env.NODE_ENV === 'production';

initPerf10t();

mark(Marks.APP_LOADING_STARTED);

initStatist();

setUserProperties();

// This is for anything that needs to be done for ALL react components.
// This runs before we start to render anything.
function preRenderSetup(callwhendone) {
    window.addEventListener('error', (event) => {
        const msg = event.message;
        const line = event.lineno;
        const column = event.colno;

        // Игнорируем ошибки ResizeObserver
        // https://github.com/adobe/react-spectrum/issues/1924
        if (msg === 'ResizeObserver loop limit exceeded' || msg === 'ResizeObserver loop completed with undelivered notifications.') {
            return;
        }

        if (isDevMode()) {
            store.dispatch(
                getLogErrorAction(
                    {
                        type: AnnouncementBarTypes.DEVELOPER,
                        message:
                            'A JavaScript error in the webapp client has occurred. (msg: ' +
                            msg +
                            ', row: ' +
                            line +
                            ', col: ' +
                            column +
                            ').',
                    },
                    true,
                ),
            );
        }
    });
    setCSRFFromCookie();
    callwhendone();
}

function renderRootComponent() {
    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(<App />);
}

/**
 * Adds a function to be invoked onload appended to any existing onload
 * event handlers.
 *
 * @param   {function} fn onload event handler
 *
 */
function appendOnLoadEvent(fn) {
    if (window.attachEvent) {
        window.attachEvent('onload', fn);
    } else if (window.onload) {
        const curronload = window.onload;
        window.onload = (evt) => {
            curronload(evt);
            fn(evt);
        };
    } else {
        window.onload = fn;
    }
}

appendOnLoadEvent(() => {
    // Do the pre-render setup and call renderRootComponent when done
    preRenderSetup(renderRootComponent);
});

appendOnLoadEvent(() => {
    pingApiOnStartup();
});

if (isProd && 'serviceWorker' in navigator) {
    // WARNING: Service Worker requires redirect from /service-worker.js to /static/service-worker.js on backend
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').catch(() => {
            /* ignore */
        });
    });
}

async function setUserProperties() {
    updateUserPropsInStatist({
        // eslint-disable-next-line no-process-env
        appVersion: process.env.RELEASE_VERSION,
        screensize: `${window.screen.width}x${window.screen.height}`,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        connectionType: window.navigator?.connection?.effectiveType,
        isWeb: !isDesktopApp(),
        server: window.location.hostname,
    });

    if (isDesktopApp()) {
        try {
            const usedResources = await window.internalAPI?.getUsedResources();

            if (!usedResources) {
                return;
            }

            updateUserPropsInStatist({
                runTime: Math.floor(Date.now() - usedResources.creationTime),
                memoryAvailable: usedResources.totalMemoryKB,
                memoryUsage: usedResources.usedMemoryKB,
            });
        } catch (error) {
            // опускаем ошибку так как версия десктопа может не поддерживать этот запрос
        }
    }
}
