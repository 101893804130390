import {getMyTeams} from 'mattermost-redux/selectors/entities/teams';
import {type RootState} from 'stores/redux_store';

export const selectTeamSidebarOpen = (state: RootState): [boolean, boolean] => {
    const teams = getMyTeams(state);
    const settings = state.teamSidebar;
    const allowToOpen = teams.length > 1;

    if (!allowToOpen) {
        return [false, false];
    }

    return [settings.isOpen, allowToOpen];
};
